import type { Node, NodeProps } from "@xyflow/react";
import usePricingFlowStore from "@/stores/flow/useFlowStore";
import { trpc } from "@/utils";
import { Position } from "@xyflow/react";
import { FlagOff } from "lucide-react";
import { toast } from "sonner";
import { useShallow } from "zustand/react/shallow";

import type { EndNode } from "@repos/rate-resolver-dtos";
import type { NodeType } from "@repos/rate-resolver-shared";

import { CustomHandle } from "../shared/CustomHandle";
import { NodeToolbarComponent } from "../shared/NodeToolbarComponent";
import { Card, CardContent } from "../ui/card";

export type CustomEndNode = Node<
	EndNode & {
		label: string;
		id: string;
	},
	NodeType.END
>;

export const CustomEndNode = ({
	isConnectable,
	selected,
	data,
}: NodeProps<CustomEndNode>) => {
	const { onNodesDelete } = usePricingFlowStore(
		useShallow((state) => ({
			onNodesDelete: state.onNodesDelete,
		})),
	);

	const deleteNodeMutation = trpc.nodes.deleteNode.useMutation({
		onSuccess: () => {
			onNodesDelete(data.id);
		},
		onError: (error) => {
			toast.error(`${error.message || "Failed to delete node"}`, {
				action: {
					label: "X",
					onClick: () => toast.dismiss(),
				},
			});
		},
	});

	const handleDelete = async () => {
		try {
			await deleteNodeMutation.mutateAsync({ id: data.id });
		} catch (error) {
			console.error("Error deleting node:", error);
		}
	};
	const handleDuplicate = () => {
		toast.error("Tu ne peux pas dupliquer un noeud de fin", {
			action: {
				label: "X",
				onClick: () => toast.dismiss(),
			},
		});
	};
	return (
		<>
			<CustomHandle
				type="source"
				position={Position.Top}
				id="end"
				isConnectable={isConnectable}
				className="z-10 bg-black"
			/>
			<Card
				className={`relative border border-black bg-red-200 dark:bg-red-600 ${
					selected ? "ring-2 ring-blue-500" : ""
				}`}
			>
				<CardContent className="p-4">
					<div className="flex items-center gap-3">
						<FlagOff />
						<span className="font-semibold">Fin</span>
					</div>
				</CardContent>
			</Card>
			<NodeToolbarComponent
				isVisible={selected ?? false}
				onDelete={handleDelete}
				onDuplicate={handleDuplicate}
				disableEdit={deleteNodeMutation.isLoading}
				isLoading={deleteNodeMutation.isLoading}
			/>
		</>
	);
};
