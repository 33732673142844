import type { ReactNode } from "react";
import { AuthProvider } from "@/providers/AuthProvider";
import { SentryProvider } from "@/providers/SentryProvider";
import { TrpcProvider } from "@/providers/TrpcProvider";

type AppProviderProps = {
	children: ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
	return (
		<SentryProvider>
			<AuthProvider>
				<TrpcProvider>{children}</TrpcProvider>
			</AuthProvider>
		</SentryProvider>
	);
};
