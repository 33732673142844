import { cn } from "@/utils";
import {
	CalendarFold,
	CaseSensitive,
	FileQuestion,
	Hash,
	Rows3,
	ToggleRight,
} from "lucide-react";

import type { VariableCreateDTO } from "@repos/rate-resolver-dtos";

type VariableIconProps = {
	type: VariableCreateDTO["data"]["type"];
	className?: string;
};
export const VariableIcon = ({ type, className }: VariableIconProps) => {
	switch (type) {
		case "STRING":
			return <CaseSensitive className={cn("size-6", className)} />;
		case "NUMBER":
			return <Hash className={cn("size-6", className)} />;
		case "BOOLEAN":
			return <ToggleRight className={cn("size-6", className)} />;
		case "DATE":
			return <CalendarFold className={cn("size-6", className)} />;
		case "ENUM":
			return <Rows3 className={cn("size-6", className)} />;
		default:
			return <FileQuestion className={cn("size-6", className)} />;
	}
};
