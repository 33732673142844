import type { DataSheetGridProps } from "react-datasheet-grid";
import React, { useCallback } from "react";
import { DynamicDataSheetGrid } from "react-datasheet-grid";

import "react-datasheet-grid/dist/style.css";

import type { SheetData } from "@/components/spreadsheet/types";
import type { GetSelectedDataReturn } from "@/components/spreadsheet/viewer/useSpreadSheetData";
import type { SelectionWithId } from "react-datasheet-grid/dist/types";
import { SpreadSheetEmpty } from "@/components/spreadsheet/viewer/SpreadSheetEmpty";
import { useSpreadSheetData } from "@/components/spreadsheet/viewer/useSpreadSheetData";
import { toast } from "sonner";

import "./styles.css";

interface SpreadsheetProps extends DataSheetGridProps {
  sheetData: SheetData;
  isFirstRowHeader: boolean;
  onDataSelect: (args: GetSelectedDataReturn) => void;
}

const SpreadSheetViewerDef = ({
  sheetData,
  onDataSelect,
  isFirstRowHeader,
  ...rest
}: SpreadsheetProps) => {
  const { columns, data, setData, getSelectedData } = useSpreadSheetData({
    sheetData,
    isFirstRowHeader,
  });

  const onSelectionChange = useCallback(
    ({ selection }: { selection: SelectionWithId | null }) => {
      if (!selection) return;
      const selectedRows = selection.max.row - selection.min.row + 1;
      if (selectedRows > 100) {
        toast.warning(
          "Seules les 100 premières lignes seront traitées, les autres seront ignorées.",
        );
        selection.max.row = selection.min.row + 99;
      }
      onDataSelect(getSelectedData(selection));
    },
    [getSelectedData, onDataSelect],
  );

  if (data.length === 0) {
    return <SpreadSheetEmpty />;
  }

  return (
    <DynamicDataSheetGrid
      value={data}
      columns={columns}
      onSelectionChange={onSelectionChange}
      onChange={setData}
      addRowsComponent={false}
      {...rest}
    />
  );
};

export const SpreadSheetViewer = React.memo(SpreadSheetViewerDef);
