import type { PricingEngine } from "@/types";
import { useCallback, useEffect, useState } from "react";
import { SidebarDnDProvider } from "@/components/FlowSideBar/context/SidebarDnDContext";
import { Loader } from "@/components/shared/Loader";
import { useAppStore } from "@/stores";
import usePricingFlowStore from "@/stores/flow/useFlowStore";
import { trpc } from "@/utils";
import { formatEdge } from "@/utils/formatEdge";
import { formatNode } from "@/utils/formatNode";
import { ReactFlowProvider } from "@xyflow/react";
import { useNavigate } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";

import { StepsV2 } from "./StepsV2";

function StepsFlow() {
	const navigate = useNavigate();

	const { selectedProject } = useAppStore(
		useShallow((state) => ({
			selectedProject: state.selectedProject,
		})),
	);

	const {
		setNodes,
		setEdges,
		setPricingEnginesList,
		setSelectedPricingEngine,
		selectedPricingEngine,
	} = usePricingFlowStore(
		useShallow((state) => ({
			setNodes: state.setNodes,
			setEdges: state.setEdges,
			setPricingEnginesList: state.setPricingEnginesList,
			selectedPricingEngine: state.selectedPricingEngine,
			setSelectedPricingEngine: state.setSelectedPricingEngine,
		})),
	);

	// Fetch nodes based on the selected pricing engine ID.
	const { data: nodes, isLoading: isLoadingNodes } =
		trpc.nodes.getNodes.useQuery(
			{ pricingEngineId: selectedPricingEngine?.id || "" },
			{
				enabled: Boolean(selectedPricingEngine),
				select: (nodes) => nodes.map((node) => formatNode(node)),
				refetchInterval: false,
			},
		);

	// Fetch edges based on the selected pricing engine ID.
	const { data: edges, isLoading: isLoadingEdges } =
		trpc.edges.getEdges.useQuery(
			{ pricingEngineId: selectedPricingEngine?.id || "" },
			{
				enabled: Boolean(selectedPricingEngine?.id),
				select: (edges) => edges.map(formatEdge),
				refetchInterval: false,
			},
		);
	const [loading, setLoading] = useState(false);

	// Fetch pricing engines based on the selected project ID.
	const { data: engines, isLoading: isLoadingPricingEngines } =
		trpc.pricingEngine.getPricingEngines.useQuery(
			{ projectId: selectedProject?.id || "" },
			{
				enabled: Boolean(selectedProject),
			},
		);

	// Function to handle updating pricing engines and selecting the first enabled engine.
	const handleSetEngines = useCallback(
		(engines: PricingEngine[]) => {
			setPricingEnginesList(engines);
			const enabledEngine = engines.find((engine) => engine.isEnabled);

			if (enabledEngine) {
				setSelectedPricingEngine(enabledEngine);
			}
		},
		[setPricingEnginesList, setSelectedPricingEngine],
	);

	// Effect to set the pricing engines and select the default one when engines are fetched.
	useEffect(() => {
		setLoading(isLoadingEdges || isLoadingNodes || isLoadingPricingEngines);
	}, [isLoadingEdges, isLoadingPricingEngines, isLoadingNodes, setLoading]);

	useEffect(() => {
		if (nodes && loading) {
			setNodes(nodes);
		}
	}, [nodes, setNodes, loading]);

	useEffect(() => {
		if (engines) {
			handleSetEngines(engines);
		}
	}, [engines, handleSetEngines]);

	useEffect(() => {
		if (edges && loading) {
			setEdges(edges);
		}
	}, [edges, setEdges, loading]);

	if (!selectedProject) {
		navigate("/projets");
		return null;
	}

	if (loading) {
		return (
			<div className="flex h-[85vh] w-full items-center justify-center">
				<Loader />
			</div>
		);
	}

	return (
		<ReactFlowProvider>
			<SidebarDnDProvider>
				<StepsV2 />
			</SidebarDnDProvider>
		</ReactFlowProvider>
	);
}

export default StepsFlow;
