import type { Node, NodeProps } from "@xyflow/react";
import { cn } from "@/utils";
import { Position } from "@xyflow/react";
import { Flag } from "lucide-react";

import type { StartNode } from "@repos/rate-resolver-dtos";
import type { NodeType } from "@repos/rate-resolver-shared";
import { TargetHandle } from "@repos/rate-resolver-shared";

import { CustomHandle } from "../shared/CustomHandle";
import { Card, CardContent } from "../ui/card";

export type CustomStartNode = Node<
	StartNode["data"] & {
		label: string;
		id: string;
	},
	NodeType.START
>;

export const CustomStartNode = ({
	isConnectable,
	selected,
}: NodeProps<CustomStartNode>) => {
	return (
		<>
			<Card
				className={cn(
					"relative h-full w-full border-none bg-blue-200 dark:bg-blue-800",
					selected ? "ring-2 ring-blue-500" : "",
				)}
			>
				<CardContent className="p-4">
					<div className="flex items-center justify-center gap-3">
						<Flag />
						<span className="font-semibold">Début</span>
					</div>
				</CardContent>
			</Card>
			<CustomHandle
				type="target"
				position={Position.Bottom}
				id={TargetHandle.TARGET}
				isConnectable={isConnectable}
				className="z-10 bg-black"
			/>
		</>
	);
};
