import { Copy, Loader2, Pencil, Trash2 } from "lucide-react";

import { Button } from "../ui/button";
import { PopoverTrigger } from "../ui/popover";

type ToolbarButtonsProps = {
	onDelete: () => void;
	onEdit?: () => void;
	onDuplicate?: () => void;
	disableEdit?: boolean;
	isEdge?: boolean;
	isLoading?: boolean;
};

export const ToolbarButtons = ({
	onDelete,
	onEdit,
	onDuplicate,
	disableEdit = false,
	isEdge = false,
	isLoading = false,
}: ToolbarButtonsProps) => {
	return (
		<>
			<Button
				variant={"ghost"}
				onClick={onDelete}
				size="icon"
				className="flex-1 rounded-r-none  text-black hover:rounded-l-sm hover:bg-gray-200 hover:text-black dark:text-white dark:hover:bg-gray-800"
				type="button"
			>
				{isLoading ? (
					<Loader2 className="size-4 animate-spin" />
				) : (
					<Trash2 className="size-4" />
				)}
			</Button>
			{!isEdge && (
				<Button
					variant={"ghost"}
					onClick={onDuplicate}
					size="icon"
					className="flex-1 rounded-l-none border-l-[0.1rem] border-gray-500 text-black hover:rounded-r-sm hover:bg-gray-200 hover:text-black dark:text-white dark:hover:bg-gray-800"
					type="button"
				>
					<Copy className="size-4" />
				</Button>
			)}
			{onEdit && (
				<>
					{isEdge ? (
						<PopoverTrigger asChild>
							<Button
								variant={"ghost"}
								onClick={onEdit}
								size="icon"
								className="flex-1 rounded-l-none border-l-[0.1rem] border-gray-500 text-black hover:rounded-r-sm hover:bg-gray-200 hover:text-black dark:text-white dark:hover:bg-gray-800"
								type="button"
								disabled={disableEdit}
							>
								<Pencil className="size-4" />
							</Button>
						</PopoverTrigger>
					) : (
						<Button
							variant={"ghost"}
							onClick={onEdit}
							size="icon"
							className="flex-1 rounded-l-none border-l-[0.1rem] border-gray-500 text-black hover:rounded-r-sm hover:bg-gray-200 hover:text-black dark:text-white dark:hover:bg-gray-800"
							type="button"
							disabled={disableEdit}
						>
							<Pencil className="size-4" />
						</Button>
					)}
				</>
			)}
		</>
	);
};
