import type { FormattedEdge, FormattedNode } from "@/types";
import type { LayoutOptions } from "elkjs/lib/elk.bundled.js";
import { useCallback, useRef } from "react";
import { useReactFlow } from "@xyflow/react";
import ELK from "elkjs/lib/elk.bundled.js";

const DEFAULT_NODE_WIDTH = 150;
const DEFAULT_NODE_HEIGHT = 50;
const layoutOptions: LayoutOptions = {
	"elk.algorithm": "layered",
	"elk.direction": "UP",
	"elk.layered.spacing.nodeNodeBetweenLayers": "120",
	"elk.spacing.nodeNode": "120",
	"elk.padding": "[top=50, left=250, bottom=50, right=250]",
	// this places orphan nodes at the bottom
	"elk.hierarchyHandling": "INCLUDE_CHILDREN",
};

export const useLayoutElements = () => {
	const { fitView } = useReactFlow();
	const layoutInProgress = useRef(false);
	const hasOffsetBeenApplied = useRef(false);

	const layoutElements = useCallback(
		async ({
			nodes,
			edges,
		}: {
			nodes: FormattedNode[];
			edges: FormattedEdge[];
		}) => {
			console.log(
				"😎 ~ file: useLayoutElements.ts:32 ~ useLayoutElements ~ nodes:",
				nodes,
			);
			console.log("can't layout ?", {
				cantLayout: layoutInProgress.current || !nodes.length,
				nodesLen: nodes.length,
				inprogress: layoutInProgress.current,
			});
			if (layoutInProgress.current || !nodes.length) return;

			layoutInProgress.current = true;

			try {
				// Layout the connected nodes using the ELK algorithm
				const graph = {
					id: "root",
					layoutOptions: layoutOptions,
					children: nodes.map((node) => ({
						id: node.id,
						width: node.measured.width || DEFAULT_NODE_WIDTH,
						height: node.measured.height || DEFAULT_NODE_HEIGHT,
					})),
					edges: edges.map((edge) => ({
						id: edge.id,
						sources: [edge.source],
						targets: [edge.target],
					})),
				};

				const elk = new ELK();
				const { children } = await elk.layout(graph);

				// Update positions of connected nodes
				const newNodes = nodes.map((node) => {
					const layoutNode = children?.find((n) => n.id === node.id);
					if (layoutNode) {
						return {
							...node,
							position: {
								x: layoutNode.x || 0,
								y: layoutNode.y || 0,
							},
						};
					}
					return node;
				});

				if (!hasOffsetBeenApplied.current) {
					hasOffsetBeenApplied.current = true;
				}
				layoutInProgress.current = false;
				// Delay fitView to ensure nodes are properly positioned
				setTimeout(() => {
					fitView({ padding: 0.2, duration: 200, maxZoom: 1.2 });
				}, 50);

				return newNodes;
			} catch (error) {
				console.error("Layout failed:", error);
				layoutInProgress.current = false;
				throw error;
			}
		},
		[fitView],
	);

	return { layoutElements };
};
