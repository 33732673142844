import type { SheetData } from "@/components/spreadsheet";
import type * as XLSX from "xlsx";
import { useCallback, useMemo, useState } from "react";
import { EnumValuesPreview } from "@/components/enumImporter/EnumValuesPreview";
import { SpreadSheetViewer } from "@/components/spreadsheet";
import { WorkbookSelector } from "@/components/spreadsheet/WorkbookSelector";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";

import type { EnumValue } from "@repos/rate-resolver-dtos";

interface EnumImportDialogProps {
	isModalOpen: boolean;
	setIsModalOpen: (isOpen: boolean) => void;
	onImport: (options: EnumValue[]) => void;
}

export const EnumImportDialog = ({
	isModalOpen,
	setIsModalOpen,
	onImport,
}: EnumImportDialogProps) => {
	const [workbook, setWorkbook] = useState<XLSX.WorkBook | null>(null);
	const [selectedSheet, setSelectedSheet] = useState<string | null>(null);
	const [selectedSheetData, setSelectedSheetData] = useState<SheetData | null>(
		null,
	);
	const [isFirstRowHeader, setIsFirstRowHeader] = useState<boolean>(false);
	const [userSelectedData, setUserSelectedData] = useState<SheetData | null>(
		null,
	);

	const reset = () => {
		setSelectedSheet(null);
		setSelectedSheetData(null);
		setIsFirstRowHeader(false);
		setUserSelectedData(null);
	};

	const enumValues = useMemo(() => {
		if (!userSelectedData) {
			return [];
		}
		const enumValues = userSelectedData
			.map((row) => ({
				label: row[0],
				value: row[1],
			}))
			.filter((item) => item.value !== undefined);

		const uniqueEnumValues = Array.from(
			// Filters out duplicate values
			new Map(enumValues.map((item) => [item.value, item])).values(),
		);
		return uniqueEnumValues as EnumValue[];
	}, [userSelectedData]);

	const onSubmit = useCallback(() => {
		if (!enumValues.length) {
			return;
		}
		onImport(enumValues);
	}, [onImport, enumValues]);

	return (
		<Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
			<DialogContent className="flex h-[95vh] max-w-[80vw] flex-col">
				<DialogHeader>
					<DialogTitle>Importer options variable</DialogTitle>
				</DialogHeader>

				<div className="h-full w-full space-y-4">
					<WorkbookSelector
						workbook={workbook}
						setWorkbook={setWorkbook}
						selectedSheet={selectedSheet}
						setSelectedSheet={setSelectedSheet}
						reset={reset}
						isFirstRowHeader={isFirstRowHeader}
						setIsFirstRowHeader={setIsFirstRowHeader}
						setSelectedSheetData={setSelectedSheetData}
					/>

					{selectedSheet && selectedSheetData && (
						<SpreadSheetViewer
							sheetData={selectedSheetData}
							isFirstRowHeader={isFirstRowHeader}
							onDataSelect={({
								selectedData,
								isSingleColumn,
								numberOfColumns,
							}) => {
								if (isSingleColumn) {
									return;
								}
								if (numberOfColumns < 2) {
									return;
								}
								setUserSelectedData(selectedData);
							}}
							height={300}
						/>
					)}

					{enumValues.length > 0 && (
						<EnumValuesPreview enumValues={enumValues} />
					)}
					<div className="flex flex-1 justify-end self-end">
						<Button disabled={userSelectedData === null} onClick={onSubmit}>
							Importer
						</Button>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};
