import { VariableType } from "@prisma/client";

import type {
	AnyOperationOperators,
	OperationConfig,
	OperatorConfig,
} from "../../config";
import {
	AllOperationOperators,
	BooleanOperationConfig,
	DateOperationConfig,
	NumberOperationConfig,
	StringOrEnumOperationConfig,
} from "../../config";

export const getAllowedFirstOperandVariableTypes = (
	assignedVariableType: VariableType,
): VariableType[] => {
	const uniqueVariableTypes = new Set<VariableType>();
	const config = getOperationsConfig(assignedVariableType);
	Object.keys(AllOperationOperators)
		.filter((operatorKey) => {
			const config = getOperationsConfig(assignedVariableType);
			const operatorConfig = config[operatorKey];
			if (!operatorConfig) {
				return false;
			}

			const { firstOperandVariableTypes } = operatorConfig;

			return firstOperandVariableTypes.length > 0;
		})
		.map((operatorKey) => {
			const operator = config[
				operatorKey as AnyOperationOperators
			] as OperatorConfig;
			operator.firstOperandVariableTypes.forEach((variableType) => {
				uniqueVariableTypes.add(variableType);
			});
		});

	return Array.from(uniqueVariableTypes);
};

export const getAllowedOperators = ({
	assignedVariableType,
	firstOperandType,
}: {
	assignedVariableType: VariableType;
	firstOperandType: VariableType;
}): { label: string; key: AnyOperationOperators }[] => {
	const config = getOperationsConfig(assignedVariableType);
	return Object.keys(config)
		.filter((operatorKey) => {
			const operatorConfig = config[operatorKey] as OperatorConfig | undefined;
			if (!operatorConfig) {
				return false;
			}

			const { firstOperandVariableTypes } = operatorConfig;

			return firstOperandVariableTypes.includes(firstOperandType);
		})
		.map((operatorKey) => {
			const operator = config[
				operatorKey as AnyOperationOperators
			] as OperatorConfig;
			return {
				label: operator.label[firstOperandType] as string,
				key: operator.key,
			};
		});
};

export const getAllowedOtherOperandVariableTypes = (
	assignedVariableType: VariableType,
	firstOperandType: VariableType,
	operator: AnyOperationOperators,
) => {
	const config = getOperationsConfig(assignedVariableType);
	const operatorConfig: OperatorConfig | undefined = config[operator];
	if (!operatorConfig) {
		return [];
	}
	const { shouldOperandsBeSameType, otherOperandsVariableTypes } =
		operatorConfig;
	if (shouldOperandsBeSameType) {
		return [firstOperandType];
	}
	return otherOperandsVariableTypes;
};

export const getOperationsConfig = (
	assignedVariableType: VariableType,
): OperationConfig => {
	switch (assignedVariableType) {
		case VariableType.STRING:
		case VariableType.ENUM:
			return StringOrEnumOperationConfig;
		case VariableType.NUMBER:
			return NumberOperationConfig;
		case VariableType.BOOLEAN:
			return BooleanOperationConfig;
		case VariableType.DATE:
			return DateOperationConfig;
		default:
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			console.log(`No config found for variable type: ${assignedVariableType}`);
			return {};
	}
};
