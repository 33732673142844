import { z } from "zod";

import { ConditionType, NodeType } from "@repos/rate-resolver-shared";

import {
	conditionOperationSchema,
	nodeSchema,
	operationSchema,
} from "../../primitives";

export const createEmptyNodeSchema = z
	.object({
		pricingEngineId: z.string().uuid(),
		label: z.string().optional(),
		type: z.nativeEnum(NodeType),
	})
	.transform(({ pricingEngineId, label, type }) => {
		const normalizedLabel = label?.trim();
		if (normalizedLabel && normalizedLabel.length === 0) {
			return { pricingEngineId, label: undefined, type };
		}
		return { pricingEngineId, label, type };
	});

export type CreateEmptyNodeDTO = z.infer<typeof createEmptyNodeSchema>;

export const deleteNodeSchema = z.object({
	id: z.string().uuid(),
});

export type DeleteNodeDTO = z.infer<typeof deleteNodeSchema>;

export const getNodesSchema = z.object({
	pricingEngineId: z.string().uuid(),
});

export type GetNodesDTO = z.infer<typeof getNodesSchema>;

export const getNodeSchema = z.object({
	id: z.string().uuid(),
});

export type GetNodeDTO = z.infer<typeof getNodeSchema>;

/*
 The schemas below are for typing purposes only
*/
export const operationNodeSchema = nodeSchema.extend({
	type: z.literal(NodeType.OPERATIONS),
	data: z.object({
		operations: z.array(operationSchema),
		operationSummaries: z.array(
			z.object({
				summary: z.string(),
				id: z.string().uuid(),
			}),
		),
	}),
});

export type OperationNode = z.infer<typeof operationNodeSchema>;

const groupSchema = z.object({
	operations: z.array(conditionOperationSchema),
	conditionType: z.nativeEnum(ConditionType),
	conditionSummaries: z.array(
		z.object({
			summary: z.string(),
			id: z.string().uuid(),
		}),
	),
});

export type GroupData = z.infer<typeof groupSchema>;

export const conditionsGroupSchema = nodeSchema.extend({
	type: z.literal(NodeType.CONDITIONS_GROUP),
	data: z.object({
		conditionGroups: z.array(groupSchema),
	}),
});

export type ConditionsGroupNode = z.infer<typeof conditionsGroupSchema>;

export const errorMessageNodeSchema = nodeSchema.extend({
	type: z.literal(NodeType.ERROR_MESSAGE),
	data: z.object({
		message: z.string(),
	}),
});

export type ErrorMessageNode = z.infer<typeof errorMessageNodeSchema>;

export const outputNodeSchema = nodeSchema.extend({
	type: z.literal(NodeType.OUTPUT),
	data: z.object({
		operations: z.array(operationSchema),
		operationSummaries: z.array(
			z.object({
				summary: z.string(),
				id: z.string().uuid(),
			}),
		),
	}),
});

export type OutputNode = z.infer<typeof outputNodeSchema>;

export const startNodeSchema = nodeSchema.extend({
	type: z.literal(NodeType.START),
	data: z.object({}),
});

export type StartNode = z.infer<typeof startNodeSchema>;

export const endNodeSchema = nodeSchema.extend({
	type: z.literal(NodeType.END),
	data: z.object({}),
});

export type EndNode = z.infer<typeof endNodeSchema>;

export type TypedNode =
	| OperationNode
	| ConditionsGroupNode
	| ErrorMessageNode
	| OutputNode
	| EndNode
	| StartNode;

export const summarySchema = z.object({
	id: z.string().uuid(),
	text: z.string(),
	textWithValues: z.string(),
	result: z.string(),
});

export type Summary = z.infer<typeof summarySchema>;

export const groupSummarySchema = groupSchema.extend({
	conditionSummaries: z.array(summarySchema),
	conditionsResult: z.string(),
});

export type GroupSummary = z.infer<typeof groupSummarySchema>;

export const conditionsGroupNodeSummarySchema = conditionsGroupSchema.extend({
	data: z.object({
		conditionGroups: z.array(groupSummarySchema),
	}),
});

export type ConditionsGroupNodeSummary = z.infer<
	typeof conditionsGroupNodeSummarySchema
>;

export const operationNodeSummarySchema = operationNodeSchema.extend({
	data: z.object({
		operations: z.array(operationSchema),
		operationSummaries: z.array(summarySchema),
	}),
});
export type OperationNodeSummary = z.infer<typeof operationNodeSummarySchema>;

export const outputNodeSummarySchema = outputNodeSchema.extend({
	data: z.object({
		operations: z.array(operationSchema),
		operationSummaries: z.array(summarySchema),
	}),
});

export type OutputNodeSummary = z.infer<typeof outputNodeSummarySchema>;

export type TypedNodeSummary =
	| OperationNodeSummary
	| ConditionsGroupNodeSummary
	| OutputNodeSummary
	| ErrorMessageNode
	| EndNode
	| StartNode;
