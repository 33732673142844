import type { Operation } from "@repos/rate-resolver-dtos";

export type PricingEngineInput = {
	[key: string]: any;
};

export enum OperandType {
	// Used when the operand is a variable's key
	FIELD = "FIELD",
	// Used when the operand is a property of an ENUM variable ( takes the property's key as value )
	FIELD_PROPERTY = "FIELD_PROPERTY",
	// Used when the operand is a static value
	VALUE = "VALUE",
}

export type OperandValue = string | number | boolean | Date;

export type FinalStep = {
	id: string;
	operations: Operation[];
	pricingEngineId: string;
};
