import { NodeType } from "@repos/rate-resolver-shared";

type Section = {
	title: string;
	nodes: NodeItem[];
	accentColor: string;
};
type NodeItem = {
	key: NodeType;
	label: string;
};
export const sections: Section[] = [
	{
		title: "Logique",
		nodes: [
			{ label: "Groupe Conditions", key: NodeType.CONDITIONS_GROUP },
			{
				key: NodeType.OPERATIONS,
				label: "Opérations",
			},
		],
		accentColor: "green",
	},
	{
		title: "Sorties",
		nodes: [
			{
				label: "Résultat",
				key: NodeType.OUTPUT,
			},
			{
				label: "Erreur",
				key: NodeType.ERROR_MESSAGE,
			},
			{
				label: "Fin",
				key: NodeType.END,
			},
		],
		accentColor: "orange",
	},
];
