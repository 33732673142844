import { VariableType } from "@prisma/client";

export type OperatorConfig = {
	key: AnyOperationOperators;
	label: Partial<Record<VariableType, string>>;
	firstOperandVariableTypes: VariableType[];
	otherOperandsVariableTypes: VariableType[];
	shouldOperandsBeSameType: boolean;
};

export type OperationConfig = {
	[key in AnyOperationOperators]?: OperatorConfig;
};

export const AllOperationOperators = {
	ADDITION: "ADDITION",
	SUBTRACTION: "SUBTRACTION",
	MULTIPLICATION: "MULTIPLICATION",
	DIVISION: "DIVISION",
	IS_AFTER: "IS_AFTER",
	IS_BEFORE: "IS_BEFORE",
	IS_EQUAL: "IS_EQUAL",
	IS_EQUAL_OR_AFTER: "IS_EQUAL_OR_AFTER",
	IS_EQUAL_OR_BEFORE: "IS_EQUAL_OR_BEFORE",
	PLUS_DAYS: "PLUS_DAYS",
	PLUS_WEEKS: "PLUS_WEEKS",
	PLUS_MONTHS: "PLUS_MONTHS",
	PLUS_YEARS: "PLUS_YEARS",
	DIFFERENCE_IN_DAYS: "DIFFERENCE_IN_DAYS",
	DIFFERENCE_IN_WEEKS: "DIFFERENCE_IN_WEEKS",
	DIFFERENCE_IN_MONTHS: "DIFFERENCE_IN_MONTHS",
	DIFFERENCE_IN_YEARS: "DIFFERENCE_IN_YEARS",
	CONTAINS: "CONTAINS",
	STARTS_WITH: "STARTS_WITH",
	ENDS_WITH: "ENDS_WITH",
} as const;

export const BooleanOperators = {
	[AllOperationOperators.IS_AFTER]: AllOperationOperators.IS_AFTER,
	[AllOperationOperators.IS_BEFORE]: AllOperationOperators.IS_BEFORE,
	[AllOperationOperators.IS_EQUAL]: AllOperationOperators.IS_EQUAL,
	[AllOperationOperators.IS_EQUAL_OR_AFTER]:
		AllOperationOperators.IS_EQUAL_OR_AFTER,
	[AllOperationOperators.IS_EQUAL_OR_BEFORE]:
		AllOperationOperators.IS_EQUAL_OR_BEFORE,
	[AllOperationOperators.CONTAINS]: AllOperationOperators.CONTAINS,
	[AllOperationOperators.STARTS_WITH]: AllOperationOperators.STARTS_WITH,
	[AllOperationOperators.ENDS_WITH]: AllOperationOperators.ENDS_WITH,
} as const;

export type AnyOperationOperators = keyof typeof AllOperationOperators;
export const NumberOperationConfig = {
	ADDITION: {
		key: AllOperationOperators.ADDITION,
		label: {
			NUMBER: "+",
		},
		firstOperandVariableTypes: [VariableType.NUMBER],
		otherOperandsVariableTypes: [VariableType.NUMBER],
		shouldOperandsBeSameType: true,
	},
	SUBTRACTION: {
		key: AllOperationOperators.SUBTRACTION,
		label: {
			NUMBER: "-",
		},
		firstOperandVariableTypes: [VariableType.NUMBER],
		otherOperandsVariableTypes: [VariableType.NUMBER],
		shouldOperandsBeSameType: true,
	},
	MULTIPLICATION: {
		key: AllOperationOperators.MULTIPLICATION,
		label: {
			NUMBER: "*",
		},
		firstOperandVariableTypes: [VariableType.NUMBER],
		otherOperandsVariableTypes: [VariableType.NUMBER],
		shouldOperandsBeSameType: true,
	},
	DIVISION: {
		key: AllOperationOperators.DIVISION,
		label: {
			NUMBER: "/",
		},
		firstOperandVariableTypes: [VariableType.NUMBER],
		otherOperandsVariableTypes: [VariableType.NUMBER],
		shouldOperandsBeSameType: true,
	},
	DIFFERENCE_IN_DAYS: {
		key: AllOperationOperators.DIFFERENCE_IN_DAYS,
		label: {
			DATE: "Différence en jours",
		},
		firstOperandVariableTypes: [VariableType.DATE],
		otherOperandsVariableTypes: [VariableType.DATE],
		shouldOperandsBeSameType: true,
	},
	DIFFERENCE_IN_WEEKS: {
		key: AllOperationOperators.DIFFERENCE_IN_WEEKS,
		label: {
			DATE: "Différence en semaines",
		},
		firstOperandVariableTypes: [VariableType.DATE],
		otherOperandsVariableTypes: [VariableType.DATE],
		shouldOperandsBeSameType: true,
	},
	DIFFERENCE_IN_MONTHS: {
		key: AllOperationOperators.DIFFERENCE_IN_MONTHS,
		label: {
			DATE: "Différence en mois",
		},
		firstOperandVariableTypes: [VariableType.DATE],
		otherOperandsVariableTypes: [VariableType.DATE],
		shouldOperandsBeSameType: true,
	},
	DIFFERENCE_IN_YEARS: {
		key: AllOperationOperators.DIFFERENCE_IN_YEARS,
		label: {
			DATE: "Différence en années",
		},
		firstOperandVariableTypes: [VariableType.DATE],
		otherOperandsVariableTypes: [VariableType.DATE],
		shouldOperandsBeSameType: true,
	},
} satisfies OperationConfig;

export type NumberOperationConfigType = typeof NumberOperationConfig;

export const StringOrEnumOperationConfig = {
	ADDITION: {
		key: AllOperationOperators.ADDITION,
		label: {
			STRING: "Concatenation",
			ENUM: "Concatenation",
		},
		firstOperandVariableTypes: [VariableType.STRING, VariableType.ENUM],
		otherOperandsVariableTypes: [
			VariableType.STRING,
			VariableType.ENUM,
			VariableType.NUMBER,
		],
		shouldOperandsBeSameType: false,
	},
} satisfies OperationConfig;

export type StringOrEnumOperationConfigType =
	typeof StringOrEnumOperationConfig;

export const BooleanOperationConfig = {
	IS_AFTER: {
		key: AllOperationOperators.IS_AFTER,
		label: {
			DATE: "Est après",
			NUMBER: ">",
		},
		firstOperandVariableTypes: [VariableType.DATE, VariableType.NUMBER],
		otherOperandsVariableTypes: [VariableType.DATE, VariableType.NUMBER],
		shouldOperandsBeSameType: true,
	},
	IS_BEFORE: {
		key: AllOperationOperators.IS_BEFORE,
		label: {
			DATE: "Est avant",
			NUMBER: "<",
		},
		firstOperandVariableTypes: [VariableType.DATE, VariableType.NUMBER],
		otherOperandsVariableTypes: [VariableType.DATE, VariableType.NUMBER],
		shouldOperandsBeSameType: true,
	},
	IS_EQUAL: {
		key: AllOperationOperators.IS_EQUAL,
		label: {
			BOOLEAN: "=",
			STRING: "=",
			ENUM: "=",
			NUMBER: "=",
			DATE: "=",
		},
		firstOperandVariableTypes: [
			VariableType.BOOLEAN,
			VariableType.STRING,
			VariableType.ENUM,
			VariableType.NUMBER,
			VariableType.DATE,
		],
		otherOperandsVariableTypes: [
			VariableType.BOOLEAN,
			VariableType.STRING,
			VariableType.ENUM,
			VariableType.NUMBER,
			VariableType.DATE,
		],
		shouldOperandsBeSameType: true,
	},
	IS_EQUAL_OR_AFTER: {
		key: AllOperationOperators.IS_EQUAL_OR_AFTER,
		label: {
			DATE: "Est égal ou après",
			NUMBER: ">=",
		},
		firstOperandVariableTypes: [VariableType.DATE, VariableType.NUMBER],
		otherOperandsVariableTypes: [VariableType.DATE, VariableType.NUMBER],
		shouldOperandsBeSameType: true,
	},
	IS_EQUAL_OR_BEFORE: {
		key: AllOperationOperators.IS_EQUAL_OR_BEFORE,
		label: {
			DATE: "Est égal ou avant",
			NUMBER: "<=",
		},
		firstOperandVariableTypes: [VariableType.DATE, VariableType.NUMBER],
		otherOperandsVariableTypes: [VariableType.DATE, VariableType.NUMBER],
		shouldOperandsBeSameType: true,
	},
	CONTAINS: {
		key: AllOperationOperators.CONTAINS,
		label: {
			STRING: "Contient",
			ENUM: "Contient",
		},
		firstOperandVariableTypes: [VariableType.STRING, VariableType.ENUM],
		otherOperandsVariableTypes: [VariableType.STRING, VariableType.ENUM],
		shouldOperandsBeSameType: false,
	},
	STARTS_WITH: {
		key: AllOperationOperators.STARTS_WITH,
		label: {
			STRING: "Commence avec",
			ENUM: "Commence avec",
		},
		firstOperandVariableTypes: [VariableType.STRING, VariableType.ENUM],
		otherOperandsVariableTypes: [VariableType.STRING, VariableType.ENUM],
		shouldOperandsBeSameType: false,
	},
	ENDS_WITH: {
		key: AllOperationOperators.ENDS_WITH,
		label: {
			STRING: "Fini par",
			ENUM: "Fini par",
		},
		firstOperandVariableTypes: [VariableType.STRING, VariableType.ENUM],
		otherOperandsVariableTypes: [VariableType.STRING, VariableType.ENUM],
		shouldOperandsBeSameType: false,
	},
} satisfies OperationConfig;
export type BooleanOperationConfigType = typeof BooleanOperationConfig;

export const DateOperationConfig = {
	PLUS_DAYS: {
		key: AllOperationOperators.PLUS_DAYS,
		label: {
			DATE: "+ jours",
		},
		firstOperandVariableTypes: [VariableType.DATE],
		otherOperandsVariableTypes: [VariableType.NUMBER],
		shouldOperandsBeSameType: false,
	},
	PLUS_WEEKS: {
		key: AllOperationOperators.PLUS_WEEKS,
		label: {
			DATE: "+ semaines",
		},
		firstOperandVariableTypes: [VariableType.DATE],
		otherOperandsVariableTypes: [VariableType.NUMBER],
		shouldOperandsBeSameType: false,
	},
	PLUS_MONTHS: {
		key: AllOperationOperators.PLUS_MONTHS,
		label: {
			DATE: "+ mois",
		},
		firstOperandVariableTypes: [VariableType.DATE],
		otherOperandsVariableTypes: [VariableType.NUMBER],
		shouldOperandsBeSameType: false,
	},
	PLUS_YEARS: {
		key: AllOperationOperators.PLUS_YEARS,
		label: {
			DATE: "+ années",
		},
		firstOperandVariableTypes: [VariableType.DATE],
		otherOperandsVariableTypes: [VariableType.NUMBER],
		shouldOperandsBeSameType: false,
	},
} satisfies OperationConfig;
export type DateOperationConfigType = typeof DateOperationConfig;
