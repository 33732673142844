import type { Control } from "react-hook-form";
import { useEffect, useState } from "react";
import { Plus, Trash } from "lucide-react";
import { useFieldArray, useFormContext } from "react-hook-form";

import type { EnumValue, OpenaiVariableDTO } from "@repos/rate-resolver-dtos";

import { Button } from "../ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { Input } from "../ui/input";
import { Pagination } from "../ui/pagination";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";

interface EnumOptionsProps {
  enumValues: EnumValue[];
  setEnumValues: (newValues: EnumValue[]) => void;
  variableIndex: number;
  control: Control<OpenaiVariableDTO>;
}

const EnumOptions = ({
  enumValues,
  setEnumValues,
  variableIndex,
}: EnumOptionsProps) => {
  const [enumPage, setEnumPage] = useState(1);
  const [editMode, setEditMode] = useState<{ [key: string]: boolean }>({});
  const enumItemsPerPage = 5;

  const {
    control: formControl,
    getValues,
    setValue,
  } = useFormContext<OpenaiVariableDTO>();
  const { fields, replace, append } = useFieldArray({
    control: formControl,
    name: `variables.${variableIndex}.enumValues`,
  });

  const updateEnumValues = () => {
    const currentValues =
      getValues(`variables.${variableIndex}.enumValues`) || [];
    const updatedEnumValues = [
      ...enumValues.slice(0, (enumPage - 1) * enumItemsPerPage),
      ...currentValues,
      ...enumValues.slice(enumPage * enumItemsPerPage),
    ];
    setEnumValues(updatedEnumValues);
    replace(
      updatedEnumValues.slice(
        (enumPage - 1) * enumItemsPerPage,
        enumPage * enumItemsPerPage,
      ),
    );
  };

  const handlePageChange = (newPage: number) => {
    updateEnumValues();
    setEnumPage(newPage);
  };

  const handleCellClick = (index: number, field: string) => {
    setEditMode((prev) => ({ ...prev, [`${index}-${field}`]: true }));
  };

  const handleBlur = (index: number, field: string) => {
    updateEnumValues();
    setEditMode((prev) => ({ ...prev, [`${index}-${field}`]: false }));
  };

  const handleChange = (index: number, field: string, value: string) => {
    if (field === "value")
      setValue(`variables.${variableIndex}.enumValues.${index}.value`, value);
    else
      setValue(`variables.${variableIndex}.enumValues.${index}.label`, value);
  };

  const handleDelete = (i) => {
    const cmpIndex = i + (enumPage - 1) * enumItemsPerPage;
    const updatedEnumValues = enumValues.filter(
      (_, index) => cmpIndex !== index,
    );
    setEnumValues(updatedEnumValues);
    if (enumValues.length % enumItemsPerPage === 1 && enumPage > 1)
      setEnumPage(enumPage - 1);
  };

  const handleAddNewEnum = () => {
    const newEnum = {
      label: "Nouvelle Valeur",
      value: "NOUVELLE_VALEUR",
    };
    const newEnumValues = [...enumValues, newEnum];
    setEnumValues(newEnumValues);
    const newPage = Math.ceil(newEnumValues.length / enumItemsPerPage);
    setEnumPage(newPage);
    append(newEnum);
  };

  useEffect(() => {
    replace(
      enumValues.slice(
        (enumPage - 1) * enumItemsPerPage,
        enumPage * enumItemsPerPage,
      ),
    );
  }, [enumPage, enumValues, replace]);

  return (
    <div className="mt-7 flex flex-row items-baseline justify-start">
      <Dialog>
        <DialogTrigger asChild>
          <Button
            type="button"
            variant="outline"
            size="lg"
            className="flex-shrink-0"
          >
            Voir toutes les options
          </Button>
        </DialogTrigger>
        <DialogContent className="flex h-[48vh] w-full max-w-4xl flex-col">
          <DialogHeader>
            <DialogTitle>Options</DialogTitle>
            <Button
              type="button"
              variant="outline"
              size="sm"
              className="absolute right-12 top-4"
              onClick={handleAddNewEnum}
            >
              <Plus className="mr-2 h-4 w-4" /> Ajouter une option
            </Button>
          </DialogHeader>
          {enumValues.length === 0 ? (
            <div className="text-medium flex h-full items-center justify-center text-center">
              Il semble que vous n&apos;ayez pas encore ajouté de variables
              d&apos;énumération, veuillez cliquer sur &quot;Ajouter une
              option&quot; pour créer votre première option.
            </div>
          ) : (
            <div className="h-[28vh]">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Nom</TableHead>
                    <TableHead>Identifiant option</TableHead>
                    <TableHead>Actions</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {fields.map((field, index) => (
                    <TableRow key={field.id}>
                      <TableCell
                        onClick={() => handleCellClick(index, "label")}
                        className="m-0 pb-3 pt-3"
                      >
                        {editMode[`${index}-label`] ? (
                          <Input
                            autoFocus
                            defaultValue={field.label}
                            placeholder="Enter label"
                            onBlur={() => handleBlur(index, "label")}
                            onChange={(e) =>
                              handleChange(index, "label", e.target.value)
                            }
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.currentTarget.blur();
                              }
                            }}
                            className="m-0 box-border h-full w-full border-none p-1 outline-none"
                          />
                        ) : (
                          field.label
                        )}
                      </TableCell>
                      <TableCell
                        onClick={() => handleCellClick(index, "value")}
                      >
                        {editMode[`${index}-value`] ? (
                          <Input
                            autoFocus
                            defaultValue={field.value}
                            placeholder="Enter value"
                            onBlur={() => handleBlur(index, "value")}
                            onChange={(e) =>
                              handleChange(index, "value", e.target.value)
                            }
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.currentTarget.blur();
                              }
                            }}
                            className="m-0 box-border h-full w-full border-none p-1 outline-none"
                          />
                        ) : (
                          field.value
                        )}
                      </TableCell>
                      <TableCell>
                        <div
                          className="ml-4"
                          onClick={() => handleDelete(index)}
                        >
                          <Trash />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
          <div className="flex-shrink-0">
            {enumValues.length > enumItemsPerPage && (
              <Pagination
                currentPage={enumPage}
                totalItems={enumValues.length}
                itemsPerPage={enumItemsPerPage}
                onPageChange={handlePageChange}
              />
            )}
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button type="button" variant="outline">
                Fermer
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EnumOptions;
