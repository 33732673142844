import { useState } from "react";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
	Card,
	CardContent,
	CardFooter,
	CardHeader,
} from "@/components/ui/card";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Skeleton } from "@/components/ui/skeleton";
import { useProjectActions } from "@/hooks/useProjectActions";
import { cn, trpc } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { DotsVerticalIcon } from "@radix-ui/react-icons";
import { Copy, Pencil } from "lucide-react";
import { useForm, useWatch } from "react-hook-form";
import { TbWorld } from "react-icons/tb";
import { toast } from "sonner";

import type { TarificationStatusUpdateDto } from "@repos/rate-resolver-dtos";
import type { ProjectType } from "@repos/rate-resolver-shared";
import { pricingEngineStatusUpdateSchema } from "@repos/rate-resolver-dtos";

import { UpdateProjectForm } from "../projects/UpdateProjectForm";

interface ProjectCardProps {
	project: ProjectType;
	onSelect: () => void;
}

const ProjectCard = ({
	project: { id: projectId, name: title, description, image, status },
	onSelect,
}: ProjectCardProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const [isExpanded, setIsExpanded] = useState(false);

	const utils = trpc.useUtils();
	const { handleDuplicate } = useProjectActions();
	const { control, setValue } = useForm<TarificationStatusUpdateDto>({
		resolver: zodResolver(pricingEngineStatusUpdateSchema),
		defaultValues: {
			projectId: projectId,
			status,
		},
	});

	const statusValue = useWatch({ name: "status", control });

	const updatePricingEngineStatus = trpc.pricingEngine.updateStatus.useMutation(
		{
			onSuccess: () => {
				toast.success("Le statut du tarificateur a été changé avec succès.", {
					action: {
						label: "X",
						onClick: () => toast.dismiss(),
					},
				});
				utils.projects.getProjects.invalidate();
			},
			onError: (error) => {
				toast.error(error.message, {
					action: {
						label: "X",
						onClick: () => toast.dismiss(),
					},
				});
			},
		},
	);

	const handleStatusChange = async (newStatus: boolean) => {
		const newStatusValue = newStatus ? "PUBLISHED" : "DRAFT";
		try {
			await updatePricingEngineStatus.mutateAsync({
				projectId: projectId,
				status: newStatusValue,
			});
		} catch (error) {
			console.error(error);
			setValue("status", status);
		}
	};

	return (
		<>
			<Card className="group relative cursor-pointer overflow-hidden rounded-lg shadow-lg transition-transform duration-300 ease-in-out hover:-translate-y-2 hover:shadow-xl">
				<CardHeader className="relative">
					<div className="absolute right-2 top-2 p-2">
						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<Button variant="link" type="button" className="h-4 w-8 p-0">
									<DotsVerticalIcon className="h-6 w-6" />
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent className="bg-background ">
								<DropdownMenuItem
									onClick={() => {
										handleDuplicate({
											projectId,
										});
									}}
								>
									<Copy className="size-6 p-1"></Copy> <span>Dupliquer</span>
								</DropdownMenuItem>
								<DropdownMenuItem onClick={() => setIsOpen(true)}>
									<Pencil className="size-6 p-1"></Pencil> <span>Modifier</span>
								</DropdownMenuItem>
								<DropdownMenuItem
									onClick={() => {
										handleStatusChange(statusValue === "DRAFT" ? true : false);
										setValue(
											"status",
											statusValue === "DRAFT" ? "PUBLISHED" : "DRAFT",
										);
									}}
								>
									<TbWorld className="size-6 p-1"></TbWorld>
									{statusValue === "DRAFT" ? "Publier" : "Dépublier"}
								</DropdownMenuItem>
							</DropdownMenuContent>
						</DropdownMenu>
					</div>

					<img
						src={image || "https://via.placeholder.com/300"}
						alt={title}
						width={300}
						height={300}
						className="mx-auto h-64 w-48 object-contain"
						onClick={onSelect}
					/>
				</CardHeader>
				<CardContent className="h-32 bg-background pl-4 pt-4">
					<div onClick={onSelect}>
						<h3 className="text-lg font-semibold">{title}</h3>
						<p
							onMouseEnter={() => setIsExpanded(true)}
							onMouseLeave={() => setIsExpanded(false)}
							className={cn(
								"text-sm text-muted-foreground",
								isExpanded ? "" : "line-clamp-2",
							)}
						>
							{description}
						</p>
					</div>
				</CardContent>
				<CardFooter className="mb-4 flex w-full items-center justify-start bg-background">
					<Badge>{statusValue === "PUBLISHED" ? "Publié" : "Test"}</Badge>
				</CardFooter>
			</Card>
			<Dialog open={isOpen} onOpenChange={setIsOpen}>
				<DialogContent className="z-50 sm:max-w-[425px]">
					<DialogHeader>
						<DialogTitle>Ajouter un projet</DialogTitle>
					</DialogHeader>
					<UpdateProjectForm
						projectId={projectId}
						onProjectUpdated={() => setIsOpen(false)}
					/>
				</DialogContent>
			</Dialog>
		</>
	);
};

const ProjectCardLoading = () => {
	return (
		<div className="flex h-full w-full flex-col space-y-3 overflow-hidden rounded-xl border bg-card">
			<Skeleton className="h-[250px] bg-card" />
			<div className="space-y-2 bg-background p-4">
				<Skeleton className="h-5 w-[250px]" />
				<Skeleton className="h-5 w-[200px]" />
			</div>
		</div>
	);
};

export { ProjectCard, ProjectCardLoading };
