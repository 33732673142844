import type { SheetData } from "@/components/spreadsheet";
import type * as XLSX from "xlsx";
import { useCallback, useState } from "react";
import { EnumValuesPreview } from "@/components/enumImporter/EnumValuesPreview";
import WorkBookReaderForm from "@/components/enumImporter/WorkBookReaderForm";
import { SpreadSheetViewer } from "@/components/spreadsheet";
import { WorkbookSelector } from "@/components/spreadsheet/WorkbookSelector";

import type { EnumValue } from "@repos/rate-resolver-dtos";

function WorkBookReader() {
	const [workbook, setWorkbook] = useState<XLSX.WorkBook | null>(null);
	const [selectedSheet, setSelectedSheet] = useState<string | null>(null);
	const [selectedSheetData, setSelectedSheetData] = useState<SheetData | null>(
		null,
	);
	const [isFirstRowHeader, setIsFirstRowHeader] = useState<boolean>(false);
	const [userSelectedData, setUserSelectedData] = useState<SheetData | null>(
		null,
	);
	const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

	const enumValues = useCallback(() => {
		if (!userSelectedData) {
			return [];
		}
		const enumValues = userSelectedData
			.map((row) => ({
				label: row[0],
				value: row[1],
			}))
			.filter((item) => item.value !== undefined);

		const uniqueEnumValues = Array.from(
			// Filters out duplicate values
			new Map(enumValues.map((item) => [item.value, item])).values(),
		);
		return uniqueEnumValues as EnumValue[];
	}, [userSelectedData]);

	const reset = () => {
		setSelectedSheet(null);
		setSelectedSheetData(null);
		setIsFirstRowHeader(false);
		setUserSelectedData(null);
		setIsButtonDisabled(true);
	};

	return (
		<div>
			<WorkbookSelector
				workbook={workbook}
				setWorkbook={setWorkbook}
				selectedSheet={selectedSheet}
				setSelectedSheet={setSelectedSheet}
				reset={reset}
				isFirstRowHeader={isFirstRowHeader}
				setIsFirstRowHeader={setIsFirstRowHeader}
				setSelectedSheetData={setSelectedSheetData}
			/>
			{selectedSheet && selectedSheetData && (
				<SpreadSheetViewer
					sheetData={selectedSheetData}
					isFirstRowHeader={isFirstRowHeader}
					onDataSelect={({ selectedData, isSingleColumn, numberOfColumns }) => {
						if (isSingleColumn || numberOfColumns < 2) {
							setIsButtonDisabled(true);
							return;
						}
						setUserSelectedData(selectedData);
						setIsButtonDisabled(false);
					}}
					height={300}
				/>
			)}

			{enumValues.length > 0 && <EnumValuesPreview enumValues={enumValues()} />}

			{userSelectedData && (
				<WorkBookReaderForm
					userSelectedData={userSelectedData}
					isButtonDisabled={isButtonDisabled}
					setIsButtonDisabled={setIsButtonDisabled}
				/>
			)}
		</div>
	);
}

export default WorkBookReader;
