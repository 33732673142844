import { Button } from "@/components/ui/button";
import {
	Sheet,
	SheetContent,
	SheetHeader,
	SheetTitle,
	SheetTrigger,
} from "@/components/ui/sheet";
import { MenuIcon } from "lucide-react";

import Menu from "./menu";

export function SheetMenu() {
	return (
		<Sheet>
			<SheetTrigger className="lg:hidden" asChild>
				<Button className="h-8" variant="outline" size="icon">
					<MenuIcon size={20} />
				</Button>
			</SheetTrigger>
			<SheetContent className="flex h-full flex-col px-3 sm:w-72" side="left">
				<SheetHeader>
					<Button
						className="flex items-center justify-center pb-2 pt-1"
						variant="link"
						asChild
					>
						<a href="/projets" className="flex items-center gap-2">
							<img src="/favicon.webp" />
							<SheetTitle className="text-lg font-bold">
								AS SOLUTIONS
							</SheetTitle>
						</a>
					</Button>
				</SheetHeader>
				<Menu isOpen />
			</SheetContent>
		</Sheet>
	);
}
