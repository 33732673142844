import type { FC } from "react";
import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-react";

interface SubmitButtonProps {
	isLoading: boolean;
	label: string;
	disabled?: boolean;
	className?: string;
}

export const SubmitButton: FC<SubmitButtonProps> = ({
	isLoading,
	label,
	disabled = false,
	className = "",
}) => {
	return (
		<Button
			variant="default"
			className={`font-medium ${className}`}
			type="submit"
			disabled={disabled || isLoading}
		>
			{label}
			{isLoading && <Loader2 className="ml-2 h-4 w-4 animate-spin" />}
		</Button>
	);
};
