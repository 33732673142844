import { trpc } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "sonner";

import type { CreateProjectDTO } from "@repos/rate-resolver-dtos";
import { createProjectSchema } from "@repos/rate-resolver-dtos";

import { SubmitButton } from "../shared/SubmitButton";
import { DialogFooter } from "../ui/dialog";
import { FormControl, FormField, FormItem, FormMessage } from "../ui/form";
import { Input } from "../ui/input";
import { Label } from "../ui/label";

type AddProjectProps = {
	onProjectCreated?: () => void;
};

export const AddProjectForm = ({ onProjectCreated }: AddProjectProps) => {
	const utils = trpc.useUtils();

	const form = useForm<CreateProjectDTO>({
		resolver: zodResolver(createProjectSchema),
		defaultValues: {
			name: "",
			description: "",
			image: "",
		},
	});

	const { handleSubmit, control, reset } = form;

	const { mutateAsync: createProject, isLoading } =
		trpc.projects.createProject.useMutation({
			onSuccess: () => {
				toast.success("Project created successfully");
			},
		});

	const onSubmit = async (formData: CreateProjectDTO) => {
		try {
			await createProject(formData);
			if (onProjectCreated) {
				onProjectCreated();
				utils.projects.getProjects.invalidate();
				reset();
			}
		} catch (error) {
			console.error("TRPC mutation error:", error);
		}
	};

	return (
		<FormProvider {...form}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid gap-4 py-4">
					<FormField
						control={control}
						name="name"
						render={({ field }) => (
							<FormItem>
								<Label>Name</Label>
								<FormControl>
									<Input placeholder="Nom du projet" {...field} />
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<FormField
						control={control}
						name="description"
						render={({ field }) => (
							<FormItem>
								<Label>Description</Label>
								<FormControl>
									<Input placeholder="Description du projet" {...field} />
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<FormField
						control={control}
						name="image"
						render={({ field }) => (
							<FormItem>
								<Label>Image URL</Label>
								<FormControl>
									<Input
										placeholder="https://example.com/image.jpg"
										{...field}
									/>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>
				<DialogFooter>
					<SubmitButton isLoading={isLoading} label="Envoyer" />
				</DialogFooter>
				{Object.keys(form.formState.errors).length > 0 && (
					<div>
						{Object.entries(form.formState.errors).map(([key, error]) => (
							<p key={key}>{error.message}</p>
						))}
					</div>
				)}
			</form>
		</FormProvider>
	);
};
