import { cn } from "@/utils";
import {
	ChevronsLeftRightEllipsis,
	FileQuestion,
	FolderDot,
	Globe,
	User,
} from "lucide-react";

import type { VariableCreateDTO } from "@repos/rate-resolver-dtos";

type CategoryIconProps = {
	category: VariableCreateDTO["data"]["category"];
	className?: string;
};
export const CategoryIcon = ({ category, className }: CategoryIconProps) => {
	switch (category) {
		case "INPUT":
			return <ChevronsLeftRightEllipsis className={cn("size-6", className)} />;
		case "GLOBAL_CONFIG":
			return <Globe className={cn("size-6", className)} />;
		case "PROJECT_CONFIG":
			return <FolderDot className={cn("size-6", className)} />;
		case "USER":
			return <User className={cn("size-6", className)} />;
		default:
			return <FileQuestion className={cn("size-6", className)} />;
	}
};
