import type { EnumValue } from "@repos/rate-resolver-dtos";

const numberOfOptions = 4;
export function EnumValuesPreview({ enumValues }: { enumValues: EnumValue[] }) {
	return (
		<div>
			<p>Options importés: </p>
			<p>
				{enumValues
					.slice(0, numberOfOptions)
					.reduce(
						(acc, curr, index) =>
							acc +
							curr.label +
							"( ID: " +
							curr.value +
							" )" +
							(index !== 3 ? ", " : ""),
						"",
					)}

				{enumValues.length - numberOfOptions > 0 ? (
					<span className="font-semibold">
						{" "}
						+ {enumValues.length - numberOfOptions} autres options
					</span>
				) : null}
			</p>
		</div>
	);
}
