import { useMemo } from "react";
import { Label } from "@/components/ui/label";

import type { ApiOutputs } from "@repos/rate-resolver-api";
import type { Summary } from "@repos/rate-resolver-dtos";
import { NodeType } from "@repos/rate-resolver-shared";

import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "../../ui/table";

interface SummaryTableProps {
	nodeSummary: ApiOutputs["pricingEngine"]["testRate"]["summary"]["nodeSummaries"][0];
}
export const SummaryTable = ({ nodeSummary }: SummaryTableProps) => {
	const data = useMemo(() => {
		const nodeType = nodeSummary.type;
		switch (nodeType) {
			case NodeType.CONDITIONS_GROUP:
				return nodeSummary.data.conditionGroups;
			case NodeType.OPERATIONS:
				return nodeSummary.data.operationSummaries;
			case NodeType.OUTPUT:
				return nodeSummary.data.operationSummaries;
			case NodeType.ERROR_MESSAGE:
				return [
					{
						id: "err",
						result: "Fin parcours.",
						text: nodeSummary.data.message,
						textWithValues: "",
					},
				] as Summary[];
			default:
				return [];
		}
	}, [nodeSummary]);

	const columnName = useMemo(() => {
		const nodeType = nodeSummary.type;
		switch (nodeType) {
			case NodeType.CONDITIONS_GROUP:
				return "Groupe de conditions";
			case NodeType.OPERATIONS:
				return "Operation";
			case NodeType.OUTPUT:
				return "Sortie";
			case NodeType.START:
				return "Début";
			case NodeType.END:
				return "Fin";
			case NodeType.ERROR_MESSAGE:
				return "Message d'erreur";
		}
	}, [nodeSummary.type]);

	if (data.length === 0) {
		return null;
	}

	return (
		<div className="mt-4 flex flex-col justify-center">
			<Label>Détails {columnName}</Label>
			{/* This should only be a temporary workaround */}
			{/* TODO: Refactor this */}
			{nodeSummary.type === NodeType.CONDITIONS_GROUP ? (
				data.map((group, index) => (
					<div className="ml-8 mt-4" key={index}>
						<Label>Groupe {index + 1}</Label>
						<div className="my-3 rounded-md border">
							<Table>
								<TableHeader>
									<TableRow>
										<TableHead>Condition </TableHead>
										<TableHead>Valeur</TableHead>
										<TableHead className="text-right">Résultat</TableHead>
									</TableRow>
								</TableHeader>
								<TableBody>
									{group.conditionSummaries.map((summary, index) => (
										<TableRow key={`${summary.id}-${index}`}>
											<TableCell className="font-medium">
												{summary.text}
											</TableCell>
											<TableCell>{summary.textWithValues}</TableCell>
											<TableCell className="text-right">
												{summary.result}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</div>
					</div>
				))
			) : (
				<div className="my-3 rounded-md border">
					<Table>
						<TableHeader>
							<TableRow>
								<TableHead>{columnName}</TableHead>
								<TableHead>Valeur</TableHead>
								<TableHead className="text-right">Résultat</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody>
							{data.map((summary, index) => (
								<TableRow key={`${summary.id}-${index}`}>
									<TableCell className="font-medium">{summary.text}</TableCell>
									<TableCell>{summary.textWithValues}</TableCell>
									<TableCell className="text-right">{summary.result}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</div>
			)}
		</div>
	);
};
