import type { PropertyReturnType } from "@/types";
import { useEffect } from "react";
import { CustomSelect } from "@/components/shared/CustomSelect";
import StaticValueInput from "@/components/shared/StaticValueInput";
import { Button } from "@/components/ui/button";
import { DialogContent, DialogTitle } from "@/components/ui/dialog";
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table";
import { useAppStore } from "@/stores/useAppStore";
import { trpc } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2 } from "lucide-react";
import {
	FormProvider,
	useFieldArray,
	useForm,
	useWatch,
} from "react-hook-form";

import type { PropertyUpsertInput } from "@repos/rate-resolver-dtos";
import type { SimpleVariable } from "@repos/rate-resolver-shared";
import { propertyUpsertSchema } from "@repos/rate-resolver-dtos";
import {
	getPropertyTypeOptions,
	isNullOrUndefined,
} from "@repos/rate-resolver-shared";

type PropertyDialogProps =
	| {
			onClose: () => void;
			isEditMode: true;
			variableId: string;
			propertyId: string; // Required in edit mode
			onPropertySaved?: (property: PropertyUpsertInput) => void;
	  }
	| {
			onClose: () => void;
			isEditMode: false;
			variableId: string;
			propertyId?: undefined; // Not allowed in create mode
			onPropertySaved?: (property: PropertyUpsertInput) => void;
	  };

export const PropertyUpsertDialog = ({
	onClose,
	variableId,
	propertyId,
	onPropertySaved,
	isEditMode,
}: PropertyDialogProps) => {
	const { selectedProject } = useAppStore((state) => ({
		selectedProject: state.selectedProject,
	}));

	const { data: property } = trpc.properties.getById.useQuery(
		{
			variableId,
			propertyId: isEditMode ? propertyId : "",
			projectId: selectedProject?.id ?? "",
		},
		{
			enabled:
				!isNullOrUndefined(selectedProject) &&
				isEditMode &&
				!isNullOrUndefined(propertyId),
			networkMode: "always",
		}, // Fetch only in edit mode
	);

	const { data: variable } = trpc.variables.getVariable.useQuery(
		{
			id: variableId,
			projectId: selectedProject?.id ?? "",
		},
		{
			enabled: !isNullOrUndefined(selectedProject) && !isEditMode,
			networkMode: "always",
		},
	);

	const form = useForm<PropertyUpsertInput>({
		resolver: zodResolver(propertyUpsertSchema),
		defaultValues: getDefaultValues({
			variable,
			property,
			isEditMode,
			projectId: selectedProject?.id ?? "",
			variableId,
		}) as PropertyUpsertInput,
	});

	const { fields, replace } = useFieldArray({
		control: form.control,
		name: "values",
		keyName: "enumValueId",
	});

	useEffect(() => {
		if (selectedProject) {
			const defaultValues = getDefaultValues({
				variable,
				property,
				isEditMode,
				projectId: selectedProject.id || "",
				variableId,
			}) as PropertyUpsertInput;
			form.reset(defaultValues);
		}
	}, [
		isEditMode,
		property,
		form,
		selectedProject,
		replace,
		variableId,
		variable,
	]);

	const propertyType = useWatch({ control: form.control, name: "type" });
	const propertyLabel = useWatch({
		control: form.control,
		name: "label",
		defaultValue: "",
	});
	const values = useWatch({
		control: form.control,
		name: "values",
		defaultValue: [],
	});

	const {
		mutateAsync: upsertProperty,
		isLoading,
		error,
	} = trpc.properties.upsert.useMutation();

	const onSubmit = async (data: PropertyUpsertInput) => {
		try {
			await upsertProperty(data);
			onPropertySaved?.(data);
			onClose();
		} catch (err) {
			console.log("Caught error:", err);
		}
	};

	return (
		<DialogContent className="h-[60vh] max-w-[60vw] overflow-y-auto">
			<FormProvider {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)}>
					<div className="flex h-full flex-col justify-between">
						<div className="grid gap-4">
							<DialogTitle>
								<h4 className="font-medium leading-none">
									{isEditMode
										? `Modifier ${property?.label.toLowerCase() || ""}`
										: "Créer une nouvelle propriété"}
								</h4>
							</DialogTitle>

							<div className="grid grid-cols-1 gap-4 px-2 py-2 xl:grid-cols-2 ">
								<div className="flex flex-col">
									<FormField
										control={form.control}
										name="label"
										render={({ field }) => (
											<FormItem>
												<FormLabel>Libelle propriété</FormLabel>
												<FormControl>
													<Input
														placeholder="Libelle de la propriété"
														{...field}
													/>
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>
								</div>
								<div className="flex flex-col">
									<FormField
										control={form.control}
										name="key"
										render={({ field }) => (
											<FormItem>
												<FormLabel>Identifiant de la propriété</FormLabel>
												<FormControl>
													<Input placeholder="Identifiant unique" {...field} />
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>
								</div>
								<div className="flex flex-col">
									<FormField
										control={form.control}
										name="type"
										render={({ field }) => (
											<FormItem>
												<FormLabel>Type de la valeur</FormLabel>
												<CustomSelect
													placeholder="Selectionner un type de valeur"
													options={propertyTypeOptions}
													value={field.value}
													onValueChange={(value) => {
														if (value !== field.value) {
															field.onChange(value);
															replace(
																//@ts-expect-error we need to reset the value
																values.map((item) => ({
																	...item,
																	value: null,
																})),
															);
														}
													}}
													className="h-8 w-full"
													isForm
												/>
												<FormMessage />
											</FormItem>
										)}
									/>
								</div>
							</div>
							{propertyTypeOptions.some(
								(option) => option.value === propertyType,
							) && fields.length > 0 ? (
								<>
									<Table>
										<TableHeader>
											<TableRow>
												<TableHead>Libelle de l&apos;option</TableHead>
												<TableHead>
													Valeur{" "}
													{propertyLabel.length > 0
														? propertyLabel.toLowerCase()
														: "propriété"}
												</TableHead>
											</TableRow>
										</TableHeader>
										<TableBody>
											{fields.map((field, index) => (
												<TableRow key={field.enumValueId}>
													<TableCell>{field.enumValueLabel}</TableCell>
													<TableCell>
														<FormField
															control={form.control}
															name={`values.${index}.value`}
															render={({ field }) => (
																<FormItem>
																	<FormControl>
																		<StaticValueInput
																			variableType={propertyType}
																			rawValue={field.value}
																			rawValueInput={field.value}
																			handleValueChange={(value) =>
																				field.onChange(value)
																			}
																			setValueInput={(value) =>
																				isNullOrUndefined(value)
																					? field.onChange(undefined)
																					: field.onChange(value)
																			}
																		/>
																	</FormControl>
																	<FormMessage />
																</FormItem>
															)}
														/>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
									{error && (
										<div className="my-2 text-red-600">
											Erreur: {error.message}
										</div>
									)}
								</>
							) : null}
						</div>
						<div className="flex items-center justify-end gap-5">
							<Button
								type="button"
								className="w-1/2"
								variant={"destructive"}
								disabled={isLoading}
								onClick={onClose}
							>
								Annuler
							</Button>
							<Button
								type="submit"
								className="w-1/2"
								variant={"secondary"}
								disabled={isLoading}
							>
								{isEditMode ? "Mettre à jour" : "Créer"}
								{isLoading && <Loader2 className="ml-2 h-4 w-4 animate-spin" />}
							</Button>
						</div>
					</div>
				</form>
			</FormProvider>
		</DialogContent>
	);
};

const propertyTypeOptions = getPropertyTypeOptions();

function getDefaultValues({
	variable,
	property,
	isEditMode,
	projectId,
	variableId,
}: {
	variable: SimpleVariable | undefined;
	property: PropertyReturnType | undefined;
	isEditMode: boolean;
	projectId: string;
	variableId: string;
}) {
	if (isEditMode && property) {
		return {
			key: property.key,
			label: property.label,
			type: property.type,
			projectId,
			propertyId: property.id,
			variableId,
			values: property.propertyValues.map(
				({ enumValueId, value, enumValueLabel }) => ({
					enumValueId,
					value: value as string,
					enumValueLabel,
				}),
			),
		};
	}
	if (!isEditMode && variable) {
		const values = variable.enumValues.map((enumValue) => ({
			enumValueId: enumValue.id,
			value: undefined,
			enumValueLabel: enumValue.label,
		}));

		return {
			key: "",
			label: "",
			type: undefined,
			projectId,
			propertyId: undefined,
			variableId,
			values: [...values],
		};
	}
	return {
		key: "",
		label: "",
		type: undefined,
		projectId,
		propertyId: undefined,
		variableId,
		values: [],
	};
}
