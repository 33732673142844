import { Sidebar } from "@/components/admin-panel/sidebar";
import { useLayoutStore } from "@/stores/useLayoutStore";
import { useSidebarToggleStore } from "@/stores/useSidebarToggleStore";
import { cn } from "@/utils";

export default function AdminPanelLayout({
	children,
}: {
	children: React.ReactNode;
}) {
	const sidebar = useLayoutStore(useSidebarToggleStore, (state) => state);

	if (!sidebar) return null;

	return (
		<>
			<Sidebar />
			<main
				className={cn(
					"min-h-[calc(100vh_-_56px)] bg-zinc-50 transition-[margin-left] duration-300 ease-in-out dark:bg-zinc-900",
					sidebar.isOpen === false ? "lg:ml-[90px]" : "lg:ml-72",
				)}
			>
				{children}
			</main>
		</>
	);
}
