import { useAppStore } from "@/stores";

import { NodeSummaryCard } from "./NodeSummaryCard";
import OuputVariables from "./OuputVariables";

export const TarificationSummary = () => {
	const { tarificationSummary } = useAppStore((state) => ({
		tarificationSummary: state.tarificationSummary,
	}));

	return (
		<>
			{tarificationSummary && (
				<>
					<OuputVariables />
					{tarificationSummary.summary.nodeSummaries.map((item, index) => (
						<NodeSummaryCard
							key={`summ${index}`}
							nodeSummary={item}
							index={index + 1}
						/>
					))}
				</>
			)}
		</>
	);
};
