import { ClerkProvider } from "@clerk/clerk-react";

// Import your publishable key

export const AuthProvider = ({ children }) => {
	return (
		<ClerkProvider
			publishableKey={import.meta.env.VITE_CLERK_PUBLISHABLE_KEY}
			afterSignOutUrl="/sign-in"
			signInUrl="/sign-in"
			signUpUrl="/sign-up"
			appearance={{
				variables: {
					colorPrimary: "hsl(210 82% 57%)",
				},
			}}
		>
			{children}
		</ClerkProvider>
	);
};
