import type { FormattedEdge } from "@/types";

import type { ApiOutputs } from "@repos/rate-resolver-api";

export const formatEdge = (
	edge: ApiOutputs["edges"]["getEdge"],
): FormattedEdge => ({
	...edge,
	target: edge.target || "",
	label: edge.data.label || "",
	type: "custom-edge",
});
