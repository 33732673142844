import type { VariableType } from "@prisma/client";
import { useEffect, useMemo } from "react";

import { getVariableTypeOptions } from "@repos/rate-resolver-shared";

import { CustomSelect } from "./CustomSelect";
import StaticValueInput from "./StaticValueInput";

type StaticValueWithSelectorProps = {
	variableTypes: VariableType[];
	rawValue?: string | number | boolean | Date | null | undefined;
	rawValueInput: string | null;
	handleValueChange: (
		value: string | number | boolean | Date | null | undefined,
		selectedType: VariableType,
	) => void;
	setValueInput: (value: string) => void;
	selectedType: VariableType | null;
	setSelectedType: (type: VariableType) => void;
};

export const StaticValueWithSelector = ({
	variableTypes,
	rawValue,
	rawValueInput,
	handleValueChange,
	setValueInput,
	selectedType,
	setSelectedType,
}: StaticValueWithSelectorProps) => {
	const variableTypeOptions = useMemo(() => {
		const allOptions = getVariableTypeOptions();
		// Filter only the allowed types
		return allOptions.filter((option) => variableTypes.includes(option.value));
	}, [variableTypes]);

	useEffect(() => {
		if (variableTypeOptions.length === 1) {
			setSelectedType(variableTypeOptions[0]?.value as VariableType);
		}
	}, [variableTypeOptions, setSelectedType]);

	const handleTypeChange = (value: string) => {
		setSelectedType(value as VariableType);
	};

	return (
		<div className="flex w-full flex-col gap-2">
			{variableTypeOptions.length > 1 && (
				<CustomSelect
					placeholder="Selectionner"
					options={variableTypeOptions}
					value={selectedType || ""}
					onValueChange={handleTypeChange}
					className="w-full"
				/>
			)}
			{selectedType !== null && (
				<StaticValueInput
					variableType={selectedType}
					rawValue={rawValue}
					rawValueInput={rawValueInput}
					handleValueChange={(value) => handleValueChange(value, selectedType)}
					setValueInput={setValueInput}
				/>
			)}
		</div>
	);
};
