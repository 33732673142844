import TarificationOutput from "@/components/tarification/TarificationOutput";
import { TarificationTestForm } from "@/components/tarification/TarificationTestForm";
import {
	ResizableHandle,
	ResizablePanel,
	ResizablePanelGroup,
} from "@/components/ui/resizable";

export const Tarification = () => {
	return (
		<ResizablePanelGroup
			direction="horizontal"
			className="h-auto w-full rounded-md border border-gray-500"
		>
			<ResizablePanel defaultSize={60} minSize={30} className="p-6">
				<TarificationTestForm />
			</ResizablePanel>
			<ResizableHandle withHandle />
			<ResizablePanel defaultSize={40} minSize={30} className="p-6">
				<TarificationOutput />
			</ResizablePanel>
		</ResizablePanelGroup>
	);
};
