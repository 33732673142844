import { useEffect } from "react";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { useThemeStore } from "@/stores/useThemeStore";
import { MoonIcon, SunIcon } from "@radix-ui/react-icons";

import { Button } from "../ui/button";

const ModeToggle = () => {
	const { theme, toggleTheme } = useThemeStore();

	useEffect(() => {
		// Apply theme to document
		if (theme === "dark") {
			document.documentElement.classList.add("dark");
		} else {
			document.documentElement.classList.remove("dark");
		}
	}, [theme]);

	return (
		<TooltipProvider>
			<Tooltip>
				<TooltipTrigger asChild>
					<Button
						variant="outline"
						size="icon"
						onClick={toggleTheme}
						className="h-8 w-8 rounded-full bg-background"
					>
						<SunIcon className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
						<MoonIcon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
						<span className="sr-only">Changer de thème</span>
					</Button>
				</TooltipTrigger>
				<TooltipContent>
					<p>Changer de thème</p>
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	);
};

export default ModeToggle;
