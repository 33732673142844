import { useEffect } from "react";
import { useThemeStore } from "@/stores";
import { applyTheme } from "@/utils";
import { useAuth } from "@clerk/clerk-react";
import { Outlet, useNavigate } from "react-router-dom";

export const RootLayout = () => {
	const { isSignedIn, isLoaded } = useAuth();
	const navigate = useNavigate();
	const theme = useThemeStore((state) => state.theme);

	useEffect(() => {
		applyTheme(theme);
	}, [theme]);

	useEffect(() => {
		if (isLoaded && isSignedIn) {
			navigate("/projets");
		}
	}, [isLoaded, isSignedIn, navigate]);

	return (
		<main className="h-screen w-screen dark:bg-zinc-900">
			<Outlet />
		</main>
	);
};
