import type { Column, ColumnDef, Row } from "@tanstack/react-table";
import { VariableActions } from "@/components/dataTable/variables";
import { Button } from "@/components/ui/button";
import { trpc } from "@/utils";
import { ArrowUpDown } from "lucide-react";

import type { SimpleVariable } from "@repos/rate-resolver-shared";
import {
	formatCategory,
	getVariableToString,
} from "@repos/rate-resolver-shared";

interface GetColumnsProps {
	headers: { [key: string]: string };
	refetchVariables: () => void;
}

export const getColumns = ({
	headers,
	refetchVariables,
}: GetColumnsProps): ColumnDef<SimpleVariable>[] => {
	return [
		...Object.keys(headers).map((key, index) => ({
			accessorKey: key,
			header: ({ column }: { column: Column<SimpleVariable> }) => (
				<Button
					variant="ghost"
					onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
				>
					{headers[key]}
					<ArrowUpDown className="ml-2 h-4 w-4" />
				</Button>
			),
			cell: ({ row }: { row: Row<SimpleVariable> }) => (
				<div className="line-clamp-1">
					{index === 0 && row.original.label}
					{index === 1 && (formatCategory(row.original.category) ?? "-")}
					{index === 2 && getVariableToString(row.original.defaultValue || "-")}
					{index === 3 && row.original.type}
				</div>
			),
		})),
		{
			id: "actions",
			cell: ({ row }) => {
				const { id, type } = row.original;

				const deleteVariableMutation =
					trpc.variables.deleteVariable.useMutation({
						onSuccess: () => {
							refetchVariables();
							console.log(`Variable with ID: ${id} deleted successfully.`);
						},
						onError: (error) => {
							console.error("Failed to delete the variable:", error);
						},
					});

				const handleDelete = () => {
					deleteVariableMutation.mutate({ id });
				};

				return (
					<VariableActions
						variableId={id}
						isEnum={type === "ENUM"}
						onEdit={refetchVariables}
						onDelete={handleDelete}
						iconOrientation="horizontal"
					/>
				);
			},
		},
	];
};
