import type { PropertyReturnType } from "@/types";
import type { Column, ColumnDef, Row } from "@tanstack/react-table";
import { PropertyActions } from "@/components/dataTable/properties/PropertyActions";

import { getVariableToString } from "@repos/rate-resolver-shared";

interface GetColumnsProps {
	headers: { [key: string]: string };
}

export const getColumns = ({
	headers,
}: GetColumnsProps): ColumnDef<PropertyReturnType>[] => {
	return [
		...Object.keys(headers).map((key, index) => ({
			accessorKey: key,
			// eslint-disable-next-line no-empty-pattern
			header: ({}: { column: Column<PropertyReturnType> }) => (
				<div className="py-2 pl-0 pr-4">{headers[key]}</div>
			),
			cell: ({ row }: { row: Row<PropertyReturnType> }) => {
				const { propertyValues, label } = row.original;
				const propertyValue = propertyValues.find(
					(propertyValue) => propertyValue.enumValueId === key,
				);
				const value = index === 0 ? label : propertyValue?.value || "-";
				return <div className="line-clamp-1">{getVariableToString(value)}</div>;
			},
		})),
		{
			id: "actions",
			cell: ({ row }) => {
				const { id, variableId } = row.original;

				return (
					<PropertyActions
						propertyId={id}
						variableId={variableId}
						iconOrientation="horizontal"
					/>
				);
			},
		},
	];
};
