import { useEffect } from "react";
import { trpc } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "sonner";

import type { UpdateProjectDTO } from "@repos/rate-resolver-dtos";
import { updateProjectSchema } from "@repos/rate-resolver-dtos";

import { SubmitButton } from "../shared/SubmitButton";
import { DialogFooter } from "../ui/dialog";
import { FormControl, FormField, FormItem, FormMessage } from "../ui/form";
import { Input } from "../ui/input";
import { Label } from "../ui/label";

type UpdateProjectProps = {
	projectId: string;
	onProjectUpdated?: () => void;
};

export const UpdateProjectForm = ({
	projectId,
	onProjectUpdated,
}: UpdateProjectProps) => {
	const { data: project } = trpc.projects.getProject.useQuery({
		id: projectId,
	});

	const utils = trpc.useUtils();

	const form = useForm<UpdateProjectDTO>({
		resolver: zodResolver(updateProjectSchema),
		defaultValues: {
			id: projectId,
			name: project?.name || "",
			description: project?.description || "",
			image: project?.image || "",
		},
	});

	useEffect(() => {
		if (project) {
			form.reset({
				id: project.id,
				name: project.name,
				description: project.description,
				image: project.image || "",
			});
		}
	}, [project, form]);

	const { handleSubmit, control, reset } = form;

	const { mutateAsync, isLoading } = trpc.projects.updateProject.useMutation({
		onSuccess: () => {
			toast.success("Project updated successfully");
		},
	});

	const onSubmit = async (formData: UpdateProjectDTO) => {
		try {
			await mutateAsync(formData);
			if (onProjectUpdated) {
				onProjectUpdated();
				utils.projects.getProjects.invalidate();
				reset();
			}
		} catch (error) {
			console.error("TRPC mutation error:", error);
		}
	};

	return (
		<FormProvider {...form}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid gap-4 py-4">
					<FormField
						control={control}
						name="name"
						render={({ field }) => (
							<FormItem>
								<Label>Name</Label>
								<FormControl>
									<Input placeholder="Nom du projet" {...field} />
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<FormField
						control={control}
						name="description"
						render={({ field }) => (
							<FormItem>
								<Label>Description</Label>
								<FormControl>
									<Input placeholder="Description du projet" {...field} />
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<FormField
						control={control}
						name="image"
						render={({ field }) => (
							<FormItem>
								<Label>Image URL</Label>
								<FormControl>
									<Input
										placeholder="https://example.com/image.jpg"
										{...field}
									/>
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>
				<DialogFooter>
					<SubmitButton isLoading={isLoading} label="Enregistrer" />
				</DialogFooter>
			</form>
		</FormProvider>
	);
};
