import { NodeToolbar, Position } from "@xyflow/react";

import { ToolbarButtons } from "./ToolbarButtons";

//type redundant in ToolbarButtons and NodeToolbarProps
type NodeToolbarProps = {
	isVisible: boolean;
	disableEdit?: boolean;
	isLoading?: boolean;
	onDuplicate?: () => void;
	onDelete: () => void;
	onEdit?: () => void;
};

export const NodeToolbarComponent = ({
	isVisible,
	onDelete,
	onEdit,
	onDuplicate,
	disableEdit = false,
	isLoading = false,
}: NodeToolbarProps) => {
	return (
		<NodeToolbar
			className="flex items-center overflow-hidden rounded-sm border-[0.05rem] border-gray-500 bg-white dark:bg-card"
			isVisible={isVisible}
			position={Position.Top}
			align={"end"}
		>
			<ToolbarButtons
				onDelete={onDelete}
				onEdit={onEdit}
				onDuplicate={onDuplicate}
				disableEdit={disableEdit}
				isLoading={isLoading}
			/>
		</NodeToolbar>
	);
};
