import type { Node, NodeProps } from "@xyflow/react";
import { useEffect, useState } from "react";
import { useCopyPasteActions } from "@/hooks/useCopyPaste";
import { useNodeActions } from "@/hooks/useNodeActions";
import { getDefaultOperation } from "@/lib";
import usePricingFlowStore from "@/stores/flow/useFlowStore";
import { useAppStore } from "@/stores/useAppStore";
import { formatNode } from "@/utils/formatNode";
import { trpc } from "@/utils/trpc";
import { pointerWithin } from "@dnd-kit/core";
import { zodResolver } from "@hookform/resolvers/zod";
import { Position, useReactFlow } from "@xyflow/react";
import { Loader2Icon, Plus } from "lucide-react";
import {
	FormProvider,
	useFieldArray,
	useForm,
	useWatch,
} from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";
import { useShallow } from "zustand/react/shallow";

import type {
	OperationNode,
	OperationsNodeUpdateDTO,
} from "@repos/rate-resolver-dtos";
import {
	operationSchema,
	operationsNodeUpdateSchema,
} from "@repos/rate-resolver-dtos";
import { NodeType, TargetHandle } from "@repos/rate-resolver-shared";

import { CopyPasteActions } from "../shared/CopyPasteActions";
import { CustomHandle } from "../shared/CustomHandle";
import { LabelEditor } from "../shared/LabelEditor";
import { NodeToolbarComponent } from "../shared/NodeToolbarComponent";
import { Button } from "../ui/button";
import { Card, CardContent, CardHeader } from "../ui/card";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../ui/dialog";
import { Sortable } from "../ui/sortable";
import { OperationBuilderV2 } from "./operationBuilder/OperationBuilder";

export type CustomOutputNode = Node<
	OperationNode["data"] & {
		label: string;
		id: string;
	},
	NodeType.OUTPUT
>;

export const CustomOutputNode = ({
	data,
	isConnectable,
	selected,
}: NodeProps<CustomOutputNode>) => {
	const { selectedProject } = useAppStore((state) => ({
		selectedProject: state.selectedProject,
	}));
	const { onUpdateNode, onNodesDelete, onDuplicateNode } = usePricingFlowStore(
		useShallow(({ onUpdateNode, onNodesDelete, onDuplicateNode }) => ({
			onUpdateNode,
			onNodesDelete,
			onDuplicateNode,
		})),
	);
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const [isOperationValid, setIsOperationValid] = useState(false);

	const methods = useForm<OperationsNodeUpdateDTO>({
		resolver: zodResolver(operationsNodeUpdateSchema),
		defaultValues: {
			nodeId: data.id,
			projectId: selectedProject?.id,
			label: data.label,
			operations: data.operations,
		},
	});

	const {
		fields: operations,
		remove,
		append,
		move,
		replace,
	} = useFieldArray({
		control: methods.control,
		name: `operations`,
	});

	const addOperation = () => {
		append(getDefaultOperation());
	};

	const operationWatch = useWatch({
		control: methods.control,
		name: `operations`,
		defaultValue: [],
	});

	const {
		handleCopy: handleCopyOperations,
		handleReplace: handleReplaceOperations,
		handleAppend: handleAppendOperations,
		copiedItems: copiedOperations,
	} = useCopyPasteActions({
		items: operationWatch,
		itemType: "operations",
		appendAction: append,
		replaceAction: replace,
	});

	const updateNodeMutation = trpc.nodes.updateOutputNode.useMutation({
		onSuccess: (_data) => {
			onUpdateNode(formatNode(_data));
			toast.success("Output mise à jour avec succès!", {
				action: {
					label: "X",
					onClick: () => toast.dismiss(),
				},
			});
		},
		onError: (error) => {
			toast.error(error.message, {
				action: {
					label: "X",
					onClick: () => toast.dismiss(),
				},
			});
		},
	});

	const handleSubmit = async (formData: OperationsNodeUpdateDTO) => {
		try {
			await updateNodeMutation.mutateAsync({
				nodeId: data.id,
				projectId: selectedProject?.id ?? "",
				label: data.label,
				operations: formData.operations,
			});
			setIsDialogOpen(false);
		} catch (error) {
			console.error("Failed to update node:", error);
		}
	};

	const deleteNodeMutation = trpc.nodes.deleteNode.useMutation({
		onSuccess: () => {
			onNodesDelete(data.id);
			toast.success("Node deleted successfully", {
				action: {
					label: "X",
					onClick: () => toast.dismiss(),
				},
			});
		},
		onError: (error) => {
			toast.error(`${error.message || "Failed to delete node"}`, {
				action: {
					label: "X",
					onClick: () => toast.dismiss(),
				},
			});
		},
	});
	const reactFlowInstance = useReactFlow();

	//Node duplicate mutation
	const { handleDuplicate } = useNodeActions({
		onDuplicateSuccess: (newNode) => {
			onDuplicateNode(formatNode(newNode)); // Update local state or store
		},
		reactFlowInstance,
	});

	const handleDelete = async () => {
		try {
			await deleteNodeMutation.mutateAsync({ id: data.id });
		} catch (error) {
			console.error("Error deleting node:", error);
		}
	};

	const isDeleting = deleteNodeMutation.isLoading;

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		if (data.operations) {
			methods.reset({
				nodeId: data.id,
				projectId: selectedProject?.id,
				label: data.label,
				operations: data.operations,
			});
		}
	}, [data, selectedProject, methods]);

	useEffect(() => {
		const validationOperationsResult = z
			.array(operationSchema)
			.safeParse(operationWatch);

		setIsOperationValid(validationOperationsResult.success);
	}, [operationWatch]);

	return (
		<Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
			<CustomHandle
				type="source"
				position={Position.Top}
				id="source"
				isConnectable={isConnectable}
				className="z-10 bg-black"
			/>
			<Card
				className={`relative w-full border border-black ${
					selected ? "ring-2 ring-blue-500" : ""
				}`}
			>
				<CardHeader className="pb-6">
					<LabelEditor
						initialLabel={data.label}
						onSubmit={async (newLabel) => {
							handleSubmit({
								nodeId: data.id,
								projectId: selectedProject?.id ?? "",
								label: newLabel,
								operations: data.operations,
							});
						}}
						nodeType={NodeType.OUTPUT}
					/>
				</CardHeader>
				<CardContent>
					{data.operationSummaries.map((summarie) => (
						<Card className="mb-2" key={summarie.id}>
							<CardContent className="p-2 text-xs">
								{summarie.summary}
							</CardContent>
						</Card>
					))}

					<DialogContent className="w-full max-w-5xl">
						<DialogHeader>
							<DialogTitle>
								{data.operations.length ? "Edit Operations" : "Add Operations"}
							</DialogTitle>
						</DialogHeader>
						<FormProvider {...methods}>
							<form onSubmit={methods.handleSubmit(handleSubmit)}>
								<div className="mb-4 rounded-xl border border-gray-300 bg-card p-4 text-card-foreground shadow">
									<div className="flex w-full flex-row justify-end">
										<CopyPasteActions
											isValid={isOperationValid}
											hasFields={operations.length > 0}
											copiedItems={
												Array.isArray(copiedOperations) &&
												copiedOperations.length > 0
											}
											onCopy={handleCopyOperations}
											onReplace={handleReplaceOperations}
											onAppend={handleAppendOperations}
										/>
									</div>
									<Sortable
										orientation="mixed"
										collisionDetection={pointerWithin}
										value={operationWatch}
										onMove={({ activeIndex, overIndex }) =>
											move(activeIndex, overIndex)
										}
										overlay={
											<div className="size-full rounded-md bg-card/10" />
										}
									>
										{operationWatch.map((operation, indexOperation) => (
											<OperationBuilderV2
												key={operation.id}
												operation={operation}
												operationIndex={indexOperation}
												removeOperation={remove}
												basePath={"operations"}
											/>
										))}
									</Sortable>{" "}
									<div className="mt-4 flex justify-end">
										<Button
											variant="outline"
											size="sm"
											onClick={addOperation}
											type="button"
										>
											<Plus className="mr-2 h-4 w-4" />
											Ajouter une opération
										</Button>
									</div>
								</div>

								<div className="mt-4 flex justify-end">
									<Button type="submit" className="min-w-[150px]">
										{!updateNodeMutation.isLoading ? (
											<>{"Sauvegarder"}</>
										) : (
											<Loader2Icon className="animate-spin" />
										)}
									</Button>
								</div>
							</form>
						</FormProvider>
					</DialogContent>
				</CardContent>
			</Card>
			<CustomHandle
				type="target"
				position={Position.Bottom}
				id={TargetHandle.TARGET}
				isConnectable={isConnectable}
				className="bg-green-600"
			/>
			<NodeToolbarComponent
				isVisible={selected ?? false}
				onDelete={handleDelete}
				onDuplicate={() => handleDuplicate(data.id)}
				onEdit={() => setIsDialogOpen(true)}
				disableEdit={isDeleting}
			/>
		</Dialog>
	);
};
