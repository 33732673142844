import React, { useEffect } from "react";
import { CustomHandle } from "@/components/shared/CustomHandle";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { useThemeStore } from "@/stores";
import { Position, useNodeId, useUpdateNodeInternals } from "@xyflow/react";
import { GitFork } from "lucide-react";
import { useMeasure } from "react-use";

import type { ConditionsGroupNode } from "@repos/rate-resolver-dtos";
import { ConditionType, TargetHandle } from "@repos/rate-resolver-shared";

export const GroupRecapSummary = ({
	conditionSummaries,
	conditionType,
	groupIndex,
}: {
	conditionSummaries: ConditionsGroupNode["data"]["conditionGroups"][0]["conditionSummaries"];
	conditionType: ConditionType;
	groupIndex: number;
}) => {
	const theme = useThemeStore(({ theme }) => theme);

	const [ref, rect] = useMeasure<HTMLDivElement>();
	const id = useNodeId();
	const updateNodeInternals = useUpdateNodeInternals();

	useEffect(() => {
		if (id) {
			updateNodeInternals(id);
		}
	}, [rect, updateNodeInternals, id]);

	return (
		<div ref={ref} className="h-full w-full">
			<CustomHandle
				type="target"
				position={Position.Right}
				id={TargetHandle.CONDITION_TRUE(groupIndex)}
				style={{ bottom: rect.bottom + rect.height / 2 }}
				className="bg-green-600"
			/>
			{/* Header */}
			<CardHeader className="pb-2 pl-0 pr-0">
				<div className="flex w-full items-center">
					<span className="mr-2">
						<GitFork
							className="size-4"
							color={theme === "dark" ? "white" : "black"}
						/>
					</span>
					<p className="text-xs">Groupe Conditions {groupIndex + 1}</p>
				</div>
			</CardHeader>
			{conditionSummaries.map((summary, index) => (
				<React.Fragment key={index}>
					<Card className="mb-1 dark:bg-background" key={summary.id}>
						<CardContent className="p-1.5 text-xs">
							{summary.summary}
						</CardContent>
					</Card>
					{index < conditionSummaries.length - 1 && (
						<p className="mb-1 text-xs">
							{conditionType === ConditionType.AND ? "Et" : "OU"}
						</p>
					)}
				</React.Fragment>
			))}
		</div>
	);
};
