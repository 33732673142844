import type { Node, NodeProps } from "@xyflow/react";
import { CustomHandle } from "@/components/shared/CustomHandle";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { cn } from "@/utils";
import { Position } from "@xyflow/react";

import type { ConditionsGroupNode } from "@repos/rate-resolver-dtos";
import type { ConditionType, NodeType } from "@repos/rate-resolver-shared";
import { TargetHandle } from "@repos/rate-resolver-shared";

import { ConditionGroupSummary } from "./conditionGroupSummary/ConditionGroupSummary";

export type CustomConditionNodeSummary = Node<
	ConditionsGroupNode["data"] & {
		label: string;
		id: string;
		conditionType: ConditionType;
	},
	NodeType.CONDITIONS_GROUP
>;

export const CustomConditionsGroupNodeSummary = ({
	data,
	isConnectable,
	selected,
}: NodeProps<CustomConditionNodeSummary>) => {
	return (
		<>
			<CustomHandle
				type="source"
				position={Position.Top}
				id="source"
				isConnectable={isConnectable}
				className="z-10 bg-black"
			/>
			<Card
				className={cn(
					"relative h-full w-full max-w-[380px] border-2 border-black",
					selected ? "ring-2 ring-blue-500" : "",
				)}
			>
				<CardHeader className="pb-6">{data.label}</CardHeader>
				<CardContent className="flex flex-col gap-2">
					{data.conditionGroups.map((group, index) => (
						<ConditionGroupSummary
							key={`condition-group-${index}`}
							groupIndex={index}
							data={group}
						/>
					))}
				</CardContent>
			</Card>

			<CustomHandle
				type="target"
				position={Position.Bottom}
				id={TargetHandle.TARGET}
				isConnectable={isConnectable}
				className="bg-black"
			/>
		</>
	);
};
