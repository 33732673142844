import { useState } from "react";
import {
	ProjectCard,
	ProjectCardLoading,
} from "@/components/project/ProjectCard";
import { AddProjectForm } from "@/components/projects/AddProjectForm";
import { Card } from "@/components/ui/card";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import { useAppStore } from "@/stores/useAppStore.ts";
import { trpc } from "@/utils";
import { PlusCircle } from "lucide-react";
import { useNavigate } from "react-router-dom";

export default function Projects() {
	const [isOpen, setIsOpen] = useState(false);

	const { data, isLoading } = trpc.projects.getProjects.useQuery();
	const navigate = useNavigate();
	const { setSelectedProject, setActiveTab } = useAppStore((state) => ({
		setSelectedProject: state.setSelectedProject,
		setActiveTab: state.setActiveTab,
	}));

	return (
		<>
			<div className="m-8">
				<h1>Select a Project</h1>
			</div>
			<div className="grid auto-rows-fr grid-cols-1 gap-6 p-4 md:grid-cols-2 md:p-6 lg:grid-cols-3 xl:grid-cols-4	">
				{isLoading
					? new Array(10).fill(0).map((_, i) => <ProjectCardLoading key={i} />)
					: data?.map((project) => (
							<ProjectCard
								key={project.id}
								project={project}
								onSelect={() => {
									setSelectedProject(project);
									// Set the default active tab to variable first tab element
									setActiveTab("Variables");
									navigate("/projet/variables");
								}}
							/>
						))}

				{isOpen && (
					<div
						className="fixed inset-0 z-40 bg-black bg-opacity-50"
						onClick={() => setIsOpen(false)}
					/>
				)}

				<Card
					className="group relative flex cursor-pointer items-center justify-center overflow-hidden rounded-lg bg-background shadow-lg transition-transform duration-300 ease-in-out hover:-translate-y-2 hover:shadow-xl"
					onClick={() => setIsOpen(true)}
				>
					<PlusCircle className="size-12" />
				</Card>

				<Dialog open={isOpen} onOpenChange={setIsOpen}>
					<DialogContent className="z-50 sm:max-w-[425px]">
						<DialogHeader>
							<DialogTitle>Ajouter un projet</DialogTitle>
						</DialogHeader>
						<AddProjectForm onProjectCreated={() => setIsOpen(false)} />
					</DialogContent>
				</Dialog>
			</div>
		</>
	);
}
