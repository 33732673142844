import { create } from "zustand";
import { persist } from "zustand/middleware";

type ThemeState = {
	theme: "light" | "dark";
	setTheme: (theme: "light" | "dark") => void;
	toggleTheme: () => void;
};

const initializeTheme = (): "light" | "dark" => {
	if (typeof window !== "undefined") {
		const prefersDark = window.matchMedia(
			"(prefers-color-scheme: dark)",
		).matches;
		return prefersDark ? "dark" : "light";
	}

	// Default to light in non-browser environments
	return "light";
};

export const useThemeStore = create<ThemeState>()(
	persist(
		(set) => ({
			theme: initializeTheme(),
			setTheme: (theme) => set({ theme }),
			toggleTheme: () =>
				set((state) => ({ theme: state.theme === "light" ? "dark" : "light" })),
		}),
		{
			name: "theme-storage",
		},
	),
);
