import { z } from "zod";

export const errorMessageNodeUpdateSchema = z.object({
	id: z.string().uuid(),
	label: z.string().optional(),
	data: z.object({
		message: z.string().trim().min(1, "Veuillez entrer au moins un caractère"),
	}),
});

export type ErrorMessageNodeUpdateDTO = z.infer<
	typeof errorMessageNodeUpdateSchema
>;
