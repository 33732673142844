"use client";

import type { DropzoneProps, FileRejection } from "react-dropzone";
import * as React from "react";
import { cn } from "@/utils";
import { UploadIcon } from "@radix-ui/react-icons";
import { FileIcon } from "lucide-react";
import Dropzone from "react-dropzone";
import { toast } from "sonner";

interface FileSelectProps extends React.HTMLAttributes<HTMLDivElement> {
	accept?: DropzoneProps["accept"];

	maxSize?: DropzoneProps["maxSize"];

	onFileSelect?: (file: File) => void;
}

export function FileSelect({
	accept = { "image/*": [] },
	maxSize = 1024 * 1024 * 2,
	onFileSelect,
	className,
	...dropzoneProps
}: FileSelectProps) {
	const [file, setFile] = React.useState<File | null>(null);

	const onDrop = React.useCallback(
		(acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
			if (acceptedFiles.length > 0) {
				const selectedFile = acceptedFiles[0];
				setFile(selectedFile as File);

				if (onFileSelect) {
					onFileSelect(selectedFile as File);
				}
			}

			if (rejectedFiles.length > 0) {
				rejectedFiles.forEach(({ file }) => {
					toast.error(`File ${file.name} was rejected`);
				});
			}
		},
		[onFileSelect],
	);

	return (
		<div className="relative flex flex-col gap-6 overflow-hidden">
			<Dropzone
				onDrop={onDrop}
				accept={accept}
				maxSize={maxSize}
				maxFiles={1}
				multiple={false}
			>
				{({ getRootProps, getInputProps, isDragActive }) => (
					<div
						{...getRootProps()}
						className={cn(
							"group relative grid h-52 w-full cursor-pointer place-items-center rounded-lg border-2 border-dashed border-muted-foreground/25 px-5 py-2.5 text-center transition hover:bg-muted/25",
							"ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
							isDragActive && "border-muted-foreground/50",
							className,
						)}
						{...dropzoneProps}
					>
						<input {...getInputProps()} />
						{isDragActive ? (
							<div className="flex flex-col items-center justify-center gap-4 sm:px-5">
								<div className="rounded-full border border-dashed p-3">
									<UploadIcon
										className="size-7 text-muted-foreground"
										aria-hidden="true"
									/>
								</div>
								<p className="font-medium text-muted-foreground">
									Drop the file here
								</p>
							</div>
						) : (
							<div className="flex flex-col items-center justify-center gap-4 sm:px-5">
								{file ? (
									<>
										<div className="rounded-full border border-dashed p-3">
											<FileIcon
												className="size-7 text-muted-foreground"
												aria-hidden="true"
											/>
										</div>
										<p className="font-medium text-muted-foreground">
											{file.name}
										</p>
									</>
								) : (
									<>
										<div className="rounded-full border border-dashed p-3">
											<UploadIcon
												className="size-7 text-muted-foreground"
												aria-hidden="true"
											/>
										</div>
										<p className="font-medium text-muted-foreground">
											Drag and drop a file here, or click to select a file
										</p>
										<p className="text-sm text-muted-foreground/70">
											You can upload a file up to {formatBytes(maxSize)}
										</p>
									</>
								)}
							</div>
						)}
					</div>
				)}
			</Dropzone>
		</div>
	);
}

function formatBytes(bytes: number, decimals = 2) {
	if (bytes === 0) return "0 Bytes";
	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}
