import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface SidebarToggleStoreProps {
	isOpen: boolean;
	setIsOpen: () => void;
}

export const useSidebarToggleStore = create(
	persist<SidebarToggleStoreProps>(
		(set, get) => ({
			isOpen: true,
			setIsOpen: () => {
				set({ isOpen: !get().isOpen });
			},
		}),
		{
			name: "sidebarOpen",
			storage: createJSONStorage(() => localStorage),
		},
	),
);
