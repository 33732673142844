import { z } from "zod";

import { EdgeType } from "@repos/rate-resolver-shared";

export const createEdgeSchema = z.object({
	sourceNodeId: z.string().uuid(),
	targetNodeId: z.string().uuid(),
	conditionGroupIndex: z.number().optional(),
	edgeType: z.nativeEnum(EdgeType),
	label: z.string().trim().optional(),
});

export type CreateEdgeDTO = z.infer<typeof createEdgeSchema>;

export const updateEdgeSchema = z.object({
	id: z.string().uuid(),
	label: z.string().trim().min(1, "Veuillez entrer au moins un caractère"),
});

export type UpdateEdgeDTO = z.infer<typeof updateEdgeSchema>;

export const deleteEdgeSchema = z.object({
	id: z.string().uuid(),
});

export type DeleteEdgeDTO = z.infer<typeof deleteEdgeSchema>;

export const getEdgesSchema = z.object({
	pricingEngineId: z.string().uuid(),
});

export type GetEdgesDTO = z.infer<typeof getEdgesSchema>;

export const getEdgeSchema = z.object({
	projectId: z.string().uuid(),
	id: z.string().uuid(),
});

export type GetEdgeDTO = z.infer<typeof getEdgeSchema>;

export const getEdgesByNodeIdSchema = z.object({
	nodeId: z.string().uuid(),
	projectId: z.string().uuid(),
});

export type GetEdgesByNodeIdDTO = z.infer<typeof getEdgesByNodeIdSchema>;
