import { z } from "zod";

import { ConditionType } from "@repos/rate-resolver-shared";

import { conditionOperationSchema } from "../../primitives";

export const conditionsGroupCreateGroupSchema = z.object({
	nodeId: z.string().uuid(),
	operations: z.array(conditionOperationSchema).default([]).optional(),
	conditionType: z
		.nativeEnum(ConditionType)
		.default(ConditionType.AND)
		.optional(),
});

export type ConditionsGroupCreateGroupDTO = z.infer<
	typeof conditionsGroupCreateGroupSchema
>;

export const conditionsGroupUpdateGroupSchema = z.object({
	nodeId: z.string().uuid(),
	projectId: z.string().uuid(),
	conditionGroupIndex: z.number(),
	operations: z.array(conditionOperationSchema).default([]),
	conditionType: z.nativeEnum(ConditionType).default(ConditionType.AND),
});

export type ConditionsGroupUpdateGroupDTO = z.infer<
	typeof conditionsGroupUpdateGroupSchema
>;

export const conditionsGroupDeleteGroupSchema = z.object({
	nodeId: z.string().uuid(),
	projectId: z.string().uuid(),
	conditionIndex: z.number(),
});

export type ConditionsGroupDeleteGroupDTO = z.infer<
	typeof conditionsGroupDeleteGroupSchema
>;
export const conditionsGroupUpdateLabelSchema = z.object({
	nodeId: z.string().uuid(),
	label: z.string().trim().min(1, "Veuillez entrer au moins un caractère"),
});

export type ConditionsGroupUpdateLabel = z.infer<
	typeof conditionsGroupUpdateLabelSchema
>;
