import { CustomConditionsGroupNode } from "@/components/nodes/conditionNode/index";
import { CustomEndNode } from "@/components/nodes/CustomEndNode";
import { CustomErrorMessageNode } from "@/components/nodes/CustomErrorMessageNode";
import { CustomOperationsNode } from "@/components/nodes/CustomOperationNode";
import { CustomOutputNode } from "@/components/nodes/CustomOutputNode";
import { CustomStartNode } from "@/components/nodes/CustomStartNode";

import { NodeType } from "@repos/rate-resolver-shared";

import SmoothStepEdge from "../shared/SmoothStepEdge";
import AnimatedEdge from "../tarification/components/AnimatedEdge";
import { CustomConditionsGroupNodeSummary } from "../tarification/components/summaryNodes/conditionNode/index";

export const nodeTypes = {
	[NodeType.CONDITIONS_GROUP]: CustomConditionsGroupNode,
	[NodeType.OPERATIONS]: CustomOperationsNode,
	[NodeType.ERROR_MESSAGE]: CustomErrorMessageNode,
	[NodeType.OUTPUT]: CustomOutputNode,
	[NodeType.START]: CustomStartNode,
	[NodeType.END]: CustomEndNode,
};

// We just need a custom group conditions node
export const nodeSummaryTypes = {
	[NodeType.CONDITIONS_GROUP]: CustomConditionsGroupNodeSummary,
	[NodeType.OPERATIONS]: CustomOperationsNode,
	[NodeType.ERROR_MESSAGE]: CustomErrorMessageNode,
	[NodeType.OUTPUT]: CustomOutputNode,
	[NodeType.START]: CustomStartNode,
	[NodeType.END]: CustomEndNode,
};

export const basicEdgeTypes = {
	"custom-edge": SmoothStepEdge,
};

export const animatedEdgeTypes = {
	"custom-edge": AnimatedEdge,
};
