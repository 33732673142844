import type { ReactNode } from "react";
import { createContext, useContext, useState } from "react";

import type { NodeType } from "@repos/rate-resolver-shared";

interface DnDContextType {
	nodeType: NodeType | null;
	setNodeType: (type: NodeType | null) => void;
}

const DnDContext = createContext<DnDContextType | undefined>(undefined);

export const useSidebarDnD = () => {
	const context = useContext(DnDContext);
	if (!context) {
		throw new Error("useSidebarDnD must be used within a SidebarDnDProvider");
	}
	return context;
};

interface SidebarDnDProviderProps {
	children: ReactNode;
}

export const SidebarDnDProvider = ({ children }: SidebarDnDProviderProps) => {
	const [nodeType, setNodeType] = useState<NodeType | null>(null);

	return (
		<DnDContext.Provider value={{ nodeType, setNodeType }}>
			{children}
		</DnDContext.Provider>
	);
};
