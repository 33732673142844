import { SummaryTable } from "@/components/tarification/components/SummaryTable";
import { Label } from "@/components/ui/label";

import type { ApiOutputs } from "@repos/rate-resolver-api";

interface NodeSummaryCardProps {
	nodeSummary: ApiOutputs["pricingEngine"]["testRate"]["summary"]["nodeSummaries"][0];
	index: number;
}
export const NodeSummaryCard = ({
	nodeSummary,
	index,
}: NodeSummaryCardProps) => {
	return (
		<div className="my-3 flex flex-col justify-center rounded-md  border border-b-2 border-blue-500 p-4">
			<Label className="text-lg font-bold text-black dark:text-white">
				{" "}
				Etape {index} : {nodeSummary.label}{" "}
			</Label>
			<SummaryTable nodeSummary={nodeSummary} />
		</div>
	);
};
