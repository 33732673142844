import type { FormattedNode } from "@/types";
import type { EdgeProps } from "@xyflow/react";
import { useEffect, useRef, useState } from "react";
import { useAppStore } from "@/stores";
import usePricingFlowStore from "@/stores/flow/useFlowStore";
import { trpc } from "@/utils";
import { formatNode } from "@/utils/formatNode";
import { BaseEdge, getSmoothStepPath } from "@xyflow/react";

import { NodeType } from "@repos/rate-resolver-shared";

const AnimatedEdge = ({
	id,
	sourceX,
	sourceY,
	targetX,
	targetY,
	sourcePosition,
	targetPosition,
	style = {},
	markerEnd,
}: EdgeProps) => {
	const { selectedProject, tarificationSummary } = useAppStore((state) => ({
		selectedProject: state.selectedProject,
		tarificationSummary: state.tarificationSummary,
	}));

	const { selectedPricingEngine } = usePricingFlowStore((state) => ({
		selectedPricingEngine: state.selectedPricingEngine,
	}));

	const initialLayoutApplied = useRef(false);
	const [localNodes, setLocalNodes] = useState<FormattedNode[]>([]);

	const { data: nodes, isLoading } = trpc.nodes.getNodes.useQuery(
		{ pricingEngineId: selectedPricingEngine?.id ?? "" },
		{
			enabled: !!selectedProject,
			select: (nodes) => nodes.map((node) => formatNode(node)),
		},
	);

	useEffect(() => {
		if (!isLoading && nodes && !initialLayoutApplied.current) {
			setLocalNodes(nodes);
			initialLayoutApplied.current = true;
		}
	}, [nodes, isLoading, localNodes]);

	const [edgePath] = getSmoothStepPath({
		sourceX,
		sourceY,
		sourcePosition,
		targetX,
		targetY,
		targetPosition,
	});

	let strokeColor = "#b1b1b7";
	let strokeWidth = 1;
	let animated = false;

	const hasEndNode = tarificationSummary?.summary.visitedEdges.some((edge) => {
		const targetNode = localNodes.find((node) => node.id === edge.sourceNodeId);
		return targetNode?.type === NodeType.END;
	});

	if (hasEndNode) {
		strokeColor = "#00ff00";
		strokeWidth = 3;
		animated = true;
	} else {
		strokeColor = "#ff0000";
		strokeWidth = 3;
		animated = true;
	}

	return (
		<>
			<BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
			<path
				id={id}
				style={{
					...style,
					stroke: strokeColor,
					strokeWidth: strokeWidth,
					strokeDasharray: animated ? "5,5" : "none",
					animation: animated ? "dash 1s linear infinite" : "none",
				}}
				className="react-flow__edge-path"
				d={edgePath}
				markerEnd={markerEnd}
			/>
			<style>
				{`
          @keyframes dash {
            to {
              stroke-dashoffset: -10;
            }
          }
        `}
			</style>
		</>
	);
};

export default AnimatedEdge;
