import { useState } from "react";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import useBoolean from "@/hooks/useBoolean";
import usePricingFlowStore from "@/stores/flow/useFlowStore";
import { trpc } from "@/utils";
import { CheckCircledIcon, CrossCircledIcon } from "@radix-ui/react-icons";
import { format } from "date-fns";
import { Loader2, Trash } from "lucide-react";
import { toast } from "sonner";
import { useShallow } from "zustand/react/shallow";

import { LoadingButton } from "../loading-button";
import { Badge } from "../ui/badge";
import { Button } from "../ui/button";
import { Input } from "../ui/input";

function SnapshotSection() {
	const showLabelInput = useBoolean(false);
	const [snapshotLabel, setSnapShotLabel] = useState<string | undefined>(
		undefined,
	);
	const {
		pricingEnginesList,
		selectedPricingEngine,
		setSelectedPricingEngine,
		addPricingEngineToList,
		onDeletePriceEngine,
	} = usePricingFlowStore(
		useShallow((state) => ({
			pricingEnginesList: state.pricingEnginesList,
			selectedPricingEngine: state.selectedPricingEngine,
			setSelectedPricingEngine: state.setSelectedPricingEngine,
			addPricingEngineToList: state.addPricingEngineToList,
			onDeletePriceEngine: state.onDeletePriceEngine,
		})),
	);

	const snapshortMutator =
		trpc.pricingEngine.createPricingEngineSnapshot.useMutation({
			onSuccess: () => {
				toast.success("Instantané créé avec succès");
				showLabelInput.setFalse();
			},
			onError: () => {
				toast.error("Erreur lors de la création de l'instantané");
			},
		});

	const handleCreateSnapshot = async () => {
		if (selectedPricingEngine && selectedPricingEngine.id) {
			const newEngine = await snapshortMutator.mutateAsync({
				pricingEngineId: selectedPricingEngine.id,
				name: (snapshotLabel ?? "").length > 0 ? snapshotLabel : undefined,
			});
			addPricingEngineToList({
				id: newEngine.id,
				name: snapshotLabel,
				isEnabled: newEngine.isEnabled,
				updatedAt: newEngine.updatedAt,
			});
			setSelectedPricingEngine(newEngine);
		}
	};
	//DELETE PRICE ENGINE
	const snapShotDeleteMutator =
		trpc.pricingEngine.deletePriceEngineSnapShot.useMutation({
			onSuccess: () => {
				toast.success("Suppression avec succès");
			},
			onError: () => {
				toast.error("Erreur lors de la suppression de la version.");
			},
		});

	const handleDeleteSnapShot = async (id) => {
		if (selectedPricingEngine?.id === id) {
			toast.error("Impossible de supprimer une verision actif");
		} else {
			try {
				await snapShotDeleteMutator.mutateAsync({
					pricingEngineId: id,
				});
				onDeletePriceEngine(id);
			} catch (error) {
				console.log(error);
			}
		}
	};
	return (
		<section className="flow-actions flex flex-col justify-center gap-3">
			<Select
				onValueChange={(value: string) => {
					const engine = pricingEnginesList.find(
						(engine) => engine.id === value,
					);
					if (engine) {
						setSelectedPricingEngine(engine);
					}
				}}
				value={selectedPricingEngine?.id ?? undefined}
			>
				<SelectTrigger className="w-full">
					<SelectValue placeholder="Selectionner Version" />
				</SelectTrigger>
				<SelectContent>
					<SelectGroup>
						<SelectLabel>Selectionner Version</SelectLabel>

						{pricingEnginesList.map(({ id, name, isEnabled, updatedAt }) => (
							<div className="flex flex-row" key={id}>
								<SelectItem value={id}>
									<span className="mr-1">
										{name ? name : format(updatedAt, "dd/MM/yyyy à HH:mm")}
									</span>
									{isEnabled && <Badge>Actif</Badge>}
								</SelectItem>
								<Button
									variant={null}
									className="bg-inherit"
									onClick={() => handleDeleteSnapShot(id)}
								>
									<Trash className="hover:text-red-600"></Trash>
								</Button>
							</div>
						))}
					</SelectGroup>
				</SelectContent>
			</Select>
			{!showLabelInput.value ? (
				<LoadingButton
					className="w-full"
					isLoading={snapshortMutator.isLoading}
					onClick={showLabelInput.setTrue}
				>
					Enregistrer une version
				</LoadingButton>
			) : (
				<div className="flex w-full flex-row items-center">
					<div className="relative w-full">
						<Input
							type="text"
							placeholder="Label de version"
							className="w-full pl-4 pr-20" // Add padding-right to avoid overlapping with icons
							value={snapshotLabel}
							onChange={(e) => setSnapShotLabel(e.target.value)}
						/>
						{/* Icons on the right side */}
						<div className="absolute right-2 top-1/2 flex -translate-y-1/2 transform space-x-2">
							{snapshortMutator.isLoading ? (
								<Loader2 className="size-5  animate-spin cursor-pointer" />
							) : (
								<CheckCircledIcon
									className="size-5 cursor-pointer text-gray-400 hover:text-green-500"
									onClick={handleCreateSnapshot}
								/>
							)}

							<CrossCircledIcon
								className="size-5 cursor-pointer text-gray-400 hover:text-red-500"
								onClick={showLabelInput.setFalse}
							/>
						</div>
					</div>
				</div>
			)}
		</section>
	);
}

export default SnapshotSection;
