import type { DataTableAccessoryProps } from "@/components/dataTable/variables";
import { useState } from "react";
import { AddVariable } from "@/components/AddVariable";
import { DataTable } from "@/components/dataTable/data-table";
import {
	DataTableAccessory,
	getColumns,
} from "@/components/dataTable/variables";
import { useDebounce } from "@/hooks/useDebounce";
import { useAppStore } from "@/stores/useAppStore";
import { trpc } from "@/utils";
import { PlusCircle } from "lucide-react";

export const Variables = () => {
	const selectedProject = useAppStore((state) => state.selectedProject);
	const [search, setSearch] = useState<string>("");
	const debouncedSearch = useDebounce(search, 300);

	const utils = trpc.useUtils();
	const { data: variables } = trpc.variables.getVariables.useQuery({
		projectId: selectedProject?.id ?? "",
		search: debouncedSearch,
		excludeProperties: true,
		excludeVariables: false,
	});

	const headers: { [key: string]: string } = {
		label: "Label",
		category: "Categorie",
		defaultValue: "Valeur Par défaut",
		type: "Type",
	};

	const accessoryDataTableProps = {
		searchValue: search,
		onChangeSearchValue: setSearch,
		buttonText: "Ajouter une variable",
		ActionComponent: () => (
			<AddVariable
				onVariableCreated={() => {
					utils.variables.invalidate();
				}}
			/>
		),
		buttonIcon: <PlusCircle className="mr-2 h-4 w-4" />,
	} satisfies DataTableAccessoryProps;

	return (
		<div className="overflow-x-auto">
			<DataTable
				columns={getColumns({
					headers,
					refetchVariables: () => utils.variables.invalidate(),
				})}
				data={variables ?? []}
				DataTableAccessoryComponent={() => (
					<DataTableAccessory {...accessoryDataTableProps} />
				)}
			/>
		</div>
	);
};
