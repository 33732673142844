import { Button } from "@/components/ui/button";
import { AlertCircle, Home } from "lucide-react";

export const ErrorPage = () => {
	return (
		<div className="flex min-h-screen flex-col items-center justify-center bg-background text-foreground">
			<div className="max-w-md text-center">
				<AlertCircle className="mx-auto h-16 w-16 text-destructive" />
				<h1 className="mt-6 text-3xl font-bold">Oops! Something went wrong</h1>
				<p className="mt-4 text-muted-foreground">
					We apologize for the inconvenience. Our team has been notified and is
					working on fixing the issue.
				</p>
				<div className="mt-8 flex justify-center">
					<Button asChild>
						<a href="/">
							<Home className="mr-2 h-4 w-4" />
							Return to Home
						</a>
					</Button>
				</div>
			</div>
		</div>
	);
};
