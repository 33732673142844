import type { Connection } from "@xyflow/react";
import { useAppStore } from "@/stores/useAppStore";
import { trpc } from "@/utils";
import {
	Background,
	BackgroundVariant,
	Controls,
	Panel,
	ReactFlow,
	useNodesInitialized,
	useReactFlow,
} from "@xyflow/react";
import { Loader2, Menu, Network, ShieldCheckIcon } from "lucide-react";

import {
	EdgeType,
	getGroupIndexFromTargetHandle,
	isConditionTrueTargetHandle,
	NodeType,
} from "@repos/rate-resolver-shared";

import "@xyflow/react/dist/style.css";

import { useCallback, useEffect, useState } from "react";
import { FlowSideBar } from "@/components/FlowSideBar";
import { useSidebarDnD } from "@/components/FlowSideBar/context/SidebarDnDContext";
import { LoadingButton } from "@/components/loading-button";
import { CustomConditionsGroupNode } from "@/components/nodes/conditionNode/ConditionsGroupNode";
import { CustomEndNode } from "@/components/nodes/CustomEndNode";
import { CustomErrorMessageNode } from "@/components/nodes/CustomErrorMessageNode";
import { CustomOperationsNode } from "@/components/nodes/CustomOperationNode";
import { CustomOutputNode } from "@/components/nodes/CustomOutputNode";
import { CustomStartNode } from "@/components/nodes/CustomStartNode";
import SmoothStepEdge from "@/components/shared/SmoothStepEdge";
import { Button } from "@/components/ui/button";
import useBoolean from "@/hooks/useBoolean";
import { useLayoutElements } from "@/hooks/useLayoutElements";
import usePricingFlowStore from "@/stores/flow/useFlowStore";
import { useThemeStore } from "@/stores/useThemeStore";
import { formatEdge } from "@/utils/formatEdge";
import { formatNode } from "@/utils/formatNode";
import { toast } from "sonner";
import { useShallow } from "zustand/react/shallow";

import type { CreateEdgeDTO } from "@repos/rate-resolver-dtos";

import "./flow.css";

const nodeTypes = {
	[NodeType.CONDITIONS_GROUP]: CustomConditionsGroupNode,
	[NodeType.OPERATIONS]: CustomOperationsNode,
	[NodeType.ERROR_MESSAGE]: CustomErrorMessageNode,
	[NodeType.OUTPUT]: CustomOutputNode,
	[NodeType.START]: CustomStartNode,
	[NodeType.END]: CustomEndNode,
};

const edgeTypes = {
	"custom-edge": SmoothStepEdge,
};

export const StepsV2 = () => {
	const sideBarToggle = useBoolean(true);
	const {
		loading,
		edges,
		nodes,
		setNodes,
		addEdge,
		onEdgesChange,
		onNodesChange,
		selectedPricingEngine,
		onEngineUpdate,
	} = usePricingFlowStore(
		useShallow(
			({
				loading,
				edges,
				nodes,
				setNodes,
				// onConnect,
				addEdge,
				onEdgesChange,
				onNodesChange,
				selectedPricingEngine,
				onEngineUpdate,
			}) => ({
				loading,
				edges,
				nodes,
				setNodes,
				// onConnect,
				addEdge,
				onEdgesChange,
				onNodesChange,
				selectedPricingEngine,
				onEngineUpdate,
			}),
		),
	);

	const nodesInitialized = useNodesInitialized();
	const [didInit, setDidInit] = useState(false);
	const [didLayout, setDidLayout] = useState(false);

	const { selectedProject, setVariables } = useAppStore((state) => ({
		selectedProject: state.selectedProject,
		setVariables: state.setSelectedVariables,
	}));

	const { theme } = useThemeStore();
	const { nodeType } = useSidebarDnD();
	const { screenToFlowPosition } = useReactFlow();
	const { layoutElements } = useLayoutElements();

	const { data: variables, isFetched: isVariablesFetched } =
		trpc.variables.getVariables.useQuery({
			projectId: selectedProject?.id || "",
			excludeProperties: false,
			excludeVariables: false,
		});

	const { mutateAsync: createEmptyNode } =
		trpc.nodes.createEmptyNode.useMutation({
			onError: (error) => {
				toast.error(error.message);
			},
		});
	const { mutateAsync: createEdge } = trpc.edges.createEdge.useMutation({
		onError: (error) => {
			toast.error(error.message);
		},
	});
	const enabledEngine = trpc.pricingEngine.enablePricingEngine.useMutation({
		onSuccess: () => {
			selectedPricingEngine &&
				onEngineUpdate({
					...selectedPricingEngine,
					isEnabled: true,
				});

			toast.success("Tarificateur activé avec succès");
		},
	});

	const onDragOver = useCallback((event: React.DragEvent) => {
		event.preventDefault();
		event.dataTransfer.dropEffect = "move";
	}, []);

	const onDrop = useCallback(
		async (event: React.DragEvent) => {
			event.preventDefault();

			if (!nodeType || !selectedProject?.id) return;

			const position = screenToFlowPosition({
				x: event.clientX,
				y: event.clientY,
			});
			if (selectedPricingEngine) {
				const node = await createEmptyNode({
					pricingEngineId: selectedPricingEngine.id,
					type: nodeType,
					label: "",
				});

				const newNode = formatNode(node, position);
				setNodes(nodes.concat(newNode));
			}
		},
		[
			nodeType,
			selectedProject?.id,
			screenToFlowPosition,
			selectedPricingEngine,
			createEmptyNode,
			setNodes,
			nodes,
		],
	);

	const onConnect = async ({ source, target, targetHandle }: Connection) => {
		if (!source || !target) return;

		const sourceNode = nodes.find((node) => node.id === source);
		const targetNode = nodes.find((node) => node.id === target);

		const connectionData: Partial<CreateEdgeDTO> = {
			sourceNodeId: source,
			targetNodeId: target,
			edgeType: EdgeType.DEFAULT,
		};

		if (
			sourceNode?.type === NodeType.END &&
			targetNode?.type === NodeType.START
		) {
			toast.warning("Cannot connect start to end Directly");
			return;
		}

		// if the target node is a condition group and we have a condition group index
		if (
			targetNode?.type === NodeType.CONDITIONS_GROUP &&
			targetHandle &&
			isConditionTrueTargetHandle(targetHandle)
		) {
			connectionData.conditionGroupIndex =
				getGroupIndexFromTargetHandle(targetHandle);
			connectionData.edgeType = EdgeType.CONDITION_TRUE;
		}

		try {
			const edge = await createEdge(connectionData as CreateEdgeDTO);

			addEdge(formatEdge(edge));
		} catch (error) {
			console.error("Failed to create edge:", error);
		}
	};

	const handleEnabledPricingEngine = async () => {
		if (!selectedPricingEngine || selectedPricingEngine.isEnabled) return;
		await enabledEngine.mutateAsync({
			pricingEngineId: selectedPricingEngine.id,
		});
	};

	useEffect(() => {
		if (variables && isVariablesFetched) {
			setVariables(variables);
		}
	}, [variables, isVariablesFetched, setVariables]);

	useEffect(() => {
		if (!didInit && nodesInitialized) {
			layoutElements({
				nodes,
				edges,
			})
				.then((nodes) => {
					if (nodes) {
						setDidInit(true);
						setNodes(nodes);
					}
				})
				.catch((error) => {
					console.error("Layout failed:", error);
				});
		}
	}, [nodesInitialized, didInit, nodes, edges]);

	useEffect(() => {
		if (didInit && !didLayout) {
			console.log(2, { didInit, didLayout });

			layoutElements({
				nodes,
				edges,
			})
				.then((nodes) => {
					if (nodes) {
						setDidLayout(true);
						setNodes(nodes);
					}
				})
				.catch((error) => {
					console.error("Layout failed:", error);
				});
		}
	}, [didInit]);

	// This should never happen as StepFlow already checks it.
	if (!selectedProject) {
		return <div className="text-sky-500">No project selected</div>;
	}

	if (loading) {
		return (
			<div className="flex h-[85vh] w-full items-center justify-center">
				<Loader2 className="size-4 animate-spin" />
			</div>
		);
	}

	return (
		<div className="h-[85vh] w-full">
			<ReactFlow
				fitView
				colorMode={theme}
				nodes={nodes}
				edges={edges}
				nodeTypes={nodeTypes}
				edgeTypes={edgeTypes}
				onNodesChange={onNodesChange}
				onEdgesChange={onEdgesChange}
				onConnect={onConnect}
				onDrop={onDrop}
				onDragOver={onDragOver}
				fitViewOptions={{ padding: 0.2, duration: 200, nodes: nodes }}
			>
				<Panel position="top-left">
					<Button onClick={() => sideBarToggle.toggle()} size="icon">
						<Menu />
					</Button>
				</Panel>
				<Controls position="bottom-right" />
				<Background variant={BackgroundVariant.Dots} />
				{sideBarToggle.value && <FlowSideBar />}
				<Panel position="top-right" className="flex gap-2">
					<LoadingButton
						isLoading={enabledEngine.isLoading}
						className={
							selectedPricingEngine?.isEnabled
								? "gap-2 bg-green-600"
								: "gap-2 bg-gray-500"
						}
						onClick={handleEnabledPricingEngine}
					>
						{selectedPricingEngine?.isEnabled ? (
							<>
								{selectedPricingEngine.name} actif <ShieldCheckIcon />
							</>
						) : (
							<>Activer {selectedPricingEngine?.name}</>
						)}
					</LoadingButton>
					<Button
						onClick={() => {
							layoutElements({
								nodes: nodes,
								edges: edges,
							})
								.then((nodes) => {
									if (nodes) {
										setNodes(nodes);
									}
								})
								.catch((error) => {
									console.error("Layout failed:", error);
								});
						}}
						size="icon"
					>
						<Network />
					</Button>
				</Panel>
			</ReactFlow>
		</div>
	);
};
