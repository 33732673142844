import { trpc } from "@/utils/trpc";
import { toast } from "sonner";

import type { DupolicateProjectInputDTO } from "@repos/rate-resolver-dtos";

export const useProjectActions = () => {
	const utils = trpc.useUtils();
	const duplicateProjectMutation = trpc.projects.duplicateProject.useMutation({
		onSuccess: () => {
			toast.success("Projet dupliqué avec succès !", {
				action: {
					label: "X",
					onClick: () => toast.dismiss(),
				},
			});
			utils.projects.getProjects.invalidate();
		},
		onError: (error) => {
			toast.error(
				`${error.message || "Échec lors de la duplication du projet:"}`,
				{
					action: {
						label: "X",
						onClick: () => toast.dismiss(),
					},
				},
			);
		},
	});

	const handleDuplicate = async ({ projectId }: DupolicateProjectInputDTO) => {
		try {
			duplicateProjectMutation.mutate({ projectId });
		} catch (error) {
			console.error("Failed to duplicate project:", error);
		}
	};
	return {
		handleDuplicate,
	};
};
