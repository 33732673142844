import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

interface NotFoundProps {
	message?: string;
	backLink?: string;
}
export const NotFound = ({
	message = "On n'a pas pu trouver cette page",
	backLink = "/",
}: NotFoundProps) => {
	return (
		<div className="flex min-h-[80vh] flex-col items-center justify-center bg-background p-4">
			<div className="text-center">
				<svg
					className="mx-auto h-24 w-24"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
					aria-hidden="true"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={2}
						d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
					/>
				</svg>
				<h1 className="mt-4 text-3xl font-bold tracking-tight sm:text-5xl">
					404
				</h1>
				<p className="mt-6 text-base leading-7 ">{message}</p>
				<div className="mt-10 flex items-center justify-center gap-x-6">
					<Link to={backLink}>
						<Button variant="secondary">Retourner à la page variables</Button>
					</Link>
				</div>
			</div>
		</div>
	);
};
