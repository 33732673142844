import { Toaster } from "@/components/ui/sonner";
import { AppProvider } from "@/providers/AppProvider";
import { AppRoutes } from "@/routes";

import "./index.css";

function App() {
	return (
		<AppProvider>
			<AppRoutes />
			<Toaster />
		</AppProvider>
	);
}

export default App;
