import type { Node, NodeProps } from "@xyflow/react";
import { AddGroupCard } from "@/components/nodes/conditionNode/conditionGroup/AddGroupCard";
import { ConditionGroup } from "@/components/nodes/conditionNode/conditionGroup/ConditionGroup";
import { useNodeActions } from "@/hooks/useNodeActions";
import usePricingFlowStore from "@/stores/flow/useFlowStore";
import { useAppStore } from "@/stores/useAppStore";
import { cn } from "@/utils";
import { formatNode } from "@/utils/formatNode";
import { trpc } from "@/utils/trpc";
import { Position, useReactFlow } from "@xyflow/react";
import { toast } from "sonner";
import { useShallow } from "zustand/react/shallow";

import type { ConditionsGroupNode } from "@repos/rate-resolver-dtos";
import type { ConditionType } from "@repos/rate-resolver-shared";
import { NodeType, TargetHandle } from "@repos/rate-resolver-shared";

import { CustomHandle } from "../../shared/CustomHandle";
import { LabelEditor } from "../../shared/LabelEditor";
import { NodeToolbarComponent } from "../../shared/NodeToolbarComponent";
import { Card, CardContent, CardHeader } from "../../ui/card";

export type CustomConditionNode = Node<
	ConditionsGroupNode["data"] & {
		label: string;
		id: string;
		conditionType: ConditionType;
	},
	NodeType.CONDITIONS_GROUP
>;

export const CustomConditionsGroupNode = ({
	data,
	isConnectable,
	selected,
}: NodeProps<CustomConditionNode>) => {
	const { selectedProject } = useAppStore((state) => ({
		selectedProject: state.selectedProject,
	}));
	const { onUpdateNode, onNodesDelete, onDuplicateNode } = usePricingFlowStore(
		useShallow(({ onUpdateNode, onNodesDelete, onDuplicateNode }) => ({
			onUpdateNode,
			onNodesDelete,
			onDuplicateNode,
		})),
	);

	const updateLabelMutation = trpc.nodes.updateConditionGroupLabel.useMutation({
		onSuccess: (data) => {
			onUpdateNode(formatNode(data));
			toast.success("Conditions mise à jour avec succès!", {
				action: {
					label: "X",
					onClick: () => toast.dismiss(),
				},
			});
		},
		onError: (error) => {
			toast.error(error.message, {
				action: {
					label: "X",
					onClick: () => toast.dismiss(),
				},
			});
		},
	});

	const deleteNodeMutation = trpc.nodes.deleteNode.useMutation({
		onSuccess: () => {
			onNodesDelete(data.id);
		},
		onError: (error) => {
			toast.error(`${error.message || "Failed to delete node"}`, {
				action: {
					label: "X",
					onClick: () => toast.dismiss(),
				},
			});
		},
	});

	const handleDelete = async () => {
		try {
			await deleteNodeMutation.mutateAsync({ id: data.id });
		} catch (error) {
			console.error("Error deleting node:", error);
		}
	};

	const reactFlowInstance = useReactFlow();

	//Node duplicate mutation
	const { handleDuplicate } = useNodeActions({
		onDuplicateSuccess: (newNode) => {
			onDuplicateNode(formatNode(newNode)); // Update local state or store
		},
		reactFlowInstance,
	});

	return (
		<>
			<CustomHandle
				type="source"
				position={Position.Top}
				id="source"
				isConnectable={isConnectable}
				className="z-10 bg-black"
			/>
			<Card
				className={cn(
					"relative h-full w-full max-w-[380px] border-2 border-black",
					selected ? "ring-2 ring-blue-500" : "",
				)}
			>
				<CardHeader className="pb-6">
					<LabelEditor
						initialLabel={data.label}
						onSubmit={async (newLabel) => {
							updateLabelMutation.mutate({ label: newLabel, nodeId: data.id });
						}}
						disabled={deleteNodeMutation.isLoading}
						nodeType={NodeType.CONDITIONS_GROUP}
					/>
				</CardHeader>
				<CardContent className="flex flex-col gap-2">
					{data.conditionGroups.map((group, index) => (
						<ConditionGroup
							key={`condition-group-${index}`}
							groupIndex={index}
							data={group}
							nodeId={data.id}
							projectId={selectedProject?.id ?? ""}
						/>
					))}
					<AddGroupCard />
				</CardContent>
			</Card>

			<CustomHandle
				type="target"
				position={Position.Bottom}
				id={TargetHandle.TARGET}
				isConnectable={isConnectable}
				className="bg-black"
			/>

			<NodeToolbarComponent
				isVisible={selected ?? false}
				onDelete={handleDelete}
				onDuplicate={() => handleDuplicate(data.id)}
				disableEdit
				isLoading={deleteNodeMutation.isLoading}
			/>
		</>
	);
};
