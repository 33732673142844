import { useState } from "react";
import { PropertyUpsertDialog } from "@/components/properties/PropertyUpsertDialog";
import { Dialog, DialogTrigger } from "@/components/ui/dialog";
import { trpc } from "@/utils";
import { PlusCircle } from "lucide-react";

import { Button } from "../../ui/button";
import { Input } from "../../ui/input";

export interface DataTableAccessoryProps {
	searchValue: string;
	onChangeSearchValue: (value: string) => void;
	variableId: string;
}

export function DataTableAccessory({
	variableId,
	onChangeSearchValue,
	searchValue,
}: DataTableAccessoryProps) {
	const [open, setOpen] = useState(false);
	const trpcUtils = trpc.useUtils();

	return (
		<div className="flex items-center justify-between py-4">
			<Input
				placeholder="Rechercher propriété..."
				value={searchValue}
				onChange={(e) => onChangeSearchValue(e.target.value)}
				className="ml-2 max-w-sm"
				autoFocus
			/>
			<div>
				<Dialog open={open} onOpenChange={setOpen}>
					<DialogTrigger asChild>
						<Button className="mr-2" type="button">
							<PlusCircle className="mr-2 h-4 w-4" />
							Créer une propriété
						</Button>
					</DialogTrigger>
					<PropertyUpsertDialog
						isEditMode={false}
						variableId={variableId}
						onClose={() => setOpen(false)}
						onPropertySaved={() => trpcUtils.properties.invalidate()}
					/>
				</Dialog>
			</div>
		</div>
	);
}
