import { useCallback, useEffect, useRef, useState } from "react";
import { GroupSummary } from "@/components/nodes/conditionNode/conditionGroup/GroupSummary";
import { ConditionBuilderV2 } from "@/components/nodes/operationBuilder/ConditionBuilder";
import { CopyPasteActions } from "@/components/shared/CopyPasteActions";
import { ToolbarButtons } from "@/components/shared/ToolbarButtons";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import { Sortable } from "@/components/ui/sortable";
import { useOnClickOutside } from "@/hooks/useClickOutside";
import { useCopyPasteActions } from "@/hooks/useCopyPaste";
import { getDefaultConditionOperation } from "@/lib";
import usePricingFlowStore from "@/stores/flow/useFlowStore";
import { cn, trpc } from "@/utils";
import { formatNode } from "@/utils/formatNode";
import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2Icon, Plus } from "lucide-react";
import {
	FormProvider,
	useFieldArray,
	useForm,
	useWatch,
} from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";
import { useShallow } from "zustand/react/shallow";

import type {
	ConditionsGroupUpdateGroupDTO,
	GroupData,
} from "@repos/rate-resolver-dtos";
import {
	conditionOperationSchema,
	conditionsGroupUpdateGroupSchema,
} from "@repos/rate-resolver-dtos";

type ConditionGroupFormProps = {
	data: GroupData;
	groupIndex: number;
	nodeId: string;
	projectId: string;
};
export const ConditionGroup = ({
	data,
	nodeId,
	projectId,
	groupIndex,
}: ConditionGroupFormProps) => {
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const { onUpdateNode } = usePricingFlowStore(
		useShallow(({ onUpdateNode }) => ({
			onUpdateNode,
		})),
	);

	const methods = useForm<ConditionsGroupUpdateGroupDTO>({
		resolver: zodResolver(conditionsGroupUpdateGroupSchema),
		defaultValues: {
			nodeId: nodeId,
			projectId: projectId,
			conditionGroupIndex: groupIndex,
			operations:
				data.operations.length > 0
					? data.operations
					: [getDefaultConditionOperation()],
			conditionType: data.conditionType,
		},
	});

	const {
		fields: operations,
		append,
		remove,
		replace,
		move,
	} = useFieldArray({
		control: methods.control,
		name: "operations",
	});

	const [isValid, setIsValid] = useState(false);
	const [isSelected, setIsSelected] = useState(false);
	const ref = useRef<HTMLDivElement>(null);

	useOnClickOutside(ref, () => {
		setIsSelected(false);
	});

	const conditionsWatch = useWatch({
		control: methods.control,
		name: "operations",
		defaultValue: [],
	});

	useEffect(() => {
		try {
			const validationResult = z
				.array(conditionOperationSchema)
				.safeParse(conditionsWatch);
			setIsValid(validationResult.success);
		} catch (error) {
			setIsValid(false);
			console.log("conditions validation error:", error);
		}
	}, [conditionsWatch]);

	const {
		handleCopy: handleCopyConditions,
		handleReplace: handleReplaceConditions,
		handleAppend: handleAppendConditions,
		copiedItems: copiedConditions,
	} = useCopyPasteActions({
		items: conditionsWatch,
		itemType: "conditions",
		appendAction: append,
		replaceAction: (items) => replace(items),
	});

	const updateMutation = trpc.nodes.updateConditionGroup.useMutation({
		onSuccess: (_data) => {
			onUpdateNode(formatNode(_data));
			setIsDialogOpen(false);
		},
		onError: (error) => {
			toast.error(`${error.message || "Failed to update"}`, {
				action: {
					label: "X",
					onClick: () => toast.dismiss(),
				},
			});
		},
	});

	const deleteMutation = trpc.nodes.deleteConditionGroup.useMutation({
		onSuccess: (_data) => {
			onUpdateNode(formatNode(_data));
			setIsDialogOpen(false);
		},
		onError: (error) => {
			toast.error(`${error.message || "Failed to delete"}`, {
				action: {
					label: "X",
					onClick: () => toast.dismiss(),
				},
			});
		},
	});

	const shouldShowOperator = useCallback(
		(index: number) => {
			return index !== operations.length - 1;
		},
		[operations],
	);

	const handleSubmit = async (formData: ConditionsGroupUpdateGroupDTO) => {
		try {
			if (updateMutation.isLoading || deleteMutation.isLoading) return;
			await updateMutation.mutateAsync({
				...formData,
			});
		} catch (error) {
			console.error("Failed to update node:", error);
		}
	};
	const duplicateMutation = trpc.nodes.createConditionGroup.useMutation({
		onSuccess: (_data) => {
			onUpdateNode(formatNode(_data));
			setIsDialogOpen(false);
		},
		onError: (error) => {
			toast.error(`${error.message || "Failed to duplicate"}`, {
				action: {
					label: "X",
					onClick: () => toast.dismiss(),
				},
			});
		},
	});
	const handleDuplication = async () => {
		if (!nodeId) {
			toast.error("Element introuvable. veuillez rafraîchir la page svp.");
			return;
		}
		try {
			duplicateMutation.mutateAsync({
				nodeId,
				operations: data.operations,
				conditionType: data.conditionType,
			});
		} catch (error) {
			console.error("Échec lors de la  duplication du groupe:", error);
			toast.error("Échec lors de la duplication du groupe:");
		}
	};

	return (
		<Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
			<Card
				ref={ref}
				className={cn(
					"relative h-full w-full max-w-[380px] border-2 border-black",
					isSelected && "ring-2 ring-teal-500",
				)}
				onClick={() => {
					setIsSelected(true);
				}}
			>
				{isSelected && (
					<div className="absolute right-0 top-[-50px] flex items-center overflow-hidden rounded-sm border-[0.05rem] border-gray-500 bg-white dark:bg-card">
						<ToolbarButtons
							onDelete={() => {
								deleteMutation.mutateAsync({
									nodeId: nodeId,
									projectId: projectId,
									conditionIndex: groupIndex,
								});
							}}
							onDuplicate={handleDuplication}
							isLoading={deleteMutation.isLoading}
							onEdit={() => setIsDialogOpen(true)}
						/>
					</div>
				)}
				<CardContent>
					<GroupSummary
						conditionSummaries={data.conditionSummaries}
						conditionType={data.conditionType}
						groupIndex={groupIndex}
					/>

					<DialogContent className="w-full max-w-5xl">
						<FormProvider {...methods}>
							<form
								onSubmit={methods.handleSubmit(handleSubmit, (err) =>
									console.log("Form error:", err),
								)}
							>
								<DialogHeader className="mb-4">
									<DialogTitle>
										{data.operations.length
											? "Modifier Conditions"
											: "Ajouter Conditions"}
									</DialogTitle>
								</DialogHeader>
								<div className="mb-4 h-[60vh] max-h-[60vh] overflow-y-auto overflow-x-hidden rounded-xl border bg-card p-4 shadow">
									<div className="flex justify-end">
										<CopyPasteActions
											isValid={isValid}
											hasFields={operations.length > 0}
											copiedItems={
												Array.isArray(copiedConditions) &&
												copiedConditions.length > 0
											}
											onCopy={handleCopyConditions}
											onReplace={handleReplaceConditions}
											onAppend={handleAppendConditions}
										/>
									</div>
									<Sortable
										orientation="mixed"
										value={operations}
										onMove={({ activeIndex, overIndex }) =>
											move(activeIndex, overIndex)
										}
									>
										{operations.map((operation, index) => (
											<ConditionBuilderV2
												key={operation.id}
												operation={operation}
												operationIndex={index}
												removeOperation={remove}
												basePath={"operations"}
												shouldShowOperator={shouldShowOperator(index)}
											/>
										))}
									</Sortable>
									<div className="mt-4 flex justify-end">
										<Button
											variant="outline"
											size="sm"
											onClick={() => append(getDefaultConditionOperation())}
										>
											<Plus className="mr-2 h-4 w-4" />
											Ajouter une condition
										</Button>
									</div>
								</div>
								<div className="mt-4 flex justify-end">
									<Button
										type="button"
										onClick={() => setIsDialogOpen(false)}
										variant="secondary"
										className="mr-2"
									>
										Cancel
									</Button>
									<Button type="submit" className="min-w-[150px]">
										{methods.formState.isSubmitting ? (
											<Loader2Icon className="animate-spin" />
										) : (
											"Save"
										)}
									</Button>
								</div>
							</form>
						</FormProvider>
					</DialogContent>
				</CardContent>
			</Card>
		</Dialog>
	);
};
