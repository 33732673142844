import { PropertyType, VariableCategory } from "@prisma/client";
import { z } from "zod";

import { isNullOrUndefined } from "@repos/rate-resolver-shared";

import {
	isVariableValueValid,
	keySchema,
	nonNullableValueSchema,
	valueSchema,
} from "../../primitives";

export const propertyCreateSchema = z
	.object({
		key: keySchema,
		label: z.string().min(3, "Veuillez entrer au moins trois caractères"),
		type: z.nativeEnum(PropertyType),
		values: z.array(
			z.object({
				enumValueId: z.string().uuid(),
				value: valueSchema,
				enumValueLabel: z.string().optional(),
			}),
		),
	})
	.superRefine(({ type, values, label }, ctx) => {
		values.forEach(({ value }, index) => {
			const isValid = isVariableValueValid({
				value,
				type,
				acceptNullOrUndefined: false,
			});
			if (!isValid) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: `La valeur de la propriété "${label}" doit être de type ${type}`,
					path: ["values", index, "value"],
				});
			}
		});
	});

export const propertyCreateManySchema = z.object({
	projectId: z.string().uuid(),
	variableId: z.string().uuid(),
	properties: z.array(propertyCreateSchema),
});

export type PropertyCreateManyInput = z.infer<typeof propertyCreateManySchema>;

export const propertyUpsertSchema = z.object({
	projectId: z.string().uuid(),
	variableId: z.string().uuid(),
	propertyId: z.string().uuid().optional(),
	key: keySchema,
	label: z.string().min(3, "Veuillez entrer au moins trois caractères"),
	type: z.nativeEnum(PropertyType),
	values: z.array(
		z.object({
			enumValueId: z.string().uuid(),
			value: nonNullableValueSchema,
			enumValueLabel: z.string().optional(),
		}),
	),
});

export type PropertyUpsertInput = z.infer<typeof propertyUpsertSchema>;

export const propertyDeleteSchema = z.object({
	propertyId: z.string().uuid(),
});

export type PropertyDeleteInput = z.infer<typeof propertyDeleteSchema>;

export const propertySearchSchema = z
	.object({
		projectId: z.string().uuid(),
		variableId: z.string().uuid(),
		search: z.string().optional(),
		category: z.nativeEnum(VariableCategory).optional(),
		types: z.array(z.nativeEnum(PropertyType)).optional(),
	})
	.transform(({ search, ...rest }) => {
		if (isNullOrUndefined(search) || search.trim().length === 0) {
			return { search: undefined, ...rest };
		}
		return { search, ...rest };
	});

export type PropertySearchDTO = z.infer<typeof propertySearchSchema>;

export const getPropertyByIdSchema = z.object({
	propertyId: z.string().uuid(),
	variableId: z.string().uuid(),
	projectId: z.string().uuid(),
});

export type GetPropertyByIdDTO = z.infer<typeof getPropertyByIdSchema>;
