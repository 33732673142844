import type { VariableType } from "@prisma/client";
import { cn } from "@/utils";
import { isDate } from "date-fns";
import { CalendarIcon } from "lucide-react";

import {
	getVariableToString,
	isNullOrUndefined,
} from "@repos/rate-resolver-shared";

import { Button } from "../ui/button";
import { Calendar } from "../ui/calendar";
import { FormControl } from "../ui/form";
import { Input } from "../ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { CustomSelect } from "./CustomSelect";

type EnumValuesType = {
	value: string;
	label: string;
};

type StaticValueInputProps = {
	variableType: VariableType;
	rawValue?: string | number | boolean | Date | null | undefined;
	rawValueInput: string | number | boolean | Date | null | undefined;
	handleValueChange: (
		value: string | number | boolean | Date | null | undefined,
	) => void;
	setValueInput: (value: string) => void;
	enumValues?: EnumValuesType[];
};

const StaticValueInput = ({
	variableType,
	rawValue,
	rawValueInput,
	handleValueChange,
	setValueInput,
	enumValues,
}: StaticValueInputProps) => {
	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			event.preventDefault();
			handleValueChange(rawValueInput);
		}
	};

	switch (variableType) {
		case "ENUM":
			return (
				<CustomSelect
					placeholder="Selectionner une valeur par défaut"
					options={
						enumValues?.map((enumValue) => ({
							label: enumValue.label,
							value: enumValue.value,
						})) || []
					}
					disabled={!enumValues}
					value={isNullOrUndefined(rawValue) ? "" : String(rawValue)}
					onValueChange={(value) => {
						handleValueChange(value);
					}}
					className="h-8 w-full"
					isForm
				/>
			);
		case "BOOLEAN":
			return (
				<CustomSelect
					placeholder="Selectionner une valeur par défaut"
					options={[
						{ label: "Vrai", value: "true" },
						{ label: "Faux", value: "false" },
					]}
					value={
						isNullOrUndefined(rawValue)
							? undefined
							: getVariableToString(rawValue)
					}
					onValueChange={(value) => {
						const booleanValue = value === "true";
						handleValueChange(booleanValue);
					}}
					className="h-8 w-full"
					isForm
				/>
			);
		case "NUMBER":
			return (
				<Input
					type="number"
					placeholder="Valeur par défaut"
					value={isNullOrUndefined(rawValueInput) ? "" : Number(rawValueInput)}
					onChange={(e) => {
						const value = e.target.value;
						const parsedValue = value === "" ? "" : parseFloat(value);
						//@ts-expect-error: TODO: This show error because waiting a string
						setValueInput(parsedValue);
					}}
					className="h-8 w-full"
					onKeyDown={handleKeyDown}
				/>
			);
		case "STRING":
			return (
				<Input
					className="h-8 w-full"
					placeholder="Valeur"
					value={isNullOrUndefined(rawValueInput) ? "" : String(rawValueInput)}
					onChange={(e) => setValueInput(e.target.value)}
					onKeyDown={handleKeyDown}
				/>
			);
		case "DATE":
			return (
				<Popover>
					<PopoverTrigger asChild>
						<FormControl>
							<Button
								variant="outline"
								className={cn(
									"w-full justify-start text-left font-normal",
									!rawValueInput && "text-muted-foreground",
								)}
							>
								<CalendarIcon className="mr-2 h-4 w-4" />

								{isNullOrUndefined(rawValueInput)
									? "Pick a date"
									: getVariableToString(rawValueInput)}
							</Button>
						</FormControl>
					</PopoverTrigger>
					<PopoverContent className="w-auto p-0">
						<Calendar
							mode="single"
							selected={
								isDate(new Date(rawValueInput as string))
									? new Date(rawValueInput as string)
									: undefined
							}
							onSelect={(date) => handleValueChange(date?.toISOString())}
							initialFocus
						/>
					</PopoverContent>
				</Popover>
			);
		default:
			return null;
	}
};

export default StaticValueInput;
