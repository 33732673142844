import { PricingEngineStatus } from "@prisma/client";
import { z } from "zod";

// export const stepSummarySchema = z.object({
// 	stepLabel: z.string(),
// 	subStepSummaries: z.array(z.any()),
// });

// export type StepSummaryDto = z.infer<typeof stepSummarySchema>;

// export const summarySchema = z.object({
// 	stepSummaries: z.array(z.any()),
// 	lastStepSummary: z.any(),
// 	changes: z.any(),
// });

// export type SummaryDto = z.infer<typeof summarySchema>;

// export const tarificationOutpuSchema = z.object({
// 	output: z.any(),
// 	summaryUrl: z.string().url(),
// 	summary: summarySchema,
// });

// export type TarificationOutputDto = z.infer<typeof tarificationOutpuSchema>;

export const pricingEngineStatusUpdateSchema = z.object({
	projectId: z.string(),
	status: z.nativeEnum(PricingEngineStatus),
});

export type TarificationStatusUpdateDto = z.infer<
	typeof pricingEngineStatusUpdateSchema
>;

export const pricingEngineStatusSchema = z.object({
	projectId: z.string(),
});

export type TarificationStatusDto = z.infer<typeof pricingEngineStatusSchema>;
