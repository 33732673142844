import type { Node as ReactFlowNode } from "@xyflow/react";

import type { ApiOutputs } from "@repos/rate-resolver-api";
import type { NodeType } from "@repos/rate-resolver-shared";

export const TabsLabels = {
	VARIABLES: "Variables",
	ETAPES: "Etapes",
	OUTPUT: "Output",
	TARIFICATION: "Tarification",
} as const;

export type TabsLabelsType = (typeof TabsLabels)[keyof typeof TabsLabels];

export type Summary = ApiOutputs["pricingEngine"]["testRate"];
const OperationEnumType = {
	Operations: "operations",
	FallbackOperations: "fallbackOperations",
} as const;

export type OperationEnumType =
	(typeof OperationEnumType)[keyof typeof OperationEnumType];

export interface NodeMeasurements {
	width: number;
	height: number;
}

export interface FormattedNode extends Omit<ReactFlowNode, "type"> {
	type: NodeType;
	data: {
		label: string;
		id: string;
		[key: string]: unknown;
	};
	measured: {
		width: number;
		height: number;
	};
	[key: string]: unknown;
}

export type FormattedEdge = {
	id: string;
	source: string;
	target: string;
	targetHandle: string | undefined;
	label: string;
	type: string;
};

export type PropertyReturnType =
	ApiOutputs["properties"]["getByVariableId"][number];

export type PricingEngine = {
	id: string;
	name?: string | null;
	isEnabled: boolean;
	updatedAt: Date;
};
