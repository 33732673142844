import { useState } from "react";
import { EditVariable } from "@/components/EditVariable";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Eye, MoreHorizontal, MoreVertical, Pencil, Trash } from "lucide-react";
import { useNavigate } from "react-router-dom";

interface VariableActionsProps {
	variableId: string;
	isEnum?: boolean;
	onEdit: () => void;
	onDelete: () => void;
	iconOrientation?: "horizontal" | "vertical";
}

export const VariableActions = ({
	variableId,
	isEnum,
	onEdit,
	onDelete,
	iconOrientation = "horizontal",
}: VariableActionsProps) => {
	const [modalOpen, setModalOpen] = useState(false);
	const navigate = useNavigate();

	const handleOpenModal = () => {
		setModalOpen(true);
	};

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="ghost" type="button" className="h-4 w-8 p-0">
					<span className="sr-only">Ouvrir le menu</span>
					{iconOrientation === "vertical" ? (
						<MoreVertical className="h-4 w-4" />
					) : (
						<MoreHorizontal className="h-4 w-4" />
					)}
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="start">
				<Dialog open={modalOpen} onOpenChange={setModalOpen}>
					{isEnum ? (
						<DropdownMenuItem
							onClick={(e) => {
								e.preventDefault();
								navigate(
									"/projet/variables/proprietes?variableId=" + variableId,
								);
							}}
							onSelect={(e) => {
								e.preventDefault();
							}}
							className="cursor-pointer"
						>
							<Eye className="mr-2 h-4 w-4" />
							Voir propriétés
						</DropdownMenuItem>
					) : null}
					<DialogTrigger asChild>
						<DropdownMenuItem
							onSelect={(e) => {
								e.preventDefault();
								e.stopPropagation();
							}}
							onClick={(e) => {
								e.stopPropagation();
								handleOpenModal();
							}}
						>
							<Pencil className="mr-2 h-4 w-4" />
							Modifier
						</DropdownMenuItem>
					</DialogTrigger>

					<DialogContent
						className="max-w-[767px]"
						onWheel={(e) => e.stopPropagation()}
						onClick={(e) => e.stopPropagation()}
					>
						<EditVariable
							variableId={variableId}
							onVariableUpdated={() => {
								setModalOpen(false);
								onEdit();
							}}
						/>
					</DialogContent>
				</Dialog>
				<DropdownMenuItem
					onClick={(e) => {
						e.preventDefault();
						onDelete();
					}}
					onSelect={(e) => {
						e.preventDefault();
						e.stopPropagation();
					}}
					className="cursor-pointer"
				>
					<Trash className="mr-2 h-4 w-4" />
					Supprimer
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	);
};
