import { useEffect, useState } from "react";
import { EnumImportDialog } from "@/components/enumImporter";
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { useAppStore } from "@/stores/useAppStore";
import { trpc } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { VariableCategory, VariableType } from "@prisma/client";
import { Plus } from "lucide-react";
import {
	FormProvider,
	useFieldArray,
	useForm,
	useWatch,
} from "react-hook-form";
import { TbTableImport } from "react-icons/tb";

import type { VariableCreateDTO } from "@repos/rate-resolver-dtos";
import type { SimpleVariable } from "@repos/rate-resolver-shared";
import { VariableCreateSchema } from "@repos/rate-resolver-dtos";
import {
	getVariableCategoryOptions,
	getVariableTypeOptions,
	isNullOrUndefined,
} from "@repos/rate-resolver-shared";

import { CustomSelect } from "./shared/CustomSelect";
import StaticValueInput from "./shared/StaticValueInput";
import { SubmitButton } from "./shared/SubmitButton";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { PopoverContent } from "./ui/popover";

type AddVariableProps = {
	onVariableCreated?: (variable: SimpleVariable) => void;
};

const variableTypeOptions = getVariableTypeOptions();
const variableCategoryOptions = getVariableCategoryOptions();

export const AddVariable = ({ onVariableCreated }: AddVariableProps) => {
	const [isEnumImportModalOpen, setIsEnumImportModalOpen] = useState(false);
	const { selectedProject } = useAppStore((state) => ({
		selectedProject: state.selectedProject,
	}));

	const form = useForm<VariableCreateDTO>({
		resolver: zodResolver(VariableCreateSchema),
		defaultValues: {
			data: {
				label: "",
				key: "",
				type: VariableType.STRING,
				value: undefined,
				category: VariableCategory.INPUT,
				enumValues: undefined,
			},
			projectId: selectedProject?.id,
		},
	});

	const { fields, insert, replace, append } = useFieldArray({
		control: form.control,
		name: "data.enumValues",
	});

	const variableType = useWatch({ control: form.control, name: "data.type" });
	const enumValues = useWatch({
		control: form.control,
		name: "data.enumValues",
	});

	useEffect(() => {
		form.setValue("data.value", null);
	}, [variableType, form]);

	const { mutateAsync, error, isLoading } =
		trpc.variables.createVariable.useMutation();

	const onSubmit = async ({ data }: VariableCreateDTO) => {
		try {
			const variable = await mutateAsync({
				projectId: selectedProject?.id ?? null,
				data,
			});
			if (onVariableCreated) {
				onVariableCreated(variable);
			}
			form.reset();
		} catch (err) {
			console.log("Caught error:", err);
		}
	};

	const handleKeyDown = (
		event: React.KeyboardEvent<HTMLInputElement>,
		index: number,
	) => {
		if (event.key !== "Enter") {
			return;
		}
		event.preventDefault();
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore this will lead to validation errors anyway
		insert(index + 1, { label: undefined, value: undefined });
	};

	return (
		<>
			<PopoverContent
				align="start"
				className="ml-5 max-h-[400px] w-96 overflow-scroll scroll-auto"
				sideOffset={-10}
			>
				<FormProvider {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)}>
						<div className="grid gap-4">
							<div className="space-y-2">
								<h4 className="font-medium leading-none">
									Ajouter une variable
								</h4>
							</div>
							<div className="grid grid-cols-1 gap-4 py-2 xl:grid-cols-2 ">
								<div className="flex flex-col">
									<FormField
										control={form.control}
										name="data.label"
										render={({ field }) => (
											<FormItem>
												<FormLabel>Nom</FormLabel>
												<FormControl>
													<Input placeholder="Nom de la variable" {...field} />
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>
								</div>
								<div className="flex flex-col">
									<FormField
										control={form.control}
										name="data.key"
										render={({ field }) => (
											<FormItem>
												<FormLabel>Identifiant unique</FormLabel>
												<FormControl>
													<Input placeholder="Identifiant unique" {...field} />
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>
								</div>
								<div className="flex flex-col">
									<FormField
										control={form.control}
										name="data.type"
										render={({ field }) => (
											<FormItem>
												<FormLabel>Type de variable</FormLabel>
												<CustomSelect
													placeholder="Selectionner un type de variable"
													options={variableTypeOptions}
													value={field.value}
													onValueChange={field.onChange}
													className="h-8 w-full"
													isForm
												/>
												<FormMessage />
											</FormItem>
										)}
									/>
								</div>
								<div className="flex flex-col">
									<FormField
										control={form.control}
										name="data.value"
										render={({ field }) => (
											<FormItem>
												<FormLabel>Valeur par défaut</FormLabel>
												<StaticValueInput
													variableType={variableType}
													rawValue={field.value}
													rawValueInput={field.value}
													handleValueChange={(value) => field.onChange(value)}
													setValueInput={(value) =>
														isNullOrUndefined(value)
															? field.onChange(undefined)
															: field.onChange(value)
													}
													// This filter addresses the following use case:
													// When we start filling the enumValues and then clear the value input,
													// an error occurs because the default select component does not support an empty value.
													// This ensures that clearing the value input does not trigger a validation error.

													enumValues={enumValues?.filter(
														(elem) => !!elem.value,
													)}
												/>
												<FormMessage />
											</FormItem>
										)}
									/>
								</div>
								<div className="flex flex-col">
									<FormField
										control={form.control}
										name="data.category"
										render={({ field }) => (
											<FormItem>
												<FormLabel>Catégorie</FormLabel>
												<CustomSelect
													placeholder="Selectionner une catégorie"
													options={variableCategoryOptions}
													value={field.value}
													onValueChange={field.onChange}
													className="h-8 w-full"
													isForm
												/>
												<FormMessage />
											</FormItem>
										)}
									/>
								</div>
								{variableType === VariableType.ENUM && (
									<div className="flexflex-col col-span-2">
										<div className="mb-3 flex flex-row items-center justify-between gap-2">
											<h5>Options</h5>
											<Button
												variant={"outline"}
												type="button"
												size={"sm"}
												onClick={() => setIsEnumImportModalOpen(true)}
											>
												Import excel
												<TbTableImport className="ml-2 size-5" />
											</Button>
										</div>
										<div className="flex max-h-[170px] flex-col overflow-y-auto">
											{fields.map((field, index) => (
												<div key={field.id} className="mb-2 flex gap-2">
													<FormField
														control={form.control}
														name={`data.enumValues.${index}.label`}
														render={({ field }) => (
															<FormItem>
																<FormLabel>Nom</FormLabel>
																<FormControl>
																	<Input
																		placeholder="Nom de l'option"
																		{...field}
																	/>
																</FormControl>
																<FormMessage />
															</FormItem>
														)}
													/>
													<FormField
														control={form.control}
														name={`data.enumValues.${index}.value`}
														render={({ field }) => (
															<FormItem>
																<FormLabel>
																	Identifiant de l&apos;option
																</FormLabel>
																<FormControl>
																	<Input
																		placeholder="Identifiant de l'option"
																		{...field}
																		onKeyDown={(event) =>
																			handleKeyDown(event, index)
																		}
																	/>
																</FormControl>
																<FormMessage />
															</FormItem>
														)}
													/>
												</div>
											))}
										</div>
									</div>
								)}
							</div>
							{error && <div className="text-red-600">{error.message}</div>}
							<div className="flex items-center justify-end gap-5">
								{variableType === VariableType.ENUM && (
									<Button
										type="button"
										variant={"outline"}
										className="w-1/2"
										onClick={() =>
											// eslint-disable-next-line @typescript-eslint/ban-ts-comment
											// @ts-ignore this will lead to validation errors anyway
											append({ label: undefined, value: undefined })
										}
									>
										<Plus className="size-4" /> Add Enum Value
									</Button>
								)}
								<SubmitButton
									isLoading={isLoading}
									label="Envoyer"
									className="w-1/2"
								/>
							</div>
						</div>
					</form>
				</FormProvider>
			</PopoverContent>
			{isEnumImportModalOpen && (
				<EnumImportDialog
					isModalOpen={isEnumImportModalOpen}
					setIsModalOpen={setIsEnumImportModalOpen}
					onImport={(values) => {
						replace(values);
						setIsEnumImportModalOpen(false);
					}}
				/>
			)}
		</>
	);
};
