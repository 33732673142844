import type { EdgeProps } from "@xyflow/react";
import type { ReactNode } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Popover, PopoverContent } from "@/components/ui/popover";
import useBoolean from "@/hooks/useBoolean";
import usePricingFlowStore from "@/stores/flow/useFlowStore";
import { trpc } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { BaseEdge, EdgeLabelRenderer, getSmoothStepPath } from "@xyflow/react";
import { Loader2, Send } from "lucide-react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { useShallow } from "zustand/react/shallow";

import type { UpdateEdgeDTO } from "@repos/rate-resolver-dtos";
import { updateEdgeSchema } from "@repos/rate-resolver-dtos";

import { ToolbarButtons } from "./ToolbarButtons";

interface SmoothStepEdgeProps extends EdgeProps {
	colorMode?: "light" | "dark";
}

const SmoothStepEdge = (params: SmoothStepEdgeProps) => {
	const {
		sourceX,
		sourceY,
		targetX,
		targetY,
		sourcePosition,
		targetPosition,
		markerStart,
		colorMode,
		selected,
		data,
		id,
	} = params;

	const editor = useBoolean(false);

	const { onEdgesDelete, onUpdateEdge } = usePricingFlowStore(
		useShallow(({ onEdgesDelete, onUpdateEdge }) => ({
			onEdgesDelete,
			onUpdateEdge,
		})),
	);
	const updateEdge = trpc.edges.updateEdge.useMutation({
		onSuccess(_data: any) {
			onUpdateEdge(_data);
			editor.setFalse();
		},
		onError(error) {
			toast.error(error.message);
		},
	});

	const deleteEdge = trpc.edges.deleteEdge.useMutation({
		onSuccess: () => {
			onEdgesDelete(id);
		},
		onError(error) {
			toast.error(error.message);
		},
	});

	const { register, handleSubmit } = useForm<UpdateEdgeDTO>({
		resolver: zodResolver(updateEdgeSchema),
		defaultValues: {
			id: id,
			label: typeof data?.label === "string" ? data.label : "",
		},
	});

	const [edgePath, labelX, labelY] = getSmoothStepPath({
		sourceX,
		sourceY,
		sourcePosition,
		targetX,
		targetY,
		targetPosition,
	});

	const edgeColor = colorMode === "dark" ? "#ffffff" : "#475569";

	const onSubmit = handleSubmit(async (formData) => {
		try {
			await updateEdge.mutateAsync({
				id: id,
				label: formData.label,
			});
		} catch (error) {
			console.error("Failed to update edge:", error);
		}
	});

	const handleDelete = async () => {
		try {
			await deleteEdge.mutateAsync({ id: id });
		} catch (error) {
			console.error("Failed to delete edge:", error);
		}
	};

	return (
		<>
			<BaseEdge
				id={id}
				path={edgePath}
				markerStart={markerStart}
				style={{
					strokeWidth: selected ? 3 : 2,
					stroke: selected ? "#1573d1" : edgeColor,
				}}
			/>
			<EdgeLabelRenderer>
				<div
					style={{
						position: "absolute",
						transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
						pointerEvents: "all",
					}}
					className="nodrag nopan"
				>
					<Popover open={editor.value} onOpenChange={editor.setValue}>
						<span className="min-w-[60px] cursor-pointer text-center text-sm font-medium">
							{data?.label as ReactNode}
						</span>
						{selected && (
							<div className="absolute bottom-6 flex items-center rounded-sm border-[0.05rem] border-gray-500 bg-white dark:bg-card">
								<ToolbarButtons
									onDelete={handleDelete}
									onEdit={editor.setTrue}
									disableEdit={false}
									isEdge
									isLoading={updateEdge.isLoading}
								/>
							</div>
						)}

						<PopoverContent className="w-80">
							<form onSubmit={onSubmit} className="flex gap-2">
								<Input
									{...register("label")}
									placeholder="Enter edge label"
									className="flex-1"
									autoFocus
								/>
								<Button type="submit" disabled={updateEdge.isLoading}>
									{updateEdge.isLoading ? (
										<Loader2 className="animate-spin" />
									) : (
										<Send className="size-3" />
									)}
								</Button>
							</form>
						</PopoverContent>
					</Popover>
				</div>
			</EdgeLabelRenderer>
		</>
	);
};

export default SmoothStepEdge;
