import type { Node, NodeProps } from "@xyflow/react";
import { useState } from "react";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { useNodeActions } from "@/hooks/useNodeActions";
import usePricingFlowStore from "@/stores/flow/useFlowStore";
import { cn, trpc } from "@/utils";
import { formatNode } from "@/utils/formatNode";
import { Position, useReactFlow } from "@xyflow/react";
import { toast } from "sonner";
import { useShallow } from "zustand/react/shallow";

import type {
	ErrorMessageNode,
	ErrorMessageNodeUpdateDTO,
} from "@repos/rate-resolver-dtos";
import { NodeType } from "@repos/rate-resolver-shared";

import { CustomHandle } from "../shared/CustomHandle";
import { LabelEditor } from "../shared/LabelEditor";
import { NodeToolbarComponent } from "../shared/NodeToolbarComponent";
import { Textarea } from "../ui/textarea";

export type CustomErrorMessageNode = Node<
	ErrorMessageNode["data"] & { label: string; id: string },
	NodeType.ERROR_MESSAGE
>;

const EditableField = ({
	value,
	onChange,
	onKeyDown,
	onBlur,
}: {
	value: string;
	onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
	onKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement>;
	onBlur: React.FocusEventHandler<HTMLTextAreaElement>;
}) => (
	<div
		className={cn(
			"nodrag w-full rounded-sm text-sm hover:cursor-text hover:bg-gray-200 dark:hover:bg-zinc-700",
		)}
	>
		<Textarea
			placeholder={value || "Veuillez entrer un message d'erreur"}
			value={value}
			onKeyDown={onKeyDown}
			onChange={onChange}
			onBlur={onBlur}
			autoFocus
			className={cn(
				"nodrag max-h-[20px] w-full rounded-md border-none border-transparent p-0 pl-2 !shadow-none focus:border-none focus-visible:bg-gray-200 focus-visible:!shadow-none focus-visible:ring-0 dark:focus-visible:bg-zinc-700",
			)}
		/>
	</div>
);

export const CustomErrorMessageNode = ({
	data,
	isConnectable,
	selected,
}: NodeProps<CustomErrorMessageNode>) => {
	const [isEditing, setIsEditing] = useState({
		label: false,
		textMessage: false,
	});
	const [formData, setFormData] = useState({
		textMessage: data.message,
		label: data.label,
	});

	const { onUpdateNode, onNodesDelete, onDuplicateNode } = usePricingFlowStore(
		useShallow(({ onUpdateNode, onNodesDelete, onDuplicateNode }) => ({
			onUpdateNode,
			onNodesDelete,
			onDuplicateNode,
		})),
	);
	const updateNodeMutation = trpc.nodes.updateErrorMessageNode.useMutation();

	const deleteNodeMutation = trpc.nodes.deleteNode.useMutation({
		onSuccess: () => {
			onNodesDelete(data.id);
			showToast("Noeud supprimé avec succès!");
		},
		onError: (error) => showToast(`Erreur: ${error.message}`, true),
	});

	const showToast = (message: string, isError = false) => {
		toast[isError ? "error" : "success"](message, {
			action: { label: "X", onClick: () => toast.dismiss() },
		});
	};

	const handleEditToggle = (field: keyof typeof formData) =>
		setIsEditing((prev) => ({ ...prev, [field]: !prev[field] }));

	const handleChange = (
		e: React.ChangeEvent<HTMLTextAreaElement>,
		field: keyof typeof formData,
	) => {
		setFormData((prev) => ({ ...prev, [field]: e.target.value }));
	};

	const handleKeyDown = (
		e: React.KeyboardEvent<HTMLTextAreaElement>,
		field: keyof typeof formData,
	) => {
		if (e.key === "Enter") {
			handleEditToggle(field);
			updateNode();
		}
	};

	const updateNode = async (newLabel?: string) => {
		const updateData: ErrorMessageNodeUpdateDTO = {
			id: data.id,
			data: { message: formData.textMessage },
			label: newLabel || formData.label,
		};
		updateNodeMutation
			.mutateAsync(updateData)
			.then((_data) => {
				onUpdateNode(formatNode(_data));
			})
			.catch((e) => {
				toast.error(`Erreur: ${e.message}`, {
					action: { label: "X", onClick: () => toast.dismiss() },
				});
			});
	};

	const handleDelete = async () => {
		try {
			await deleteNodeMutation.mutateAsync({ id: data.id });
		} catch (error) {
			console.error("Erreur lors de la suppression:", error);
		}
	};
	const reactFlowInstance = useReactFlow();
	const { handleDuplicate } = useNodeActions({
		onDuplicateSuccess: (newNode) => {
			onDuplicateNode(formatNode(newNode)); // Update local state or store
		},
		reactFlowInstance,
	});

	return (
		<>
			<CustomHandle
				type="source"
				position={Position.Top}
				id="error"
				isConnectable={isConnectable}
				className="z-10 bg-black"
			/>
			<Card
				className={cn(
					"relative w-72 border-2 border-black",
					selected ? "ring-2 ring-blue-500" : "",
				)}
			>
				<CardHeader className="flex flex-row items-baseline">
					<LabelEditor
						initialLabel={data.label}
						onSubmit={async (newLabel) => {
							if (!deleteNodeMutation.isLoading) {
								updateNode(newLabel);
							}
						}}
						disabled={deleteNodeMutation.isLoading}
						nodeType={NodeType.ERROR_MESSAGE}
					/>
				</CardHeader>
				<CardContent className="mb-5 w-full p-2">
					{isEditing.textMessage ? (
						<EditableField
							value={formData.textMessage}
							onChange={(e) => handleChange(e, "textMessage")}
							onKeyDown={(e) => handleKeyDown(e, "textMessage")}
							onBlur={() => {
								handleEditToggle("textMessage");
								if (formData.textMessage !== data.message) {
									updateNode();
								}
							}}
						/>
					) : (
						<div
							className={cn(
								"w-full max-w-full cursor-pointer pl-2 text-sm",
								formData.textMessage ? "" : "text-gray-500 dark:text-gray-400",
							)}
							onClick={() => handleEditToggle("textMessage")}
						>
							{formData.textMessage || "Veuillez entrer un message d'erreur"}
						</div>
					)}
				</CardContent>
				<NodeToolbarComponent
					isVisible={selected ?? false}
					onDelete={handleDelete}
					onDuplicate={() => handleDuplicate(data.id)}
					disableEdit={deleteNodeMutation.isLoading}
					isLoading={deleteNodeMutation.isLoading}
				/>
			</Card>
		</>
	);
};
