import { Button } from "@/components/ui/button";
import { ClipboardPaste, Copy, ReplaceAll } from "lucide-react";

type CopyPasteActionsProps = {
	isValid: boolean;
	hasFields: boolean;
	copiedItems: boolean;
	onCopy: () => void;
	onReplace: () => void;
	onAppend: () => void;
};

export const CopyPasteActions = ({
	isValid,
	hasFields,
	copiedItems,
	onCopy,
	onReplace,
	onAppend,
}: CopyPasteActionsProps) => {
	if (!hasFields) return null;

	return (
		<>
			{isValid && (
				<Button variant="ghost" type="button" size="sm" onClick={onCopy}>
					<Copy className="size-4" />
				</Button>
			)}
			{copiedItems && (
				<>
					<Button variant="ghost" type="button" size="sm" onClick={onReplace}>
						<ReplaceAll className="size-4" />
					</Button>

					<Button variant="ghost" type="button" size="sm" onClick={onAppend}>
						<ClipboardPaste className="size-4" />
					</Button>
				</>
			)}
		</>
	);
};
