import { VariableCategory, VariableType } from "@prisma/client";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

export const getVariableTypeOptions = () => {
	return Object.values(VariableType).map((type) => {
		switch (type) {
			case VariableType.STRING:
				return { label: "Chaine de caractères", value: VariableType.STRING };
			case VariableType.NUMBER:
				return { label: "Nombre", value: VariableType.NUMBER };
			case VariableType.BOOLEAN:
				return { label: "Booléen", value: VariableType.BOOLEAN };
			case VariableType.DATE:
				return { label: "Date", value: VariableType.DATE };
			case VariableType.ENUM:
				return { label: "Enumeration", value: VariableType.ENUM };
		}
	});
};

export const getVariableCategoryOptions = () => {
	return Object.values(VariableCategory).map((category) => {
		switch (category) {
			case VariableCategory.GLOBAL_CONFIG:
				return {
					label: "Variable Globale",
					value: VariableCategory.GLOBAL_CONFIG,
				};
			case VariableCategory.PROJECT_CONFIG:
				return {
					label: "Variable Projét",
					value: VariableCategory.PROJECT_CONFIG,
				};
			case VariableCategory.USER:
				return { label: "Variable Utilisateur", value: VariableCategory.USER };
			case VariableCategory.INPUT:
				return { label: "Variable D'entrée", value: VariableCategory.INPUT };
		}
	});
};

export const isNumber = (value: unknown) => {
	return !isNaN(Number(value));
};

export const getVariableToString = (
	variable: string | number | boolean | Date,
): string => {
	const isNumberValue = isNumber(variable);

	if (variable instanceof Date) {
		return format(variable, "dd MMMM yyyy", { locale: fr });
	}

	if (typeof variable === "number" || isNumberValue) {
		return String(variable);
	}

	if (typeof variable === "boolean") {
		return variable ? "Oui" : "Non";
	}

	if (typeof variable === "string") {
		if (new Date(variable).toString() === "Invalid Date") {
			return variable;
		} else {
			return format(new Date(variable), "dd MMMM yyyy", { locale: fr });
		}
	}

	return variable;
};

export const formatCategory = (category: VariableCategory) => {
	return getVariableCategoryOptions().find(
		(option) => option.value === category,
	)?.label;
};

export function handleArrayToCsv(data: string[][]): string {
	return data
		.map(
			(row) =>
				row
					.map((value) => {
						if (!value) {
							return "";
						}
						// Escape double quotes by replacing them with two double quotes
						const escapedValue = String(value).replace(/"/g, '""');
						// Wrap the value in double quotes if it contains special characters
						return `"${escapedValue}"`;
					})
					.join(","), // Join the values in the row with commas
		)
		.join("\n"); // Join all rows with newlines
}

export function handleStringToEnumValue(input: string): string {
	const resultString = input
		.replace(/[^a-zA-Z0-9\s]/g, "") // Allow spaces for the next step
		.replace(/\s+/g, "_") // Replace spaces with underscores
		.toUpperCase(); // Convert to uppercase
	return resultString;
}
