import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import usePricingFlowStore from "@/stores/flow/useFlowStore";
import { trpc } from "@/utils";
import { formatNode } from "@/utils/formatNode";
import { useNodeId } from "@xyflow/react";
import { toast } from "sonner";
import { useShallow } from "zustand/react/shallow";

export const AddGroupCard = () => {
	const nodeId = useNodeId();
	const { onUpdateNode } = usePricingFlowStore(
		useShallow(({ onUpdateNode }) => ({
			onUpdateNode,
		})),
	);
	const addGroupMutation = trpc.nodes.createConditionGroup.useMutation({
		onSuccess: (_data) => {
			onUpdateNode(formatNode(_data));
		},
		onError: (error) => {
			toast.error(`${error.message || "Failed to add group"}`, {
				action: {
					label: "X",
					onClick: () => toast.dismiss(),
				},
			});
		},
	});

	return (
		<Card
			onClick={() => {
				if (nodeId) {
					addGroupMutation.mutate({ nodeId: nodeId });
				}
			}}
			className="flex min-h-20 items-center justify-center overflow-hidden rounded-sm border-[0.05rem] border-dashed border-gray-500 bg-white dark:bg-card"
		>
			<Button
				variant={"ghost"}
				disabled={!nodeId}
				className="min-h-20 w-full hover:bg-gray-800"
				onClick={() => {
					if (nodeId) {
						addGroupMutation.mutate({ nodeId: nodeId });
					}
				}}
			>
				Ajouter groupe de conditions
			</Button>
		</Card>
	);
};
