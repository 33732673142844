import { PropertyType } from "@prisma/client";

export const getPropertyTypeOptions = () => {
	return Object.values(PropertyType).map((type) => {
		switch (type) {
			case PropertyType.STRING:
				return { label: "Chaine de caractères", value: PropertyType.STRING };
			case PropertyType.NUMBER:
				return { label: "Nombre", value: PropertyType.NUMBER };
			case PropertyType.BOOLEAN:
				return { label: "Booléen", value: PropertyType.BOOLEAN };
			case PropertyType.DATE:
				return { label: "Date", value: PropertyType.DATE };
		}
	});
};
