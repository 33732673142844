import { z } from "zod";

import { NodeType } from "@repos/rate-resolver-shared";

export const nodeSchema = z.object({
	id: z.string().uuid(),
	label: z.string().optional(),
	type: z.nativeEnum(NodeType),
});

export type Node = z.infer<typeof nodeSchema>;
