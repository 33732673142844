import { useRef, useState } from "react";
import { NodeIcon } from "@/components/FlowSideBar";
import { useThemeStore } from "@/stores/useThemeStore";
import { cn } from "@/utils";

import type { NodeType } from "@repos/rate-resolver-shared";

import { Input } from "../ui/input";

interface LabelEditorProps {
	initialLabel: string;
	onSubmit: (newLabel: string) => Promise<void> | void;
	className?: string;
	inputClassName?: string;
	disabled?: boolean;
	nodeType: NodeType;
}

export function LabelEditor({
	initialLabel,
	onSubmit,
	className,
	inputClassName,
	disabled,
	nodeType,
}: LabelEditorProps) {
	const theme = useThemeStore(({ theme }) => theme);
	const [label, setLabel] = useState(initialLabel);

	const editRef = useRef<HTMLInputElement>(null);

	const handleLabelSubmit = async () => {
		if (label === initialLabel) {
			return;
		}
		try {
			await onSubmit(label);
			editRef.current?.blur();
		} catch (error) {
			console.error(error);
		}
	};
	return (
		<div className="flex w-full items-center">
			<span className="mr-2">
				{
					<NodeIcon
						nodeType={nodeType}
						color={theme === "dark" ? "white" : "black"}
					/>
				}
			</span>
			<div
				className={cn(
					"nodrag w-full rounded-sm text-sm hover:cursor-text hover:bg-gray-200 dark:hover:bg-zinc-700",
					className,
				)}
			>
				<Input
					ref={editRef}
					value={label}
					onChange={(e) => {
						setLabel(e.target.value);
					}}
					onKeyDown={(e) => e.key === "Enter" && handleLabelSubmit()}
					onBlur={handleLabelSubmit}
					onClick={(e) => e.stopPropagation()}
					onFocus={(e) => e.stopPropagation()}
					className={cn(
						"nodrag max-h-[20px] w-full rounded-md border-none border-transparent p-0 pl-2 text-sm !shadow-none focus:border-none focus-visible:bg-gray-200 focus-visible:!shadow-none focus-visible:ring-0 dark:focus-visible:bg-zinc-700",
						inputClassName,
					)}
					disabled={disabled}
				/>
			</div>
		</div>
	);
}
