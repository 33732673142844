import type { ReactNode } from "react";
import { useState } from "react";
import { trpc } from "@/utils";
import { useAuth } from "@clerk/clerk-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { httpBatchLink } from "@trpc/client";

import { transformer } from "@repos/trpc-utils";

type TrpcProviderProps = {
	children: ReactNode;
};

export const TrpcProvider = ({ children }: TrpcProviderProps) => {
	const { getToken } = useAuth();
	const [queryClient] = useState(
		() =>
			new QueryClient({
				defaultOptions: {
					queries: { refetchOnWindowFocus: false, cacheTime: 0 },
				},
			}),
	);

	const [trpcClient] = useState(() =>
		trpc.createClient({
			links: [
				httpBatchLink({
					url: `${import.meta.env.VITE_PUBLIC_RATE_RESOLVER_API_URL}/api/trpc`,
					async headers() {
						const token = await getToken();
						return {
							Authorization: "Bearer " + token,
						};
					},
				}),
			],
			transformer: transformer,
		}),
	);

	return (
		<trpc.Provider client={trpcClient} queryClient={queryClient}>
			<QueryClientProvider client={queryClient}>
				{/* eslint-disable-next-line turbo/no-undeclared-env-vars */}
				{import.meta.env.MODE === "development" && <ReactQueryDevtools />}
				{children}
			</QueryClientProvider>
		</trpc.Provider>
	);
};
