import type { SheetData } from "@/components/spreadsheet/types";
import type { SelectionWithId } from "react-datasheet-grid/dist/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { keyColumn, textColumn } from "react-datasheet-grid";

export type GetSelectedDataReturn = {
	selectedData: SheetData;
	isSingleColumn: boolean;
	isSingleRow: boolean;
	numberOfColumns: number;
};

export function columnIndexToLabel(index: number): string {
	let columnName = "";
	let tempIndex = index + 1; // Excel columns are 1-based, so adjust by adding 1

	while (tempIndex > 0) {
		const remainder = (tempIndex - 1) % 26;
		columnName = String.fromCharCode(65 + remainder) + columnName; // 65 is the char code for 'A'
		tempIndex = Math.floor((tempIndex - 1) / 26);
	}

	return columnName;
}

export const useSpreadSheetData = ({
	sheetData = [],
	isFirstRowHeader,
}: {
	sheetData?: SheetData;
	isFirstRowHeader: boolean;
}) => {
	const defaultData = useMemo(
		() =>
			sheetData
				.slice(isFirstRowHeader ? 1 : 0) // Skip the first row if it's a header
				.map((row) =>
					row.reduce(
						(acc, cell, index) => {
							acc[`col${index}`] = cell || ""; // Map each cell to a unique key (e.g., col0, col1, etc.)
							return acc;
						},
						{} as Record<string, string>,
					),
				),
		[sheetData, isFirstRowHeader],
	);

	const defaultColumns = useMemo(
		() =>
			sheetData[0]?.map((col, index) => ({
				...keyColumn(`col${index}`, textColumn),
				...{
					title: isFirstRowHeader ? col || "" : columnIndexToLabel(index),
				},
			})) || [],
		[sheetData, isFirstRowHeader],
	);

	const [data, setData] = useState(defaultData);
	const [columns, setColumns] = useState(defaultColumns);

	const getSelectedData = useCallback(
		(selection: SelectionWithId): GetSelectedDataReturn => {
			const { min, max } = selection;

			const numberOfSelectedRows = max.row - min.row + 1;

			if (numberOfSelectedRows > 100) {
				console.log("Selection exceeds 100 rows");
			}

			const selectedRows = [...data.slice(min.row, max.row + 1)];
			const selectedData = selectedRows.map((row) =>
				Object.keys(row)
					.slice(min.col, max.col + 1)
					.map((col) => row[col] as string),
			);

			const isSingleColumn = min.col === max.col && min.row !== max.row;
			const isSingleRow = min.row === max.row && min.col !== max.col;
			const numberOfColumns = max.col - min.col + 1;

			return { selectedData, isSingleColumn, isSingleRow, numberOfColumns };
		},
		[data],
	);

	useEffect(() => {
		setData(defaultData);
		setColumns(defaultColumns);
	}, [defaultData, defaultColumns]);

	return { data, columns, setData, getSelectedData };
};
