import type { RouteObject } from "react-router-dom";
import { Layout } from "@/layouts/DashboardLayout";
import { RootLayout } from "@/layouts/RootLayout";
import Projects from "@/pages/projects";
import { PropertiesPage } from "@/pages/Properties";
import { SignInPage } from "@/pages/SignInPage";
import { SignUpPage } from "@/pages/SignUpPage";
import StepsFlow from "@/pages/StepsFlow";
import { Tarification } from "@/pages/Tarification";
import { Variables } from "@/pages/Variables";
import WorkBookReader from "@/pages/WorkBookReader";
import * as Sentry from "@sentry/react";
import { BrowserRouter, Navigate, useRoutes } from "react-router-dom";

const useSentryRoutes = Sentry.wrapUseRoutes(useRoutes);

const Routes = () => {
	const commonRoutes: RouteObject[] = [
		{
			path: "/",
			element: <Navigate to="/sign-in" replace />,
		},
		{
			element: <RootLayout />,
			children: [
				{
					path: "/sign-in",
					element: <SignInPage />,
				},
				{
					path: "/sign-up",
					element: <SignUpPage />,
				},
			],
		},
		{
			element: <Layout />,
			children: [
				{
					path: "/projets",
					element: <Projects />,
				},
				{
					path: "/projet/etapes",
					element: <StepsFlow />,
				},
				{
					path: "/projet/variables",
					element: <Variables />,
				},
				{
					path: "/projet/variables/proprietes",
					element: <PropertiesPage />,
				},
				{
					path: "/projet/tarification",
					element: <Tarification />,
				},
				{ path: "/projet/variables/importer", element: <WorkBookReader /> },
			],
		},
	];

	const routes = useSentryRoutes([...commonRoutes]);
	return routes;
};

export const AppRoutes = () => {
	return (
		<BrowserRouter>
			<Routes />
		</BrowserRouter>
	);
};
