import { useEffect } from "react";
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { useAppStore } from "@/stores/useAppStore";
import { trpc } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { VariableCategory, VariableType } from "@prisma/client";
import { Loader2, Plus } from "lucide-react";
import {
	FormProvider,
	useFieldArray,
	useForm,
	useWatch,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

import type { VariableUpdateDTO } from "@repos/rate-resolver-dtos";
import type { SimpleVariable } from "@repos/rate-resolver-shared";
import { VariableUpdateSchema } from "@repos/rate-resolver-dtos";
import { isNullOrUndefined } from "@repos/rate-resolver-shared";

import StaticValueInput from "./shared/StaticValueInput";
import { Button } from "./ui/button";
import { DialogTitle } from "./ui/dialog";
import { Input } from "./ui/input";

type EditVariableProps = {
	variableId: string;
	onVariableUpdated?: (variable: SimpleVariable) => void;
};

export const EditVariable = ({
	variableId,
	onVariableUpdated,
}: EditVariableProps) => {
	const navigate = useNavigate();
	const { selectedVariables, setSelectedVariables, selectedProject } =
		useAppStore((state) => ({
			selectedVariables: state.selectedVariables,
			setSelectedVariables: state.setSelectedVariables,
			selectedProject: state.selectedProject,
		}));

	const { data: variable } = trpc.variables.getVariable.useQuery({
		id: variableId,
		projectId: selectedProject?.id ?? "",
	});

	const form = useForm<VariableUpdateDTO>({
		resolver: zodResolver(VariableUpdateSchema),
		defaultValues: {
			data: {
				value: "",
				key: "",
				label: "",
				enumValues: variable?.enumValues ?? undefined,
			},
			id: variable?.id ?? "",
			category: variable?.category ?? VariableCategory.INPUT,
			type: variable?.type ?? VariableType.STRING,
		},
	});

	useEffect(() => {
		if (variable) {
			form.reset({
				data: {
					value: variable.value,
					key: variable.key,
					label: variable.label,
					enumValues:
						variable.type === VariableType.ENUM
							? variable.enumValues
							: undefined,
				},
				id: variable.id,
				category: variable.category,
				type: variable.type,
			});
		}
	}, [variable, form]);

	const { fields, append } = useFieldArray({
		control: form.control,
		name: "data.enumValues",
	});

	const variableType = useWatch({ control: form.control, name: "type" });
	const enumValues = useWatch({
		control: form.control,
		name: "data.enumValues",
	});

	useEffect(() => {
		form.setValue("data.value", variable?.defaultValue ?? null);
	}, [variableType, form, variable?.defaultValue]);

	const { mutateAsync, error, isLoading } =
		trpc.variables.updateVariable.useMutation();

	const onSubmit = async (data: VariableUpdateDTO) => {
		try {
			let hasNewEnumValues = false;
			if (data.data.enumValues) {
				const { enumValues } = data.data;
				hasNewEnumValues = enumValues.some((enumValue) => !enumValue.id);
			}
			const res = await mutateAsync(data);
			const updatedVariables = [
				...selectedVariables.map((oldVar) => {
					if (oldVar.id === res.id) {
						return {
							...oldVar,
							value: res.value,
							key: res.key,
							label: res.label,
							enumValues: res.enumValues,
						};
					}
					return oldVar;
				}),
			];
			setSelectedVariables(updatedVariables);

			form.reset();

			if (onVariableUpdated) {
				//@ts-expect-error we get ts error anyway
				onVariableUpdated(data);
			}

			if (hasNewEnumValues) {
				toast.warning(
					"Veuillez mettre à jour les propriétés de la variable: " +
						variable?.label,
					{
						action: {
							label: "Propriétés",
							onClick: () =>
								navigate(
									`/projet/variables/proprietes?variableId=${variableId}`,
								),
						},
					},
				);
			}
		} catch (err) {
			console.log("Caught error:", err);
		}
	};

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)}>
				<div className="grid gap-4">
					<DialogTitle>
						<div className="space-y-2">
							<h4 className="font-medium leading-none">
								Modifier {variable?.label.toLowerCase()}
							</h4>
						</div>
					</DialogTitle>
					<div className="grid grid-cols-1 gap-4 px-2 py-2 xl:grid-cols-2 ">
						<div className="flex flex-col">
							<FormField
								control={form.control}
								name="data.label"
								render={({ field }) => (
									<FormItem>
										<FormLabel>Nom</FormLabel>
										<FormControl>
											<Input placeholder="Nom de la variable" {...field} />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>
						<div className="flex flex-col">
							<FormField
								control={form.control}
								name="data.key"
								render={({ field }) => (
									<FormItem>
										<FormLabel>Identifiant unique</FormLabel>
										<FormControl>
											<Input placeholder="Identifiant unique" {...field} />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>
						<div className="flex flex-col">
							<FormField
								control={form.control}
								name="data.value"
								render={({ field }) => (
									<FormItem>
										<FormLabel>Valeur par défaut</FormLabel>
										<FormControl>
											<StaticValueInput
												variableType={variableType}
												rawValue={field.value}
												rawValueInput={field.value}
												handleValueChange={(value) => field.onChange(value)}
												setValueInput={(value) =>
													isNullOrUndefined(value)
														? field.onChange(undefined)
														: field.onChange(value)
												}
												enumValues={enumValues}
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>
						{variableType === VariableType.ENUM && (
							<div className="col-span-2 flex flex-col">
								<h5 className="mb-3 font-medium leading-none">Options</h5>
								<div className="grid max-h-60 gap-4 overflow-y-auto">
									{fields.map((field, index) => (
										<div
											key={field.id}
											className="mb-2 grid grid-cols-2 items-center gap-4 p-2"
										>
											<FormField
												control={form.control}
												name={`data.enumValues.${index}.label`}
												render={({ field }) => (
													<FormItem>
														<FormLabel>Nom</FormLabel>
														<FormControl>
															<Input placeholder="Nom de l'option" {...field} />
														</FormControl>
														<FormMessage />
													</FormItem>
												)}
											/>
											<FormField
												control={form.control}
												name={`data.enumValues.${index}.value`}
												render={({ field }) => (
													<FormItem>
														<FormLabel>Identifiant de l&apos;option</FormLabel>
														<FormControl>
															<Input
																placeholder="Identifiant de l'option"
																{...field}
															/>
														</FormControl>
														<FormMessage />
													</FormItem>
												)}
											/>
										</div>
									))}
								</div>
							</div>
						)}
					</div>
					{error && <div className="text-red-600">{error.message}</div>}
					<div className="flex items-center justify-end gap-5">
						{variableType === VariableType.ENUM && (
							<Button
								type="button"
								variant={"outline"}
								className="w-1/2"
								// eslint-disable-next-line @typescript-eslint/ban-ts-comment
								// @ts-ignore this will lead to validation errors anyway
								onClick={() => append({ label: undefined, value: undefined })}
							>
								<Plus className="size-4" /> Add Enum Value
							</Button>
						)}
						<Button
							type="button"
							className="w-1/2"
							variant={"secondary"}
							disabled={isLoading}
							onClick={form.handleSubmit(onSubmit, (err) => console.log(err))}
						>
							Envoyer
							{isLoading ? (
								<Loader2 className="ml-2 h-4 w-4 animate-spin" />
							) : null}
						</Button>
					</div>
				</div>
			</form>
		</FormProvider>
	);
};
