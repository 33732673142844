import type { PricingEngine } from "@/types";
import { useCallback, useEffect } from "react";
import { TarificationFlowSummary } from "@/components/tarification/components/TarificationFlowSummary";
import { TarificationSummary } from "@/components/tarification/components/TarificationSummary";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useAppStore } from "@/stores";
import usePricingFlowStore from "@/stores/flow/useFlowStore";
import { trpc } from "@/utils";
import * as Sentry from "@sentry/react";
import { ReactFlowProvider } from "@xyflow/react";
import { useNavigate } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";

function TarificationOutput() {
	const navigate = useNavigate();

	const { tarificationSummary, selectedProject } = useAppStore((state) => ({
		tarificationSummary: state.tarificationSummary,
		selectedProject: state.selectedProject,
	}));

	const {
		setPricingEnginesList,
		setSelectedPricingEngine,
		selectedPricingEngine,
	} = usePricingFlowStore(
		useShallow((state) => ({
			setPricingEnginesList: state.setPricingEnginesList,
			selectedPricingEngine: state.selectedPricingEngine,
			setSelectedPricingEngine: state.setSelectedPricingEngine,
		})),
	);

	// Fetch pricing engines based on the selected project ID.
	const { data: engines } = trpc.pricingEngine.getPricingEngines.useQuery(
		{ projectId: selectedProject?.id || "" },
		{
			enabled: Boolean(selectedProject),
		},
	);

	// Function to handle updating pricing engines and selecting the first enabled engine.
	const handleSetEngines = useCallback(
		(engines: PricingEngine[]) => {
			setPricingEnginesList(engines);
			const enabledEngine = engines.find((engine) => engine.isEnabled);

			if (enabledEngine) {
				setSelectedPricingEngine(enabledEngine);
			}
		},
		[setPricingEnginesList, setSelectedPricingEngine],
	);

	useEffect(() => {
		if (engines) {
			handleSetEngines(engines);
		}
	}, [engines, handleSetEngines]);

	if (!selectedProject) {
		navigate("/projets");
		return null;
	}

	return (
		<Sentry.ErrorBoundary
			fallback={({ error }) => (
				<div>
					Une erreur est survenue lors de la génération de la tarification
					veuillez réessayer
					{/*  eslint-disable-next-line turbo/no-undeclared-env-vars */}
					<span>{import.meta.env.MODE === "development" && error.message}</span>
				</div>
			)}
		>
			<div className="size-full h-[80vh]">
				{tarificationSummary ? (
					<Tabs
						defaultValue="summary"
						className="flex h-full w-full w-full flex-col overflow-y-auto"
					>
						<TabsList>
							<TabsTrigger
								value="summary"
								className="flex-1  data-[state=active]:bg-primary"
							>
								Résumé
							</TabsTrigger>
							<TabsTrigger
								value="flow"
								className="flex-1 data-[state=active]:bg-primary"
							>
								Flow
							</TabsTrigger>
						</TabsList>
						<TabsContent value="summary">
							<TarificationSummary />
						</TabsContent>
						<TabsContent value="flow">
							<ReactFlowProvider>
								<TarificationFlowSummary key={selectedPricingEngine?.id} />
							</ReactFlowProvider>
						</TabsContent>
					</Tabs>
				) : null}
			</div>
		</Sentry.ErrorBoundary>
	);
}

export default TarificationOutput;
