import { PropertiesTable } from "@/components/dataTable/properties/PropertiesTable";
import { NotFound } from "@/components/NotFound";
import { trpc } from "@/utils";
import { Loader2 } from "lucide-react";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";

export const PropertiesPage = () => {
	const [searchParams] = useSearchParams();
	const variableId = searchParams.get("variableId");
	const { isLoading: isLoadingVariable, isError } =
		trpc.variables.getVariable.useQuery({
			id: variableId || "",
		});

	if (isLoadingVariable) {
		return (
			<div className="flex h-[80vh] items-center justify-center bg-background">
				<Loader2 className="h-16 w-16 animate-spin text-primary" />
			</div>
		);
	}
	if (z.string().uuid().safeParse(variableId).success === false || isError) {
		return (
			<NotFound message="Variable introuvable" backLink="/projet/variables" />
		);
	}

	return <PropertiesTable variableId={variableId as string} />;
};
