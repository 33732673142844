import type { VariableType } from "@prisma/client";
import { useEffect } from "react";
import { useAppStore } from "@/stores/useAppStore";
import { trpc } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { TRPCClientError } from "@trpc/client";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "sonner";

import type { TestTarificationDTO } from "@repos/rate-resolver-dtos";
import { testTarificationSchema } from "@repos/rate-resolver-dtos";
import { isNullOrUndefined } from "@repos/rate-resolver-shared";

import StaticValueInput from "../shared/StaticValueInput";
import { SubmitButton } from "../shared/SubmitButton";
import { Card } from "../ui/card";
import { FormField, FormItem, FormLabel, FormMessage } from "../ui/form";

export const TarificationTestForm = () => {
	const { selectedProject, setTarificationSummary, setInputVariablesOutput } =
		useAppStore((state) => ({
			selectedProject: state.selectedProject,
			setTarificationSummary: state.setTarificationSummary,
			setInputVariablesOutput: state.setInputVariablesOutput,
		}));

	const { data: inputVariables } = trpc.variables.getVariables.useQuery(
		{
			category: "INPUT",
			projectId: selectedProject?.id ?? "",
			excludeProperties: true,
			excludeVariables: false,
		},
		{
			enabled: !!selectedProject,
		},
	);

	const form = useForm<TestTarificationDTO>({
		resolver: zodResolver(testTarificationSchema),
		defaultValues: {
			inputs: [],
		},
		mode: "onChange",
	});

	useEffect(() => {
		if (inputVariables && inputVariables.length > 0) {
			form.reset({
				inputs: inputVariables.map((inputVariable) => ({
					type: inputVariable.type,
					value: inputVariable.defaultValue || inputVariable.value || undefined,
					key: inputVariable.key,
				})),
			});
			setTarificationSummary(null);
		}
	}, [inputVariables, form, setTarificationSummary]);

	// TODO : Handle Loading and error state
	const { mutateAsync, isLoading } = trpc.pricingEngine.testRate.useMutation();

	const onSubmit = async (data: TestTarificationDTO) => {
		try {
			setInputVariablesOutput(
				data.inputs.map((input) => ({
					key: input.key,
					type: input.type,
					value: input.value === null ? "" : String(input.value),
				})),
			);
			const inputData = data.inputs.reduce(
				(acc, input) => {
					// Assign the value to the key directly
					acc[input.key] = input.value as VariableType;
					return acc;
				},
				{} as Record<string, VariableType>,
			);

			const resultCallApi = await mutateAsync({
				projectId: selectedProject?.id ?? "",
				inputData: inputData,
			});
			setTarificationSummary(resultCallApi);
		} catch (err) {
			let message =
				"Une erreur est survenue lors de la tarification veuillez réessayer";
			if (err instanceof TRPCClientError) {
				message = err.message;
			}
			toast.error(message, {
				action: {
					label: "X",
					onClick: () => toast.dismiss(),
				},
			});
		}
	};

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(onSubmit, (err) => console.log(err))}>
				<div className="grid grid-cols-1 gap-6 xl:grid-cols-2">
					{inputVariables?.map((inputVariable, index) => {
						return (
							<FormField
								key={index}
								control={form.control}
								name={`inputs.${index}.value`}
								render={({ field }) => (
									<Card className="rounded-2xl border-0 p-6 shadow-xl">
										<FormItem>
											<FormLabel>{inputVariable.label}</FormLabel>
											<StaticValueInput
												variableType={inputVariable.type}
												rawValue={field.value}
												rawValueInput={field.value}
												handleValueChange={(value) => field.onChange(value)}
												setValueInput={(value) =>
													isNullOrUndefined(value)
														? field.onChange(undefined)
														: field.onChange(value)
												}
												enumValues={inputVariable.enumValues}
											/>
											<FormMessage />
										</FormItem>
									</Card>
								)}
							/>
						);
					})}
				</div>
				<div className="mt-12 flex w-full justify-end">
					<SubmitButton
						isLoading={isLoading}
						label="Tester"
						className="px-8 py-4"
					/>
				</div>
			</form>
		</FormProvider>
	);
};
