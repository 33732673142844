import type { HandleProps } from "@xyflow/react";
import { cn } from "@/utils";
import { Handle } from "@xyflow/react";

interface CustomHandleProps extends Omit<HandleProps, "type"> {
	type: "source" | "target";
}

export const CustomHandle: React.FC<CustomHandleProps> = ({
	type,
	position,
	id,
	className,
	...rest
}) => (
	<Handle
		type={type}
		position={position}
		id={id}
		className={cn("size-4 rounded-full border-2 border-white", className)}
		{...rest}
	/>
);
