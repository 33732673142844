import { useCallback, useState } from "react";
import { DataTable } from "@/components/dataTable/data-table";
import {
	DataTableAccessory,
	getColumns,
} from "@/components/dataTable/properties";
import { Loader } from "@/components/shared/Loader";
import { useDebounce } from "@/hooks/useDebounce";
import { useAppStore } from "@/stores/useAppStore";
import { trpc } from "@/utils";

type PropertiesProps = {
	variableId: string;
};
export const PropertiesTable = ({ variableId }: PropertiesProps) => {
	const selectedProject = useAppStore((state) => state.selectedProject);
	const [search, setSearch] = useState<string>("");
	const debouncedSearch = useDebounce(search, 300);

	const { data: properties } = trpc.properties.getByVariableId.useQuery({
		projectId: selectedProject?.id ?? "",
		variableId,
		search: debouncedSearch,
	});

	const { data: variable, isLoading: isLoadingVariable } =
		trpc.variables.getVariable.useQuery({
			id: variableId,
		});

	const getHeaders = useCallback((): { [key: string]: string } => {
		const headers = {
			label: "Label propriété",
		};
		variable?.enumValues.forEach((enumValue) => {
			headers[enumValue.id] = enumValue.label;
		});
		return headers;
	}, [variable]);

	if (isLoadingVariable) {
		return (
			<div className="flex h-[60vh] items-center justify-center">
				<Loader />
			</div>
		);
	}

	return (
		<div className="overflow-x-auto">
			<DataTable
				columns={getColumns({
					headers: getHeaders(),
				})}
				data={properties ?? []}
				DataTableAccessoryComponent={() => (
					<DataTableAccessory
						searchValue={search}
						onChangeSearchValue={setSearch}
						variableId={variableId}
					/>
				)}
			/>
		</div>
	);
};
