import { Popover, PopoverTrigger } from "@/components/ui/popover";
import { useNavigate } from "react-router-dom";

import { Button } from "../../ui/button";
import { Input } from "../../ui/input";

export interface DataTableAccessoryProps {
	searchValue: string;
	onChangeSearchValue: (value: string) => void;
	buttonText: string;
	ActionComponent?: React.ElementType;
	buttonIcon: React.ReactNode;
}

export function DataTableAccessory({
	ActionComponent,
	buttonText,
	onChangeSearchValue,
	searchValue,
	buttonIcon,
}: DataTableAccessoryProps) {
	const navigate = useNavigate();
	return (
		<div className="flex items-center justify-between py-4">
			<Input
				placeholder="Recherche variable..."
				value={searchValue}
				onChange={(e) => onChangeSearchValue(e.target.value)}
				className="ml-2 max-w-sm"
			/>
			<div>
				<Button
					onClick={() => navigate("/projet/variables/importer")}
					className="mr-2"
				>
					{buttonIcon}
					Importer des variables
				</Button>
				<Popover modal={true}>
					<PopoverTrigger asChild>
						<Button>
							{buttonIcon}
							{buttonText}
						</Button>
					</PopoverTrigger>
					{ActionComponent ? <ActionComponent /> : null}
				</Popover>
			</div>
		</div>
	);
}
