import type { ReactNode } from "react";
import { Suspense } from "react";
import { ErrorPage } from "@/pages/ErrorPage";
import * as Sentry from "@sentry/react";

type AppProviderProps = {
	children: ReactNode;
};

export const SentryProvider = ({ children }: AppProviderProps) => {
	return (
		<Suspense
			fallback={
				<div className="flex h-screen w-screen items-center justify-center">
					loading ...
				</div>
			}
		>
			<Sentry.ErrorBoundary fallback={<ErrorPage />}>
				{children}
			</Sentry.ErrorBoundary>
		</Suspense>
	);
};
