import { useState } from "react";
import { sections } from "@/components/FlowSideBar/nodeSections";
import { Input } from "@/components/ui/input";

import type { NodeType } from "@repos/rate-resolver-shared";

import { Card, CardContent } from "../ui/card";
import { useSidebarDnD } from "./context/SidebarDnDContext";
import { NodeIcon } from "./NodeIcon";

export const SideBarNodes = () => {
	const { setNodeType } = useSidebarDnD();
	const [searchQuery, setSearchQuery] = useState("");

	const onDragStart = (event: React.DragEvent, nodeType: NodeType) => {
		setNodeType(nodeType);
		event.dataTransfer.setData("application/reactflow", nodeType);
		event.dataTransfer.effectAllowed = "move";
	};

	const filteredSections = sections
		.map((section) => ({
			...section,
			nodes: section.nodes.filter((node) =>
				node.label.toLowerCase().includes(searchQuery.toLowerCase()),
			),
		}))
		.filter((section) => section.nodes.length > 0);

	return (
		<section className="side-bar-nodes-flow">
			<div className="relative">
				<Input
					type="search"
					placeholder="Rechercher un composant"
					value={searchQuery}
					onChange={(e) => setSearchQuery(e.target.value)}
				/>
			</div>
			{filteredSections.map((section) => (
				<div key={section.title}>
					<h2 className="mb-2 text-sm font-semibold text-zinc-700 dark:text-zinc-400">
						{section.title}
					</h2>
					<div className="grid grid-cols-2 gap-2">
						{section.nodes.map((node) => (
							<Card
								key={node.key}
								className="cursor-grab dark:border-zinc-700 dark:bg-zinc-800 dark:hover:bg-zinc-700"
								onDragStart={(event) => onDragStart(event, node.key)}
								draggable
							>
								<CardContent className="flex items-center justify-start space-x-2 p-2">
									<NodeIcon
										nodeType={node.key}
										color={section.accentColor}
										className="self-start"
									/>
									<span className="text-xs font-medium">{node.label}</span>
								</CardContent>
							</Card>
						))}
					</div>
				</div>
			))}
		</section>
	);
};
