import { cn } from "@/utils";
import { Ban, Cable, Flag, FlagOff, Group, Hash } from "lucide-react";

import { NodeType } from "@repos/rate-resolver-shared";

type NodeIconProps = {
	color: string;
	nodeType: NodeType;
	className?: string;
};

export const NodeIcon = ({ color, nodeType, className }: NodeIconProps) => {
	const classNames = cn("size-4", className);
	switch (nodeType) {
		case NodeType.CONDITIONS_GROUP:
			return <Group className={classNames} color={color} />;
		case NodeType.ERROR_MESSAGE:
			return <Ban className={classNames} color={color} />;
		case NodeType.START:
			return <Flag className={classNames} color={color} />;
		case NodeType.END:
			return <FlagOff className={classNames} color={color} />;
		case NodeType.OPERATIONS:
			return <Hash className={classNames} color={color} />;
		case NodeType.OUTPUT:
			return <Cable className={classNames} color={color} />;
		default:
			return <div className={`h-6 w-6 rounded-full`} color={color} />;
	}
};
