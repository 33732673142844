import { z } from "zod";

import { operationSchema } from "../../primitives";

export const operationsNodeUpdateSchema = z.object({
	nodeId: z.string().uuid(),
	projectId: z.string().uuid(),
	label: z.string().optional(),
	operations: z.array(operationSchema).default([]),
});

export type OperationsNodeUpdateDTO = z.infer<
	typeof operationsNodeUpdateSchema
>;
