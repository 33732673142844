export enum EdgeType {
	DEFAULT = "DEFAULT",
	CONDITION_TRUE = "CONDITION_TRUE",
}
type ConditionTrueHandle = `CONDITION_TRUE_${number}`;

export const TargetHandle = {
	CONDITION_TRUE: (groupIndex: number): ConditionTrueHandle =>
		`CONDITION_TRUE_${groupIndex}`,
	TARGET: "target",
} as const;

export const getGroupIndexFromTargetHandle = (targetHandle: string): number => {
	const groupIndex = Number(targetHandle.replace("CONDITION_TRUE_", ""));
	return groupIndex;
};

export const isConditionTrueTargetHandle = (
	targetHandle: string,
): targetHandle is ConditionTrueHandle => {
	return targetHandle.startsWith("CONDITION_TRUE_");
};
