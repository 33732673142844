import type { FormattedEdge, FormattedNode, PricingEngine } from "@/types";
import type { Connection, EdgeChange, NodeChange } from "@xyflow/react";
import { addEdge, applyEdgeChanges, applyNodeChanges } from "@xyflow/react";
import { create } from "zustand";

import type { AppState } from "./types";

/**
 * This hook provides a Zustand store for managing nodes and edges in a pricing flow diagram.
 * It offers various actions and state to interact with the flow, such as adding/deleting nodes and edges,
 * handling connections, and updating node and edge data.
 */
const usePricingFlowStore = create<AppState>((set, get) => ({
	selectedPricingEngine: null,
	pricingEnginesList: [],
	/** An array of nodes in the flow */
	nodes: [],
	/** An array of edges (connections) between nodes */
	edges: [],
	/** Loading state, useful for async operations */
	loading: false,
	/** Stores any error that occurs during operations */
	error: null,

	/**
	 * Handles changes in the nodes array, applying the node changes.
	 * @param changes - Array of node changes to apply
	 */
	onNodesChange: (changes: NodeChange<FormattedNode>[]) => {
		set(({ nodes }) => ({
			nodes: applyNodeChanges(changes, nodes),
		}));
	},

	/**
	 * Handles changes in the edges array, applying the edge changes.
	 * @param changes - Array of edge changes to apply
	 */
	onEdgesChange: (changes: EdgeChange<FormattedEdge>[]) => {
		set(({ edges }) => ({
			edges: applyEdgeChanges(changes, edges),
		}));
	},

	/**
	 * Adds a new edge (connection) when a node is connected to another.
	 * @param connection - The connection object representing the new edge
	 */
	onConnect: (connection: Connection) => {
		set(({ edges }) => ({
			edges: addEdge(connection, edges),
		}));
	},

	/**
	 * Sets the list of nodes in the flow.
	 * @param nodes - Array of nodes to set
	 */
	setNodes: (nodes: FormattedNode[]) => {
		set({ nodes });
	},

	/**
	 * Sets the list of edges in the flow.
	 * @param edges - Array of edges to set
	 */
	setEdges: (edges: FormattedEdge[]) => {
		set({ edges });
	},

	/**
	 * Adds a new edge to the flow.
	 * @param edge - The edge to add
	 */
	addEdge: (edge: FormattedEdge) => {
		set(({ edges }) => ({
			edges: edges.concat(edge),
		}));
	},

	/**
	 * Deletes an edge from the flow by its ID.
	 * @param edgeId - The ID of the edge to delete
	 */
	onEdgesDelete: (edgeId: string) => {
		set(({ edges }) => ({
			edges: edges.filter((edge) => edge.id !== edgeId),
		}));
	},

	/**
	 * Deletes a node and its edges from the flow by its ID.
	 * @param nodeId - The ID of the node to delete
	 */
	onNodesDelete: (nodeId: string) => {
		set(({ nodes, edges }) => ({
			nodes: nodes.filter((node) => node.id !== nodeId),
			edges: edges.filter(
				(edge) => edge.source !== nodeId && edge.target !== nodeId,
			),
		}));
	},

	/**
	 * Updates an edge with new data, such as a label, source, target, etc.
	 * @param edge - The edge object containing updated information
	 */
	onUpdateEdge: (
		edge: FormattedEdge & {
			data: {
				label: string;
				id: string;
			};
		},
	) => {
		set(({ edges }) => ({
			edges: edges.map((e) =>
				e.id === edge.id
					? {
							...e,
							data: {
								...edge.data,
							},
							label: edge.data.label,
							source: edge.source,
							target: edge.target,
						}
					: e,
			),
		}));
	},

	/**
	 * Updates a node with new data, such as label or any other information.
	 * @param node - The node object containing updated information
	 */
	onUpdateNode: (node) => {
		set(({ nodes }) => ({
			nodes: nodes.map((n) =>
				n.id === node.id
					? {
							...n,
							...node,
							// node updates should not change the position of the node
							position: {
								...n.position,
							},
							data: {
								...node.data,
							},
							label: node.data.label,
						}
					: n,
			),
		}));
	},
	onDuplicateNode: (node: FormattedNode) => {
		set(({ nodes }) => ({
			nodes: nodes.concat(node),
		}));
	},
	setError: (error: Error | null) => {
		set({ error });
	},
	setLoading: (loading: boolean) => {
		set({ loading });
	},
	setSelectedPricingEngine: (selectedPricingEngine: PricingEngine) => {
		set({ selectedPricingEngine: selectedPricingEngine });
	},
	setPricingEnginesList: (pricingEnginesList: any) => {
		set({ pricingEnginesList });
	},
	addPricingEngineToList: (engine: PricingEngine) => {
		set({
			pricingEnginesList: get().pricingEnginesList.concat(engine),
		});
	},
	onEngineUpdate: (engine: PricingEngine) => {
		set(({ pricingEnginesList }) => ({
			pricingEnginesList: pricingEnginesList.map((e) =>
				e.id === engine.id
					? engine
					: {
							...e,
							isEnabled: false,
						},
			),
		}));
		if (engine.id === get().selectedPricingEngine?.id) {
			set(() => ({
				selectedPricingEngine: engine,
			}));
		}
	},
	// onEnableEngine: (engineId: string) => {
	// 	// only one engine could be enabled
	// 	set(({ pricingEnginesList }) => ({
	// 		pricingEnginesList: pricingEnginesList.map((e) =>
	// 			e.id === engineId
	// 				? {
	// 						...e,
	// 						isEnabled: true,
	// 					}
	// 				: {
	// 						...e,
	// 						isEnabled: false,
	// 					},
	// 		),
	// 	}));
	// 	// only one engine could be enabled
	// 	set(({ selectedPricingEngine }) => ({
	// 		// enable selected engine
	// 		selectedPricingEngine:
	// 			selectedPricingEngine && selectedPricingEngine.id == engineId
	// 				? ({
	// 						...selectedPricingEngine,
	// 						isEnabled: true,
	// 					} as PricingEngine)
	// 				: selectedPricingEngine,
	// 	}));
	// },
	onDeletePriceEngine: (engineId: string) => {
		set(({ pricingEnginesList }) => ({
			pricingEnginesList: pricingEnginesList.filter((e) => e.id !== engineId),
		}));
	},
}));

export default usePricingFlowStore;
