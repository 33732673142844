import type { FormattedEdge, FormattedNode } from "@/types";
import type { EdgeChange, NodeChange } from "@xyflow/react";
import { useCallback, useEffect, useState } from "react";
import {
	animatedEdgeTypes,
	nodeSummaryTypes,
} from "@/components/nodes/CustomComponentsTypes";
import SmoothStepEdge from "@/components/shared/SmoothStepEdge";
import { Button } from "@/components/ui/button";
import { useLayoutElements } from "@/hooks/useLayoutElements";
import { useAppStore, useThemeStore } from "@/stores";
import usePricingFlowStore from "@/stores/flow/useFlowStore";
import { trpc } from "@/utils";
import { formatEdge } from "@/utils/formatEdge";
import { formatNode } from "@/utils/formatNode";
import {
	applyEdgeChanges,
	applyNodeChanges,
	Background,
	BackgroundVariant,
	Controls,
	Panel,
	ReactFlow,
} from "@xyflow/react";
import { Loader2, Network } from "lucide-react";

export const TarificationFlowSummary = () => {
	const { selectedProject, tarificationSummary } = useAppStore((state) => ({
		selectedProject: state.selectedProject,
		tarificationSummary: state.tarificationSummary,
	}));

	const { selectedPricingEngine } = usePricingFlowStore((state) => ({
		selectedPricingEngine: state.selectedPricingEngine,
	}));

	const { theme } = useThemeStore();
	const { layoutElements } = useLayoutElements();

	const [localNodes, setLocalNodes] = useState<FormattedNode[]>([]);
	const [localEdges, setLocalEdges] = useState<FormattedEdge[]>([]);

	const { data: nodes } = trpc.nodes.getNodes.useQuery(
		{ pricingEngineId: selectedPricingEngine?.id ?? "" },
		{
			enabled: !!selectedProject,
			select: (nodes) => nodes.map((node) => formatNode(node)),
		},
	);

	const { data: edges } = trpc.edges.getEdges.useQuery(
		{ pricingEngineId: selectedPricingEngine?.id ?? "" },
		{
			enabled: !!selectedProject,
			select: (edges) => edges.map(formatEdge),
		},
	);

	const onNodesChange = useCallback((changes: NodeChange<FormattedNode>[]) => {
		setLocalNodes((nodes) => applyNodeChanges(changes, nodes));
	}, []);

	const onEdgesChange = useCallback((changes: EdgeChange<FormattedEdge>[]) => {
		setLocalEdges((edge) => applyEdgeChanges(changes, edge));
	}, []);

	useEffect(() => {
		if (nodes && edges && tarificationSummary?.summary) {
			const visitedEdgeIds = new Set(
				tarificationSummary.summary.visitedEdges.map((edge) => edge.id),
			);

			// Remove orphan nodes
			const connectedNodeIds = new Set(
				edges.flatMap((edge) => [edge.source, edge.target]),
			);

			const filteredNodes = nodes.filter((node) =>
				connectedNodeIds.has(node.id),
			);

			const updatedEdges = edges.map((edge) => ({
				...edge,
				type: visitedEdgeIds.has(edge.id) ? "custom-edge" : "default",
				data: {
					...edge,
					visited: visitedEdgeIds.has(edge.id),
				},
			}));

			setLocalEdges(updatedEdges);

			layoutElements({
				nodes: filteredNodes,
				edges: updatedEdges,
			})
				.then((nodes) => {
					if (nodes) {
						setLocalNodes(nodes);
					}
				})
				.catch((error) => {
					console.error("Layout failed:", error);
				});
		}
	}, [nodes, edges, tarificationSummary, layoutElements]);

	if (!tarificationSummary) {
		return (
			<div className="flex h-[85vh] w-full items-center justify-center">
				<Loader2 className="size-4 animate-spin" />
			</div>
		);
	}

	return (
		<div className="h-[75vh] w-full">
			<ReactFlow
				colorMode={theme}
				nodes={localNodes}
				edges={localEdges}
				onNodesChange={onNodesChange}
				onEdgesChange={onEdgesChange}
				nodeTypes={nodeSummaryTypes}
				edgeTypes={{
					...animatedEdgeTypes,
					default: SmoothStepEdge,
				}}
				nodesDraggable={false}
				nodesConnectable={false}
				elementsSelectable={false}
				panOnScroll={false}
				zoomOnPinch={false}
				zoomOnDoubleClick={false}
				fitView
			>
				<Controls position="bottom-right" />
				<Background variant={BackgroundVariant.Dots} />
				<Panel position="top-right">
					<Button
						onClick={() => {
							layoutElements({
								nodes: localNodes,
								edges: localEdges,
							}).then((nodes) => nodes && setLocalNodes(nodes));
						}}
						size="icon"
					>
						<Network />
					</Button>
				</Panel>
			</ReactFlow>
		</div>
	);
};
