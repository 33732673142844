import type { UseFieldArrayRemove } from "react-hook-form";
import { useState } from "react";
import { VariableSelect } from "@/components/variableSelect/VariableSelect";
import { getDefaultOperand } from "@/lib";
import { useAppStore } from "@/stores/useAppStore";
import { useFormContext, useWatch } from "react-hook-form";

import type {
	Operand as OperandDTO,
	OperationsNodeUpdateDTO,
} from "@repos/rate-resolver-dtos";
import type { SimpleVariable } from "@repos/rate-resolver-shared";
import { isNullOrUndefined, OperandType } from "@repos/rate-resolver-shared";

interface OperandProps {
	basePath: "operations";
	operandIndex: number;
	operationIndex: number;
	removeOperation: UseFieldArrayRemove;
	totalOperands: number;
	isOperandDisabled: boolean;
	allowedVariableTypes: SimpleVariable["type"][];
	resetOperands: () => void;
}

export const Operand = ({
	operandIndex,
	operationIndex,
	removeOperation,
	totalOperands,
	isOperandDisabled,
	allowedVariableTypes,
	resetOperands,
	basePath,
}: OperandProps) => {
	const operand = useWatch({
		name: `${basePath}.${operationIndex}.operands.${operandIndex}`,
		defaultValue: getDefaultOperand(),
	});
	const { initialOperand } = useAppStore((state) => ({
		initialOperand: state.getVariableByKey(
			typeof operand.value === "string" &&
				(operand.type === OperandType.FIELD ||
					operand.type === OperandType.FIELD_PROPERTY)
				? operand.value
				: undefined,
		),
	}));
	const [selectedOperand, setSelectedOperand] = useState<
		SimpleVariable | null | undefined
	>(initialOperand);

	const form = useFormContext<OperationsNodeUpdateDTO>();

	const rawValue = useWatch({
		control: form.control,
		name: `${basePath}.${operationIndex}.operands.${operandIndex}.value`,
		defaultValue: undefined,
	}) as OperandDTO["value"] | undefined;

	const rawType = useWatch({
		control: form.control,
		name: `${basePath}.${operationIndex}.operands.${operandIndex}.type`,
		defaultValue: undefined,
	}) as OperandDTO["type"] | undefined;

	return (
		<VariableSelect
			excludeProperties={false}
			variableTypes={allowedVariableTypes}
			onSelect={(variable) => {
				const prevOperandType = selectedOperand?.type;
				setSelectedOperand(variable);
				if (
					!isNullOrUndefined(prevOperandType) &&
					operandIndex === 0 &&
					prevOperandType !== variable.type &&
					totalOperands !== 1
				) {
					const indexesToRemove = Array.from(
						{ length: totalOperands - 1 },
						(_, i) => i + 1,
					);
					removeOperation(indexesToRemove);
					form.setValue(
						`${basePath}.${operationIndex}.operationType`,
						undefined,
					);
				}
				form.setValue(
					`${basePath}.${operationIndex}.operands.${operandIndex}.type`,
					variable.isProperty ? OperandType.FIELD_PROPERTY : OperandType.FIELD,
				);
				form.setValue(
					`${basePath}.${operationIndex}.operands.${operandIndex}.value`,
					variable.key,
				);
				form.setValue(
					`${basePath}.${operationIndex}.operands.${operandIndex}.variableType`,
					variable.type,
				);
			}}
			selectedItem={operand.variableType ? selectedOperand : null}
			allowRawValue={operandIndex !== 0}
			onRawValueSubmit={(value, type) => {
				form.setValue(
					`${basePath}.${operationIndex}.operands.${operandIndex}.type`,
					OperandType.VALUE,
				);
				form.setValue(
					`${basePath}.${operationIndex}.operands.${operandIndex}.value`,
					value as string,
				);
				form.setValue(
					`${basePath}.${operationIndex}.operands.${operandIndex}.variableType`,
					type,
				);
				setSelectedOperand(null);
			}}
			rawValue={rawType === OperandType.VALUE ? rawValue : undefined}
			disabled={isOperandDisabled}
			onClear={() => {
				if (operandIndex === 0 && totalOperands > 1) {
					resetOperands();
					form.setValue(
						`${basePath}.${operationIndex}.operationType`,
						undefined,
					);
				} else {
					setSelectedOperand(null);
					removeOperation(operandIndex);
				}
			}}
		/>
	);
};
