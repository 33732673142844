import * as React from "react";
import { ButtonProps, buttonVariants } from "@/components/ui/button";
import { cn } from "@/utils";
import { ChevronLeft, ChevronRight, MoreHorizontal } from "lucide-react";

interface PaginationProps extends React.ComponentProps<"nav"> {
	currentPage: number;
	totalItems: number;
	itemsPerPage: number;
	onPageChange: (page: number) => void;
}

const Pagination = ({
	className,
	currentPage,
	totalItems,
	itemsPerPage,
	onPageChange,
	...props
}: PaginationProps) => {
	const totalPages = Math.ceil(totalItems / itemsPerPage);

	const handlePrevious = () => {
		if (currentPage > 1) {
			onPageChange(currentPage - 1);
		}
	};

	const handleNext = () => {
		if (currentPage < totalPages) {
			onPageChange(currentPage + 1);
		}
	};

	return (
		<nav
			role="navigation"
			aria-label="pagination"
			className={cn("mx-auto flex w-full justify-center", className)}
			{...props}
		>
			<PaginationPrevious
				onClick={handlePrevious}
				disabled={currentPage === 1}
			/>
			<PaginationContent>
				{Array.from({ length: totalPages }, (_, index) => (
					<PaginationItem key={index}>
						<PaginationLink
							isActive={currentPage === index + 1}
							onClick={() => onPageChange(index + 1)}
						>
							{index + 1}
						</PaginationLink>
					</PaginationItem>
				))}
			</PaginationContent>
			<PaginationNext
				onClick={handleNext}
				disabled={currentPage === totalPages}
			/>
		</nav>
	);
};
Pagination.displayName = "Pagination";

const PaginationContent = React.forwardRef<
	HTMLUListElement,
	React.ComponentProps<"ul">
>(({ className, ...props }, ref) => (
	<ul
		ref={ref}
		className={cn("flex flex-row items-center gap-1", className)}
		{...props}
	/>
));
PaginationContent.displayName = "PaginationContent";

const PaginationItem = React.forwardRef<
	HTMLLIElement,
	React.ComponentProps<"li">
>(({ className, ...props }, ref) => (
	<li ref={ref} className={cn("", className)} {...props} />
));
PaginationItem.displayName = "PaginationItem";

type PaginationLinkProps = {
	isActive?: boolean;
	disabled?: boolean;
} & Pick<ButtonProps, "size"> &
	React.ComponentProps<"a">;

const PaginationLink = ({
	className,
	isActive,
	disabled,
	size = "icon",
	...props
}: PaginationLinkProps) => (
	<a
		aria-current={isActive ? "page" : undefined}
		aria-disabled={disabled}
		className={cn(
			buttonVariants({
				variant: isActive ? "outline" : "ghost",
				size,
			}),
			className,
			disabled && "cursor-not-allowed opacity-50",
		)}
		onClick={(e) => {
			if (disabled) {
				e.preventDefault();
			} else {
				props.onClick?.(e);
			}
		}}
		{...props}
	/>
);
PaginationLink.displayName = "PaginationLink";

const PaginationPrevious = ({
	className,
	disabled,
	...props
}: React.ComponentProps<typeof PaginationLink> & { disabled: boolean }) => (
	<PaginationLink
		aria-label="Go to previous page"
		size="default"
		className={cn("gap-1 pl-2.5", className)}
		onClick={(e) => {
			if (!disabled) {
				props.onClick?.(e);
			}
		}}
		disabled={disabled}
		{...props}
	>
		<ChevronLeft className="h-4 w-4" />
		<span>Précédent</span>
	</PaginationLink>
);
PaginationPrevious.displayName = "PaginationPrevious";

const PaginationNext = ({
	className,
	disabled,
	...props
}: React.ComponentProps<typeof PaginationLink> & { disabled: boolean }) => (
	<PaginationLink
		aria-label="Go to next page"
		size="default"
		className={cn("gap-1 pr-2.5", className)}
		onClick={(e) => {
			if (!disabled) {
				props.onClick?.(e);
			}
		}}
		disabled={disabled}
		{...props}
	>
		<span>Suivant</span>
		<ChevronRight className="h-4 w-4" />
	</PaginationLink>
);
PaginationNext.displayName = "PaginationNext";

const PaginationEllipsis = ({
	className,
	...props
}: React.ComponentProps<"span">) => (
	<span
		aria-hidden
		className={cn("flex h-9 w-9 items-center justify-center", className)}
		{...props}
	>
		<MoreHorizontal className="h-4 w-4" />
		<span className="sr-only">More pages</span>
	</span>
);
PaginationEllipsis.displayName = "PaginationEllipsis";

export {
	Pagination,
	PaginationContent,
	PaginationEllipsis,
	PaginationItem,
	PaginationLink,
	PaginationNext,
	PaginationPrevious,
};
