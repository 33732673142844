import type { SheetData } from "@/components/spreadsheet/types";
import { useCallback } from "react";
import { CustomSelect } from "@/components/shared/CustomSelect";
import { FileSelect } from "@/components/shared/FileSelect";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import * as XLSX from "xlsx";

interface WorkbookSelectorProps {
	workbook: XLSX.WorkBook | null;
	selectedSheet: string | null;
	isFirstRowHeader: boolean;
	setWorkbook: (workbook: XLSX.WorkBook | null) => void;
	setIsFirstRowHeader: (isFirstRowHeader: boolean) => void;
	setSelectedSheet: (sheet: string | null) => void;
	setSelectedSheetData: (data: SheetData | null) => void;
	reset: () => void;
}
export const WorkbookSelector = ({
	workbook,
	selectedSheet,
	isFirstRowHeader,
	setWorkbook,
	reset,
	setIsFirstRowHeader,
	setSelectedSheet,
	setSelectedSheetData,
}: WorkbookSelectorProps) => {
	const handleFileUpload = useCallback(
		(file: File) => {
			const reader = new FileReader();

			reader.onload = (e) => {
				if (e.target) {
					const data = new Uint8Array(e.target.result as ArrayBuffer);
					const workbook = XLSX.read(data, { type: "array" });
					setWorkbook(workbook);
					reset();
				}
			};

			reader.readAsArrayBuffer(file);
		},
		[setWorkbook, reset],
	);

	const handleWorkbookSelect = useCallback(
		(selectedSheet: string) => {
			if (!workbook) return;
			setSelectedSheet(selectedSheet);

			// Parse the selected sheet data
			const newSelectedSheetData = workbook.Sheets[selectedSheet];
			if (!newSelectedSheetData) {
				console.error("Worksheet not found");
				return;
			}

			const json = XLSX.utils.sheet_to_json(newSelectedSheetData, {
				header: 1,
				defval: "",
			});
			if (Array.isArray(json)) {
				const formattedData = json.map((row) => {
					if (Array.isArray(row)) {
						return row.map((cell) => cell || "") as string[];
					}
					return [];
				});
				setSelectedSheetData(formattedData);
			}
		},
		[setSelectedSheetData, setSelectedSheet, workbook],
	);

	return (
		<div className="space-y-4">
			<FileSelect
				accept={{
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
						[],
					"application/vnd.ms-excel": [".xlsx", ".xls"],
				}}
				onFileSelect={handleFileUpload}
			/>

			{workbook && workbook.SheetNames.length > 0 && (
				<div className="flex flex-row gap-4">
					<div className="flex max-w-[30%] flex-1 flex-col gap-2">
						<Label>Select Workbook</Label>
						<CustomSelect
							options={workbook.SheetNames.map((name) => ({
								label: name,
								value: name,
							}))}
							placeholder="Workbooks"
							value={selectedSheet ?? ""}
							onValueChange={handleWorkbookSelect}
						/>
					</div>
					<div className="mt-4 flex items-center space-x-2">
						<Checkbox
							id="firstRowIsHeader"
							checked={isFirstRowHeader}
							onCheckedChange={setIsFirstRowHeader}
							className="size-6"
						/>
						<label
							htmlFor="firstRowIsHeader"
							className="text-sm font-medium peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
						>
							Premiere ligne est en-tête
						</label>
					</div>
				</div>
			)}
		</div>
	);
};
