import { Panel } from "@xyflow/react";

import { SideBarNodes } from "./SidebarNodes";
import SnapshotSection from "./SnapshotSection";

export const FlowSideBar = () => {
	return (
		<Panel className="mt-16 flex h-[89%] w-64 flex-col justify-between gap-4 overflow-y-auto rounded-sm bg-background p-4 dark:text-white">
			<SideBarNodes />
			<SnapshotSection />
		</Panel>
	);
};
