import type { CopiedItems } from "@/stores/useAppStore";
import { useCallback } from "react";
import { useAppStore } from "@/stores/useAppStore";
import cloneDeep from "clone-deep";
import { toast } from "sonner";
import { v4 as uuidv4 } from "uuid";

type CopyPasteActionProps<
	K extends keyof CopiedItems,
	T extends CopiedItems[K],
> = {
	items: T;
	itemType: K;
	appendAction: (item: T) => void;
	replaceAction: (items: T) => void;
};

export const useCopyPasteActions = <
	K extends keyof CopiedItems,
	T extends CopiedItems[K],
>({
	items,
	itemType,
	appendAction,
	replaceAction,
}: CopyPasteActionProps<K, T>) => {
	const { setCopiedItems, copiedItems } = useAppStore();

	const handleCopy = useCallback(() => {
		const clonedItems = cloneDeep(items);
		setCopiedItems(itemType, clonedItems);
		toast.success("Copié avec succés", {
			action: {
				label: `X`,
				onClick: () => toast.dismiss(),
			},
		});
	}, [items, itemType, setCopiedItems]);

	const handleReplace = useCallback(() => {
		const copiedItemsOfType = cloneDeep(copiedItems[itemType]);
		if (copiedItemsOfType) {
			replaceAction(
				copiedItemsOfType.map((item) => ({ ...item, id: uuidv4() })) as T,
			);
			toast.success("Remplacé avec succés", {
				action: {
					label: `X`,
					onClick: () => toast.dismiss(),
				},
			});
		}
	}, [copiedItems, itemType, replaceAction]);

	const handleAppend = useCallback(() => {
		const copiedItemsOfType = cloneDeep(copiedItems[itemType]);
		if (copiedItemsOfType) {
			appendAction(
				copiedItemsOfType.map((item) => ({ ...item, id: uuidv4() })) as T,
			);
			toast.success("Ajouté avec succés", {
				action: {
					label: `X`,
					onClick: () => toast.dismiss(),
				},
			});
		}
	}, [copiedItems, itemType, appendAction]);

	return {
		handleCopy,
		handleReplace,
		handleAppend,
		copiedItems: copiedItems[itemType],
	};
};
