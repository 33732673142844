import type { VariableType } from "@prisma/client";
import { useState } from "react";
import { StaticValueWithSelector } from "@/components/shared/StaticValueWithSelector";
import { Button } from "@/components/ui/button.tsx";
import {
	CommandEmpty,
	CommandGroup,
	CommandItem,
} from "@/components/ui/command.tsx";
import { Label } from "@/components/ui/label";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import { CategoryIcon } from "@/components/variableSelect/CategoryIcon";
import { VariableIcon } from "@/components/variableSelect/VariableIcon";
import { cn, trpc } from "@/utils";
import { Check, SendHorizontal } from "lucide-react";

import type { SimpleVariable } from "@repos/rate-resolver-shared";
import { isNullOrUndefined } from "@repos/rate-resolver-shared";

import { VariableActions } from "../dataTable/variables/VariableActions";

type VariablesListProps = {
	variables: SimpleVariable[];
	selectedItem?: string;
	onItemSelect: (variable: SimpleVariable) => void;
	rawValue?: string | number | boolean | Date | null | undefined;
	allowRawValue?: boolean;
	onRawValueSubmit?: (
		value: string | number | boolean | Date | null | undefined,
		type: VariableType,
	) => void;
	allowedTypes?: VariableType[];
};
export const VariablesList = ({
	variables,
	selectedItem,
	onItemSelect,
	allowRawValue,
	rawValue,
	onRawValueSubmit,
	allowedTypes,
}: VariablesListProps) => {
	const uniqueCategories = [
		...new Set(variables.map((variable) => variable.category)),
	];
	const [activeCategory, setActiveCategory] = useState<string | null>(null);
	const handleOnCategoryIconClick = (category: string) => () => {
		if (activeCategory === category) {
			setActiveCategory(null);
			return;
		}
		setActiveCategory(category);
	};
	const [rawValueInput, setRawValueInput] = useState(
		isNullOrUndefined(rawValue) ? null : String(rawValue),
	);
	const [rawValueType, setRawValueType] = useState<VariableType | null>(null);

	const handleRawValueChange = (
		value: string | number | boolean | Date | null | undefined,
		type: VariableType,
	) => {
		setRawValueInput(value != null ? String(value) : null);
		setRawValueType(type);

		if (onRawValueSubmit) {
			onRawValueSubmit(value, type);
		}
	};

	const utils = trpc.useUtils();

	const deleteVariableMutation = trpc.variables.deleteVariable.useMutation({
		onSuccess: () => {
			utils.variables.invalidate();
			onItemSelect({} as SimpleVariable);
		},
		onError: (error) => {
			console.error("Failed to delete the variable:", error);
		},
	});

	const handleDelete = (variableId: string) => {
		deleteVariableMutation.mutate({ id: variableId });
	};

	return (
		<div className="flex flex-col justify-center">
			<div className="relative mx-2 flex h-[250px] gap-2">
				<div className="fixed my-4 flex h-[180px] min-w-6 flex-col justify-start gap-4 pr-1 ">
					{uniqueCategories.map((category) => (
						<TooltipProvider key={category} delayDuration={200}>
							<Tooltip>
								<TooltipTrigger>
									<Button
										className={cn(
											"h-7 w-7 rounded-lg border-none p-1 text-white hover:text-white",
											activeCategory === category
												? "bg-emerald-500"
												: "bg-sky-400",
										)}
										variant="outline"
										onClick={handleOnCategoryIconClick(category)}
									>
										<CategoryIcon category={category} />
									</Button>
								</TooltipTrigger>
								<TooltipContent>
									<p>{category}</p>
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
					))}
				</div>
				<div className="ml-8 w-full">
					{allowRawValue && onRawValueSubmit ? (
						<div className="flex w-full flex-col justify-start gap-2 px-3 py-2">
							<Label className="text-sm font-medium">Valeur statique</Label>
							<div className="flex w-full items-center justify-start gap-2">
								{allowedTypes ? (
									<StaticValueWithSelector
										variableTypes={allowedTypes}
										rawValue={rawValue}
										rawValueInput={rawValueInput}
										selectedType={rawValueType}
										setSelectedType={setRawValueType}
										handleValueChange={handleRawValueChange}
										setValueInput={setRawValueInput}
									/>
								) : null}
								<Button
									size="icon"
									variant={"outline"}
									className="h-7 rounded-full"
									disabled={isNullOrUndefined(rawValueInput)}
									type="button"
									onClick={() => {
										if (rawValueType) {
											onRawValueSubmit(rawValueInput, rawValueType);
										}
									}}
								>
									<SendHorizontal className="size-4" />
								</Button>
							</div>
						</div>
					) : null}
					<CommandEmpty>Aucune variable trouvée</CommandEmpty>

					{!activeCategory ? (
						uniqueCategories.map((category) => (
							<CommandGroup key={category} heading={category}>
								{variables
									.filter((variable) => variable.category === category)
									.map((variable) => (
										<CommandItem
											key={variable.key}
											value={variable.label}
											onSelect={() => onItemSelect(variable)}
											disabled={false}
											className={cn(
												"mb-1 cursor-pointer bg-transparent hover:bg-gray-100 dark:hover:bg-neutral-900",
												selectedItem === variable.key &&
													"bg-gray-200 dark:bg-black",
											)}
										>
											<div className="flex w-full items-center justify-between gap-2 hover:bg-gray-100 dark:hover:bg-neutral-900">
												<div className="flex flex-row items-center gap-2">
													<VariableIcon
														type={variable.type}
														className="size-4 shrink-0 basis-[10%]"
													/>
													<span className="[cmdk-item]">{variable.label}</span>
												</div>
												<div className="flex flex-row items-center gap-2">
													{selectedItem === variable.key && (
														<Check className="h-4 w-4" />
													)}
													{!variable.isProperty && (
														<VariableActions
															variableId={variable.id}
															onEdit={() => utils.variables.invalidate()}
															onDelete={() => handleDelete(variable.id)}
															iconOrientation="vertical"
														/>
													)}
												</div>
											</div>
										</CommandItem>
									))}
							</CommandGroup>
						))
					) : (
						<CommandGroup heading={activeCategory}>
							{variables
								.filter((variable) => variable.category === activeCategory)
								.map((variable) => (
									<CommandItem
										key={variable.key}
										value={variable.label}
										onSelect={() => onItemSelect(variable)}
										className={cn(
											"mb-1 cursor-pointer hover:bg-gray-100",
											selectedItem === variable.key && "bg-gray-200",
										)}
									>
										<div className="flex w-full items-center justify-start gap-2 hover:bg-gray-100">
											<VariableIcon
												type={variable.type}
												className="size-4 shrink-0 basis-[10%]"
											/>
											<span className="[cmdk-item]">{variable.label}</span>
											{selectedItem === variable.key && (
												<Check className="h-4 w-4" />
											)}
										</div>
									</CommandItem>
								))}
						</CommandGroup>
					)}
				</div>
			</div>
		</div>
	);
};
