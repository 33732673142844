import type { UseFieldArrayRemove } from "react-hook-form";
import { Operand } from "@/components/nodes/operationBuilder/Operand";
import { Operator } from "@/components/nodes/operationBuilder/Operator";
import { CustomSelect } from "@/components/shared/CustomSelect";
import { Button } from "@/components/ui/button";
import { FormField, FormItem, FormMessage } from "@/components/ui/form";
import { SortableDragHandle, SortableItem } from "@/components/ui/sortable";
import useOperationOptions from "@/hooks/useOperationOptions";
import { getDefaultConditionOperand } from "@/lib";
import { DragHandleDots2Icon } from "@radix-ui/react-icons";
import { Trash2 } from "lucide-react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";

import type {
	Condition,
	ConditionsGroupUpdateGroupDTO,
} from "@repos/rate-resolver-dtos";
import {
	ConditionType,
	getAllowedFirstOperandVariableTypes,
	getAllowedOtherOperandVariableTypes,
	isNullOrUndefined,
} from "@repos/rate-resolver-shared";

interface OperationBuilderProps {
	operationIndex: number;
	operation: Condition;
	basePath: "operations";
	shouldShowOperator: boolean;
	removeOperation: UseFieldArrayRemove;
}

export function ConditionBuilderV2({
	operationIndex,
	basePath = "operations",
	removeOperation,
	operation,
	shouldShowOperator,
}: OperationBuilderProps) {
	const { control } = useFormContext<ConditionsGroupUpdateGroupDTO>();

	const leftOperand = useWatch({
		control,
		name: `${basePath}.${operationIndex}.operands.0`,
		defaultValue: getDefaultConditionOperand(),
	});

	const {
		fields: operandsFields,
		replace,
		remove,
	} = useFieldArray<ConditionsGroupUpdateGroupDTO>({
		name: `${basePath}.${operationIndex}.operands`,
	});

	const selectedOperation = useWatch({
		control,
		name: `${basePath}.${operationIndex}.operationType`,
		defaultValue: undefined,
	});

	const operationsOptions = useOperationOptions(
		"BOOLEAN",
		leftOperand.variableType,
	);
	const allowedFirstOperandTypes =
		getAllowedFirstOperandVariableTypes("BOOLEAN");
	const allowedOtherOperandTypes =
		!isNullOrUndefined(selectedOperation) &&
		!isNullOrUndefined(leftOperand.variableType)
			? getAllowedOtherOperandVariableTypes(
					"BOOLEAN",
					leftOperand.variableType,
					selectedOperation,
				)
			: [];

	return (
		<>
			<SortableItem value={operation.id} asChild>
				<div className="my-2 flex w-full items-center justify-between gap-10">
					<div className="flex items-center gap-8 ">
						<div className="w-full">
							<div className="flex items-start gap-2 rounded-xl text-card-foreground">
								<Operand
									operandIndex={0}
									basePath={basePath}
									operationIndex={operationIndex}
									removeOperation={() => {
										replace([
											getDefaultConditionOperand(),
											getDefaultConditionOperand(),
										]);
									}}
									totalOperands={operandsFields.length}
									isOperandDisabled={false}
									allowedVariableTypes={allowedFirstOperandTypes}
									resetOperands={() =>
										replace([
											getDefaultConditionOperand(),
											getDefaultConditionOperand(),
										])
									}
								/>

								<Operator
									operationsOptions={operationsOptions}
									operationIndex={operationIndex}
									basePath={basePath}
								/>

								<Operand
									operandIndex={1}
									basePath={basePath}
									operationIndex={operationIndex}
									removeOperation={remove}
									totalOperands={operandsFields.length}
									isOperandDisabled={isNullOrUndefined(selectedOperation)}
									allowedVariableTypes={allowedOtherOperandTypes}
									resetOperands={() => {}}
								/>
							</div>
						</div>
					</div>
					<div className="flex flex-row gap-2">
						<SortableDragHandle
							variant="default"
							size="icon"
							className="size-8"
						>
							<DragHandleDots2Icon className="size-4" aria-hidden="true" />
						</SortableDragHandle>
						<Button
							variant="ghost"
							size="icon"
							type="button"
							className="text-destructive hover:bg-red-200 hover:text-destructive"
							onClick={() => removeOperation(operationIndex)}
						>
							<Trash2 className="size-4 text-destructive" />
						</Button>
					</div>
				</div>
			</SortableItem>
			{shouldShowOperator && (
				<FormField
					name={`conditionType`}
					control={control}
					render={({ field }) => (
						<FormItem>
							<CustomSelect
								placeholder=""
								options={operatorOptions}
								value={field.value}
								onValueChange={field.onChange}
								className="mb-2 h-6 w-16 border-none bg-slate-200 dark:border-input dark:bg-transparent"
								isForm
							/>
							<FormMessage />
						</FormItem>
					)}
				/>
			)}
		</>
	);
}

const operatorOptions = [
	{
		label: "ET",
		value: ConditionType.AND,
	},
	{
		label: "OU",
		value: ConditionType.OR,
	},
];
