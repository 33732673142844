import type { RefObject } from "react";
import { useEffect } from "react";

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
	ref: RefObject<T>,
	handler: (event: MouseEvent | TouchEvent | FocusEvent) => void,
): void {
	const handleClick = (e) => {
		if (ref.current && !ref.current.contains(e.target)) {
			handler(e);
		}
	};
	useEffect(() => {
		document.addEventListener("click", handleClick);

		return () => {
			document.removeEventListener("click", handleClick);
		};
	});
}
