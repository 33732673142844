import type { Edge, Node, ReactFlowInstance } from "@xyflow/react";
import { formatNode } from "@/utils/formatNode";
import { trpc } from "@/utils/trpc";
import { toast } from "sonner";

export const useNodeActions = ({
	onDuplicateSuccess,
	reactFlowInstance,
}: {
	onDuplicateSuccess?: (newNode: any) => void;
	reactFlowInstance: ReactFlowInstance<Node, Edge>;
}) => {
	const duplicateNodeMutation = trpc.nodes.duplicateNode.useMutation({
		onSuccess: (data) => {
			if (onDuplicateSuccess) onDuplicateSuccess(data);
			toast.success("Nœud dupliqué avec succès !", {
				action: {
					label: "X",
					onClick: () => toast.dismiss(),
				},
			});
		},
		onError: (error) => {
			toast.error(
				`${error.message || "Échec lors de la duplication du noeud:"}`,
				{
					action: {
						label: "X",
						onClick: () => toast.dismiss(),
					},
				},
			);
		},
	});

	const handleDuplicate = async (id: string) => {
		try {
			const duplicatedNode = await duplicateNodeMutation.mutateAsync({ id });
			const formattedNode = formatNode(duplicatedNode);
			if (onDuplicateSuccess) onDuplicateSuccess(formattedNode);
			if (formattedNode.position) {
				reactFlowInstance.setViewport(
					{
						x: formattedNode.position.x + 256,
						y: formattedNode.position.y,
						zoom: reactFlowInstance.getZoom(),
					},
					{
						duration: 500,
					},
				);
			}
		} catch (error) {
			toast.error("Echec lors de la duplication du neoud", {
				action: {
					label: "X",
					onClick: () => toast.dismiss(),
				},
			});
		}
	};

	return {
		handleDuplicate,
	};
};
