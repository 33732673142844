import { useEffect, useState } from "react";
import { Calendar } from "@/components/ui/calendar.tsx";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover.tsx";
import { useAppStore } from "@/stores";
import { cn, trpc } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { isDate } from "date-fns";
import { CalendarIcon, Loader2, Trash } from "lucide-react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { toast } from "sonner";

import type { EnumValue, OpenaiVariableDTO } from "@repos/rate-resolver-dtos";
import { variableCreateManySchema } from "@repos/rate-resolver-dtos";
import {
	getVariableCategoryOptions,
	getVariableToString,
	getVariableTypeOptions,
	handleArrayToCsv,
	isNullOrUndefined,
} from "@repos/rate-resolver-shared";

import type { SheetData } from "../spreadsheet";
import { CustomSelect } from "../shared/CustomSelect";
import { Button } from "../ui/button";
import { Card } from "../ui/card";
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "../ui/form";
import { Input } from "../ui/input";
import EnumOptions from "./EnumOptions";

interface WorkBookReaderFormProps {
	userSelectedData: SheetData;
	isButtonDisabled: boolean;
	setIsButtonDisabled: (value: boolean) => void;
}

function WorkBookReaderForm({
	userSelectedData,
	isButtonDisabled,
	setIsButtonDisabled,
}: WorkBookReaderFormProps) {
	const { selectedProject } = useAppStore((state) => ({
		selectedProject: state.selectedProject,
	}));

	const variableCategoryOptions = getVariableCategoryOptions();
	const variableTypeOptions = getVariableTypeOptions();
	const form = useForm<OpenaiVariableDTO>({
		resolver: zodResolver(variableCreateManySchema.omit({ projectId: true })),
	});

	const { fields, append, remove, update } = useFieldArray({
		name: "variables",
		control: form.control,
	});

	const [enumValuesState, setEnumValuesState] = useState<EnumValue[][]>(
		fields.map((field) => field.enumValues || []),
	);

	useEffect(() => {
		setEnumValuesState(fields.map((field) => field.enumValues || []));
	}, [fields]);

	const { mutateAsync: getVariableFromExcel, isLoading } =
		trpc.openai.getVariables.useMutation();
	const { mutate: createManyVariables, isLoading: isCreatingVariables } =
		trpc.variables.createManyVariables.useMutation({
			onSuccess: () => {
				toast.success("Variables ajoutées avec succès.");
			},
			onError: (err) => {
				console.log({ erroor: err });
				toast.error("Une erreur est survenue lors de la création");
			},
		});

	const handleGetVariablesFromExcel = async () => {
		try {
			console.log(userSelectedData);
			const jsonData = handleArrayToCsv(userSelectedData);
			const result = await getVariableFromExcel({
				input: jsonData,
			});

			/*      //Static data for testing
      const result: { type: "ENUM" | "STRING" | "NUMBER" | "BOOLEAN" | "DATE"; value: string | number | boolean | null; key: string; label: string; category: "USER" | "GLOBAL_CONFIG" | "PROJECT_CONFIG" | "INPUT"; enumValues: { label: string; value: string; }[] | null; }[] = [
        { label: "Variable 1", key: "var1", type: "STRING", value: "default value", category: "GLOBAL_CONFIG", enumValues: null },
        { label: "Variable 2", key: "var2", type: "NUMBER", value: 123, category: "GLOBAL_CONFIG", enumValues: null },
        { label: "Variable 3", key: "var3", type: "BOOLEAN", value: true, category: "GLOBAL_CONFIG", enumValues: null },
        { label: "Variable 4", key: "var4", type: "DATE", value: new Date().toISOString(), category: "GLOBAL_CONFIG", enumValues: null },
        { label: "Variable 5", key: "var5", type: "ENUM", value: "option1", category: "GLOBAL_CONFIG", enumValues: [{ label: "Option 1", value: "option1" }, { label: "Option 2", value: "option2" }] }
      ]; */

			append(result.variables);

			toast.success("Variables récupérés avec succès.", {
				action: {
					label: "X",
					onClick: () => toast.dismiss(),
				},
			});
			setIsButtonDisabled(true);
		} catch (error) {
			console.error("Error fetching variables from Excel:", error);
			toast.error(
				"Une erreur est survenue lors de la récupération des variables.",
				{
					action: {
						label: "X",
						onClick: () => toast.dismiss(),
					},
				},
			);
		}
	};

	const onSubmit = (data) => {
		if (selectedProject === null) {
			return;
		}
		createManyVariables({
			projectId: selectedProject.id,
			variables: data.variables,
		});
		form.reset();
		remove([...fields.map((_, index) => index)]);
	};

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)}>
				<div className="m-3 flex flex-1 justify-end self-end">
					<Button
						type="button"
						onClick={handleGetVariablesFromExcel}
						disabled={isLoading || isButtonDisabled}
					>
						{isLoading && <Loader2 className="ml-2 h-4 w-4 animate-spin" />}
						Récupérer
					</Button>
				</div>
				{!isLoading && fields.length > 0 && (
					<Card className="p-4">
						{fields.map((field, variableIndex) => (
							<Card key={field.id} className="mb-4 w-full border-gray-300 p-4">
								<div className="flex w-full flex-1 justify-end">
									<Trash onClick={() => remove(variableIndex)} />
								</div>
								<div className="grid gap-4">
									<div className="grid grid-cols-4 gap-4 py-2">
										<FormField
											control={form.control}
											name={`variables.${variableIndex}.label`}
											render={({ field }) => (
												<FormItem>
													<FormLabel>Nom</FormLabel>
													<FormControl>
														<Input
															placeholder="Nom de la variable"
															{...field}
														/>
													</FormControl>
													<FormMessage />
												</FormItem>
											)}
										/>
										<FormField
											control={form.control}
											name={`variables.${variableIndex}.key`}
											render={({ field }) => (
												<FormItem>
													<FormLabel>Identifiant unique</FormLabel>
													<FormControl>
														<Input
															placeholder="Identifiant unique"
															{...field}
														/>
													</FormControl>
													<FormMessage />
												</FormItem>
											)}
										/>
										<FormField
											control={form.control}
											name={`variables.${variableIndex}.type`}
											render={({ field }) => (
												<FormItem>
													<FormLabel>Type de variable</FormLabel>
													<FormControl>
														<CustomSelect
															placeholder="Selectionner un type de variable"
															options={variableTypeOptions}
															value={field.value} // Type assertion
															onValueChange={(value) => {
																field.onChange(value);
																const currentValues = form.getValues(
																	`variables.${variableIndex}`,
																);
																update(variableIndex, {
																	...currentValues,
																	type: value as
																		| "STRING"
																		| "NUMBER"
																		| "BOOLEAN"
																		| "DATE"
																		| "ENUM",
																	value: null,
																});
															}}
															className="h-8 w-full"
															isForm
														/>
													</FormControl>
													<FormMessage />
												</FormItem>
											)}
										/>
										<FormField
											control={form.control}
											name={`variables.${variableIndex}.value`}
											render={({ field }) => (
												<FormItem>
													<FormLabel>Valeur par défaut</FormLabel>
													<FormControl>
														{fields[variableIndex]?.type === "ENUM" ? (
															<CustomSelect
																placeholder="Valeur par défaut"
																options={
																	enumValuesState[variableIndex]?.map(
																		(enumOption) => ({
																			label: enumOption.label,
																			value: enumOption.value,
																		}),
																	) ?? []
																}
																value={
																	typeof field.value === "string"
																		? field.value
																		: undefined
																}
																onValueChange={field.onChange}
																className="h-8 w-full"
																isForm
															/>
														) : fields[variableIndex]?.type === "STRING" ? (
															<Input
																type="text"
																placeholder="Valeur par défaut"
																{...field}
																value={
																	isNullOrUndefined(field.value)
																		? undefined
																		: getVariableToString(field.value)
																}
															/>
														) : fields[variableIndex]?.type === "NUMBER" ? (
															<Input
																type="number"
																placeholder="Valeur par défaut"
																{...field}
																value={
																	isNullOrUndefined(field.value)
																		? undefined
																		: getVariableToString(field.value)
																}
																onChange={(e) => {
																	const value = parseFloat(e.target.value);
																	field.onChange(
																		isNaN(value) ? undefined : value,
																	);
																}}
															/>
														) : fields[variableIndex]?.type === "BOOLEAN" ? (
															<CustomSelect
																placeholder="Valeur par défaut"
																options={[
																	{ label: "TRUE", value: "true" },
																	{ label: "FALSE", value: "false" },
																]}
																value={field.value ? "true" : "false"}
																onValueChange={(value) =>
																	field.onChange(value === "true")
																}
																className="h-8 w-full"
																isForm
															/>
														) : fields[variableIndex]?.type === "DATE" ? (
															<Popover>
																<PopoverTrigger asChild>
																	<FormControl>
																		<Button
																			variant="outline"
																			className={cn(
																				"w-full justify-start text-left font-normal",
																				!field.value && "text-muted-foreground",
																			)}
																		>
																			<CalendarIcon className="mr-2 h-4 w-4" />

																			{isNullOrUndefined(field.value)
																				? "Choisir une date"
																				: getVariableToString(field.value)}
																		</Button>
																	</FormControl>
																</PopoverTrigger>
																<PopoverContent className="w-auto p-0">
																	<Calendar
																		mode="single"
																		selected={
																			isDate(new Date(field.value as string))
																				? new Date(field.value as string)
																				: undefined
																		}
																		onSelect={(date) =>
																			field.onChange(date?.toISOString())
																		}
																		initialFocus
																	/>
																</PopoverContent>
															</Popover>
														) : null}
													</FormControl>
													<FormMessage />
												</FormItem>
											)}
										/>
									</div>
									<div className="grid w-full grid-cols-4 gap-4">
										<div className="col-span-1">
											<FormField
												control={form.control}
												name={`variables.${variableIndex}.category`}
												render={({ field }) => (
													<FormItem>
														<FormLabel>Catégorie</FormLabel>
														<FormControl>
															<CustomSelect
																placeholder="Selectionner une catégorie"
																options={variableCategoryOptions}
																value={field.value}
																onValueChange={field.onChange}
																className="h-8 w-full"
																isForm
															/>
														</FormControl>
														<FormMessage />
													</FormItem>
												)}
											/>
										</div>
										{fields[variableIndex]?.type === "ENUM" && (
											<EnumOptions
												enumValues={enumValuesState[variableIndex] || []}
												setEnumValues={(newValues) => {
													const updatedEnumValuesState = [...enumValuesState];
													updatedEnumValuesState[variableIndex] = newValues;
													setEnumValuesState(updatedEnumValuesState);
												}}
												variableIndex={variableIndex}
												control={form.control}
											/>
										)}
									</div>
								</div>
							</Card>
						))}
						<div className="flex w-full flex-1 justify-end">
							<Button type="submit">
								{isCreatingVariables && (
									<Loader2 className="ml-2 h-4 w-4 animate-spin" />
								)}
								Ajouter
							</Button>
						</div>
					</Card>
				)}
			</form>
		</FormProvider>
	);
}

export default WorkBookReaderForm;
