import { Card, CardContent } from "@/components/ui/card";
import { cn } from "@/utils";

import type { GroupData } from "@repos/rate-resolver-dtos";

import { GroupRecapSummary } from "./GroupRecapSummary";

type ConditionGroupSummaryProps = {
	data: GroupData;
	groupIndex: number;
};
export const ConditionGroupSummary = ({
	data,
	groupIndex,
}: ConditionGroupSummaryProps) => {
	return (
		<Card
			className={cn(
				"relative h-full w-full max-w-[380px] border-2 border-black",
			)}
		>
			<CardContent>
				<GroupRecapSummary
					conditionSummaries={data.conditionSummaries}
					conditionType={data.conditionType}
					groupIndex={groupIndex}
				/>
			</CardContent>
		</Card>
	);
};
