import { useState } from "react";
import { PropertyUpsertDialog } from "@/components/properties/PropertyUpsertDialog";
import { Button } from "@/components/ui/button";
import { Dialog, DialogTrigger } from "@/components/ui/dialog";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { trpc } from "@/utils";
import {
	Loader2,
	MoreHorizontal,
	MoreVertical,
	Pencil,
	Trash,
} from "lucide-react";
import { toast } from "sonner";

interface PropertiesActionsProps {
	propertyId: string;
	variableId: string;
	iconOrientation?: "horizontal" | "vertical";
}

export const PropertyActions = ({
	propertyId,
	variableId,
	iconOrientation = "horizontal",
}: PropertiesActionsProps) => {
	const [open, setOpen] = useState(false);

	const { mutateAsync, isLoading } = trpc.properties.deleteById.useMutation();
	const trpcUtils = trpc.useUtils();

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="ghost" type="button" className="h-4 w-8 p-0">
					<span className="sr-only">Ouvrir le menu</span>
					{iconOrientation === "vertical" ? (
						<MoreVertical className="h-4 w-4" />
					) : (
						<MoreHorizontal className="h-4 w-4" />
					)}
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="start">
				<Dialog open={open} onOpenChange={setOpen}>
					<DialogTrigger asChild>
						<DropdownMenuItem
							onSelect={(e) => {
								e.preventDefault();
								e.stopPropagation();
							}}
							onClick={(e) => {
								e.stopPropagation();
							}}
						>
							<Pencil className="mr-2 h-4 w-4" />
							Modifier
						</DropdownMenuItem>
					</DialogTrigger>

					<PropertyUpsertDialog
						isEditMode
						variableId={variableId}
						propertyId={propertyId}
						onPropertySaved={() => trpcUtils.properties.invalidate()}
						onClose={() => setOpen(false)}
					/>
				</Dialog>
				<DropdownMenuItem
					onClick={async (e) => {
						e.preventDefault();
						await mutateAsync({ propertyId })
							.then(() => {
								console.log("refetching properties");
								trpcUtils.properties.invalidate();
							})
							.catch((e) =>
								toast.error(
									e.message || "Impossible de supprimer la propriété",
									{
										action: {
											label: "X",
											onClick: () => toast.dismiss(),
										},
									},
								),
							);
					}}
					onSelect={(e) => {
						e.preventDefault();
						e.stopPropagation();
					}}
					className="cursor-pointer"
					disabled={isLoading}
				>
					{isLoading ? (
						<Loader2 className="mr-2 h-4 w-4 animate-spin" />
					) : (
						<Trash className="mr-2 h-4 w-4" />
					)}
					Supprimer
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	);
};
