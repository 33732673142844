import type { Summary, TabsLabelsType } from "@/types";
import { TabsLabels } from "@/types/index";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

import type { Condition, Operation } from "@repos/rate-resolver-dtos";
import type { ProjectType, SimpleVariable } from "@repos/rate-resolver-shared";

interface InputVariablesOutputProps {
	value: string;
	type: string;
	key: string;
}

export type CopiedItems = {
	conditions: Condition[] | null;
	operations: Operation[] | null;
};

type FormState = {
	selectedProject: ProjectType | null;
	selectedVariables: SimpleVariable[];
	inputVariablesOutput: InputVariablesOutputProps[];
	getActiveTab: TabsLabelsType;
	tarificationSummary: Summary | null;
	copiedItems: CopiedItems;
};

type FormActions = {
	setSelectedProject: (project: ProjectType) => void;
	setSelectedVariables: (variables: SimpleVariable[]) => void;
	setInputVariablesOutput: (
		inputVariablesOutput: InputVariablesOutputProps[],
	) => void;
	getVariableByKey: (key: string | undefined) => SimpleVariable | undefined;
	setActiveTab: (tab: TabsLabelsType) => void;
	setTarificationSummary: (tarificationSummary: Summary | null) => void;
	setCopiedItems: <K extends keyof CopiedItems>(
		type: K,
		items: CopiedItems[K],
	) => void;
};

const initialFormState: FormState = {
	selectedProject: null,
	selectedVariables: [],
	inputVariablesOutput: [],
	getActiveTab: TabsLabels.VARIABLES,
	tarificationSummary: null,
	copiedItems: {
		conditions: null,
		operations: null,
	},
};

export const useAppStore = create<FormState & FormActions>()(
	persist(
		devtools((set, get) => ({
			...initialFormState,
			setSelectedProject: (selectedProject: ProjectType) => {
				set({
					selectedProject,
					copiedItems: initialFormState.copiedItems,
				});
			},
			setSelectedVariables: (variables) =>
				set({ selectedVariables: variables }),
			getVariableByKey: (key) => {
				const variables = get().selectedVariables;
				return variables.find((variable) => variable.key === key);
			},
			setActiveTab: (tab) => set({ getActiveTab: tab }),
			setTarificationSummary: (tarificationSummary) => {
				set({ tarificationSummary: tarificationSummary });
			},
			setInputVariablesOutput: (variables) =>
				set({ inputVariablesOutput: variables }),
			setCopiedItems: (type, items) =>
				set((state) => ({
					copiedItems: {
						...state.copiedItems,
						[type]: items,
					},
				})),
		})),

		{
			name: "steps-storage",
		},
	),
);
