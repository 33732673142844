import type { FormattedNode, NodeMeasurements } from "@/types";
import type { XYPosition } from "@xyflow/react";

import type { TypedNode } from "@repos/rate-resolver-dtos";
import { NodeType } from "@repos/rate-resolver-shared";

const DEFAULT_NODE_DIMENSIONS = {
	width: 250,
	height: 150,
};

const DEFAULT_POSITION = {
	x: 100,
	y: 100,
};

export const formatNode = (
	node: TypedNode & { measured?: Partial<NodeMeasurements> },
	position: XYPosition = DEFAULT_POSITION,
): FormattedNode => {
	let width = DEFAULT_NODE_DIMENSIONS.width;
	let height = DEFAULT_NODE_DIMENSIONS.height;
	switch (node.type) {
		case NodeType.CONDITIONS_GROUP:
			width = 300;
			height = node.data.conditionGroups.reduce(
				(acc, group) =>
					acc + group.conditionSummaries.reduce((acc) => acc + 70, 150),
				150,
			);
			break;
		case NodeType.START:
		case NodeType.END:
			width = 150;
			height = 60;
			break;
		case NodeType.OPERATIONS:
		case NodeType.OUTPUT:
			width = 250;
			height = node.data.operationSummaries.reduce((acc) => acc + 70, 100);
	}

	return {
		...node,
		data: {
			...node.data,
			label: node.label || "",
			id: node.id || "",
		},
		position: {
			x: position.x || DEFAULT_POSITION.x,
			y: position.y || DEFAULT_POSITION.y,
		},
		measured: {
			width: node.measured?.width || width,
			height: node.measured?.height || height,
		},
	};
};
