import React from "react";
import { FormControl } from "@/components/ui/form";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";

interface CustomSelectProps {
	placeholder: string;
	options: { label: string; value: string }[];
	className?: string;
	value?: string;
	disabled?: boolean;
	onValueChange: (value: string) => void;
	isForm?: boolean;
}

export const CustomSelect: React.FC<CustomSelectProps> = ({
	placeholder,
	options,
	className = "",
	value,
	disabled,
	onValueChange,
	isForm = false,
}) => {
	const val = options.find((option) => option.value === value);

	return (
		<Select
			value={val?.value}
			onValueChange={onValueChange}
			disabled={disabled}
		>
			{isForm ? (
				<FormControl>
					<SelectTrigger className={className}>
						<SelectValue placeholder={placeholder} />
					</SelectTrigger>
				</FormControl>
			) : (
				<SelectTrigger className={className}>
					<SelectValue placeholder={placeholder} />
				</SelectTrigger>
			)}

			<SelectContent>
				{options.map((option, index) => (
					<SelectItem key={index} value={option.value}>
						{option.label}
					</SelectItem>
				))}
			</SelectContent>
		</Select>
	);
};
